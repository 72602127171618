import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast
} from '@chakra-ui/react';
import * as JsonData from "../../assets/countryCodes.json";

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import e from "express";

interface BlockNumberProps {
    cb: Function,
}
interface CountryCode {
    name: string;
    dial_code: string;
    code: string;
}

export default function BlockNumber(props: BlockNumberProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [number, setNumber ] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isSecurity, setIsSecurity] = useState<boolean>(false);
    const [type, setType] = useState<string>("0");
    const [countryCode, setCountryCode] = useState("GB +44");

    const webHelpers = useWebHelper();
    const toast = useToast();
    const AllCodes = Object.values(JsonData);

    const handleClickOpen = () => {
        setNumber("");
        setReason("");
        
        onOpen();
        setLoading(false);
    }

    const handleClose = () => {
        onClose();
    }
    const handleTypeChange = (event: React.ChangeEvent<any>) => {
        setType(event.target.value);
    };

    const handleMenuSelect = (event: React.ChangeEvent<any>) => {
        setCountryCode(event.target.value as string);
        
    };

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setNumber(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setReason(e.currentTarget.value)
    }
    const validateNumber = () => {
        let dirty = false;

        if (type == "3" || reason === "" || number === "") {
            dirty = true;
            setLoading(false);
            return toast({
                position: "bottom",
                title: `Please fill out every field in the form before submitting`,
                status: "warning",
                isClosable: true,
            });
            
          }
          if (/^[0-9]+$/.test(number) === false) {
            dirty = true;
            setLoading(false);
            return toast({
                position: "bottom",
                title: `Only enter digits into the number field, \nif you need to use a country code be sure to select whole number from the drop-down menu.`,
                status: "warning",
                isClosable: true,
            });
            
          }
          if (type == "0" && (number.length < 3 || number.length > 10)) {
            dirty = true;
            setLoading(false);
            return toast({
                position: "bottom",
                title: `Please enter a number that is between 3 and 10 digits long`,
                status: "warning",
                isClosable: true,
            });
            
          }
          if (
            (type == "1" || type == "2") &&
            (number.length < 3 || number.length > 10)
          ) {
            dirty = true;
            setLoading(false);
            return toast({
                position: "bottom",
                title: `Please enter a number that is between 3 and 10 digits long`,
                status: "warning",
                isClosable: true,
            });
          } else if (dirty === false) {
            blockNumber();
          }
        }

    const blockNumber = () => {
        let fullNumber = countryCode.slice(3) + number;
        if (type === "0" || type === "1"){
            fullNumber.replace("+", "");
        }
        let payload = {
            id: fullNumber,
            reason: reason,
            type: type,
        }
        webHelpers.PutAsync("/api/call/control/block", "zeus-api", payload).then((data: any) => {
            setLoading(false);
            if (data.id === fullNumber){
                handleClose();
                
                props.cb();
                return toast({
                    position: "bottom",
                    title: `Phone Number ${data.id} added to Blocklist`,
                    status: "success",
                    isClosable: true,
                });
            }
            else{
                return toast({
                    position: "bottom",
                    title: `Phone Number ${data.id} could not be added to Blocklist`,
                    status: "error",
                    isClosable: true,
                });
            }
        }).catch((error) => {
            setLoading(false);
            if(error.status === 409){
                return toast({
                    position: "bottom",
                    title: `Phone Number ${fullNumber} could not be added to Blocklist (Already Present in Table)`,
                    status: "error",
                    isClosable: true,
                });
            }
            console.log("error blocking phone number " + fullNumber + ". Reason: ", error);
        })
    }
    

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={"30px"}
                _hover={{ bg: "AbleYellow", textColor: "white" }}
                _active={{ bg: "#ECB500" }}

                onClick={handleClickOpen} leftIcon={<BiPlus />}>
                Add Number
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Create New Permission"}</ModalHeader>
                    <ModalBody>

                        <FormControl>
                            <FormLabel>Please complete the form, ensuring all fields are correct, before submitting.</FormLabel>
                            <Select
                                id="number-type"
                                value={type}
                                onChange={handleTypeChange}
                                marginBottom={"10px"}
                            >
                                <option value={0}>Whole Number</option>
                                <option value={1}>Starts With</option>
                                <option value={2}>Ends With</option>
                            </Select>
                            <Flex direction="row" marginBottom={"10px"}>
                                <Select
                                
                                    defaultValue="+44"
                                    id="countryCode"
                                    value={countryCode}
                                    sx={{ marginRight: '1rem' }}
                                    required
                                    onChange={handleMenuSelect}
                                    isDisabled={type === "3" || type === "2"}
                                >
                                    {AllCodes.map((code: CountryCode) => (
                                        <option value={code.code + " " + code.dial_code} >
                                            {code.name} {code.dial_code}
                                        </option>
                                    ))}
                                </Select>
                                <Input placeholder="Number" type="text" autoComplete="new-password" value={number} onChange={handleNameChange} width={"250px"}></Input>
                            </Flex>
                            <Input placeholder="Reason" type="text" autoComplete="new-password" value={reason} onChange={handleDescriptionChange}></Input>

                        </FormControl>


                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            setLoading(true);
                            validateNumber();

                        }}
                            isLoading={loading}
                            bg={"AbleBlue"}
                            textColor={"white"}
                            _hover={{ textColor: "AbleYellow" }}>Create Number</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
