import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Card, CardHeader, CardBody } from "@chakra-ui/react";
import { Spinner, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, chakra, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { IGeometryProps, Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrCloudUpload, GrNetwork } from "react-icons/gr";
import { color } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateWorkflow from "../../components/workflows/createWorkflow";
import EditWorkflow from "../../components/workflows/editWorkflow";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateOutcome from "../../components/outcomes/createOutcome";
import EditOutcome from "../../components/outcomes/editOutcome";
import Refresh from "../../components/refresh";
import { MdCheck, MdClear, MdContentPasteGo } from "react-icons/md";
import "animate.css";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IWorkflows {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
}
interface IOutcomes {
    id: string,
    friendly_id: string,
    name: string,
    description: string,
}
interface IGuidCSVRow {
    id: string,
    is_valid: boolean
}
export const InteractionsPage = () => {
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<IOutcomes[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [selectedWorkflow, setSelectedWorkflow] = useState<string>("");
    const [nextPage, setNextPage] = useState<IWorkflows[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const [file, setFile] = useState<any>();
    const [array, setArray] = useState<IGuidCSVRow[]>([]);
    const [headerKeys, setHeaderKeys] = useState<any[]>();
    const [inputMethod, setInputMethod] = useState<string>("none")
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [wereAnyGuidsInvalid, setWereAnyGuidsValid] = useState<boolean>(false);
    const [textGuids, setTextGuids] = useState<string>("");

    const fileReader = new FileReader();


    const { isOpen, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();

    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSubmit = (e: any) => {
        setShowPreview(true);
        e.preventDefault();
        setWereAnyGuidsValid(false);
        console.log("uploading", file)
        if (file && inputMethod === "file") {
            fileReader.onload = function (event) {
                const csvOutput = event.target?.result;
                csvFileToArray(csvOutput);
            };
            fileReader.readAsText(file);
        }
        else if (inputMethod === "text") {
            let newArray: Array<IGuidCSVRow> = []
            let splitText = textGuids.replace("\n", "").split(",");
            console.log("splitText");
            splitText.forEach(element => {
                let strippedElement = element.replace("\n", "");
                if (strippedElement !== "") {
                    let isValid = isValidGuid(strippedElement);
                    if (!isValid) {
                        setWereAnyGuidsValid(true) //meant to say invalid i think...
                    }

                    newArray.push({ id: strippedElement, is_valid: isValid })
                }

            });
            setArray(newArray);
        }

    }
    const csvFileToArray = (string: any) => {
        setArray([]);
        setWereAnyGuidsValid(false);
        const csvRows = string.slice(string.split(","));
        console.log("csvrows = ", csvRows.split(","));
        let newArray: Array<IGuidCSVRow> = [];
        csvRows.split(",\r\n").map((i: any) => {
            //let isValid: boolean = isValidGuid(i.split(",")[0]);
            let isValid: boolean = isValidGuid(i); //if you use this line then it will mean the last GUID will return invalid (for testing purposes)
            if (!isValid) {
                setWereAnyGuidsValid(true);
            }
            //newArray.push({ id: i.split(",")[0], is_valid: isValid });
            newArray.push({ id: i, is_valid: isValid }); //if you use this line then it will mean the last GUID will return invalid (for testing purposes)
        })
        setArray(newArray);

    }
    const isValidGuid = (string: string) => {
        let regex: RegExp = RegExp("^[{]?[0-9a-fA-F]{8}"
            + "-([0-9a-fA-F]{4}-)"
            + "{3}[0-9a-fA-F]{12}[}]?$")
        console.log("regex =", regex.test(string));
        return regex.test(string);
    }

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {
            console.log("environment is null?")
        }
    }, [])

    function goToNextPage(pageToLoad: number, searchQuery: string) { //used for infinite scrolling
        webHelpers.GetAsync('/api/config/outcomes?page=' + (pageToLoad + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.workflows)
                data.workflows.forEach((element: IOutcomes) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows);
                setMaxPages(Math.ceil(Number((data.length / rowsPerPage))));
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    function searchOutcomes(searchQuery: string) {
        if (searchQuery !== '') {
            webHelpers.GetAsync('/api/config/outcomes?page=' + (page + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                    // setRows(defaultWorkflow);
                }
                else {
                    setRows(data);
                    setMaxPages(Math.ceil(Number((data.length / rowsPerPage))));
                    setLoading(false);
                }
            }).catch((error) => {
                console.log("error fetching workflows (with search), error message: ", error);
            })
        }
        else {
            webHelpers.GetAsync('/api/config/outcomes?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    setRows(data);
                    setMaxPages(Math.ceil(Number((data.length / rowsPerPage))));
                    setLoading(false);
                }
            }).catch((error) => {
                console.log("error fetching workflows, error message : ", error);
                setLoading(false);
            })
        }

    }

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchOutcomes(search)
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchOutcomes(search)
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);

    const refresh = () => {
        setIgnore(true);
        searchOutcomes("");
        setLoading(true);
        setNextPageNo(page);

    }

    const handleProfileChange = (event: any) => {
        setSelectedType(event.target.value);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.target.value);
    }
    const handleNavigate = (target: string) => {
        navigate("/workflows/view/" + EnvironmentContext.selectedEnvironment.id + "/" + target);
    }

    useEffect(() => {
        setRows([])
    }, [EnvironmentContext.selectedEnvironment])

    useEffect(() => {
        if (array !== null && array !== undefined) {
            // setHeaderKeys(Object.keys(Object.assign({}, ...array)));
        }
    }, [array])

    useEffect(() => {
        console.log("array = ", array)

    })
    const handleOnChange = (e: any) => {
        if (inputMethod === "file") { setFile(e.target.files[0]); }
        else if (inputMethod === "text") {
            setTextGuids(e.target.value);

        }
    };
    useEffect(() => {
        setArray([]);
    }, [file])

    return (

        <ScaleFade initialScale={1} in={!isOpen}>

            <>
                <Heading color={"AbleBlue"} as={"i"} justifySelf={"center"} display={"block"}
                >Interaction Management</Heading>
                <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                    <GoBack goBackTo="/dashboard" />
                    <Select onChange={handleEnvChange} value={EnvironmentContext.selectedEnvironment.id} width={"175px"} size={"sm"} marginTop={"3px"} marginRight={"20px"} defaultValue={"live"}>
                        {EnvironmentContext.environments.map((workflow: IEnvironment) => (
                            <option value={workflow.id}>{workflow.name}</option>
                        ))}
                    </Select>
                    <Button marginTop={"3px"} onClick={() => { setInputMethod("none"); setShowPreview(false) }} display={inputMethod !== "none" ? "flex" : "none"} bg={"AbleBlue"} textColor={"white"} size={"sm"} _hover={{ bg:"AbleYellow" }}>Change Input Method</Button>
                    {/* <Refresh cb={() => refresh()} />
                    <CreateOutcome cb={() => { setLoading(true); searchOutcomes(search) }} /> */}
                </Flex>
                <Flex direction={'row'} justifyContent={"center"}>
                    <Card size={"lg"} w={"75%"} justifyContent={"center"} marginTop={"20px"} boxShadow={"2xl"} className={inputMethod === "none" ? "noneModal" : (showPreview && array.length > 0 ? "longerModal" : "inputModal")}>

                        <CardHeader>
                            {inputMethod === "none" &&
                                <Text as={"b"}>How would you like to enter the interaction IDs?</Text>
                            }
                            {
                                inputMethod === "file" &&
                                <Text as={"b"}>Please select the file you wish to enter then click 'upload'</Text>

                            }
                            {
                                inputMethod === "text" &&
                                <Text as={"b"}>Please enter the list of interaction IDs, comma seperated</Text>
                            }
                        </CardHeader>
                        <CardBody>
                            {inputMethod === "none" &&
                                <Flex direction={"row"} w={"100%"} height={"100%"} justifyContent={"space-around"} className="animate__animated animate__fadeIn animate__slower">
                                    <Button w={"45%"} h={"100%"} onClick={() => { setInputMethod("file"); setFile(undefined); setArray([]); setHeaderKeys(undefined); setShowPreview(false); }}>
                                        <Flex direction={"column"} justifyContent={"center"}>
                                            <Flex width={"100%"} justifyContent={"center"}>
                                                <GrCloudUpload fontSize={"50px"} />
                                            </Flex>
                                            <Text >Upload File</Text>
                                        </Flex>
                                    </Button>

                                    <Button w={"45%"} h={"100%"} onClick={() => {setInputMethod("text"); setFile(undefined); setArray([]); setHeaderKeys(undefined); setTextGuids(""); setShowPreview(false);} }>
                                        <Flex direction={"column"} justifyContent={"center"}>
                                            <Flex width={"100%"} justifyContent={"center"}>
                                                <MdContentPasteGo fontSize={"50px"} />
                                            </Flex>
                                            <Text>Paste Text</Text>
                                        </Flex>
                                    </Button>
                                </Flex>
                            }
                            {inputMethod === "file" &&
                                <>
                                    <form>
                                        <label className="custom-file-upload">
                                            <chakra.input type={"file"}
                                                id={"csvFileInput"}
                                                accept={".csv"}
                                                onChange={handleOnChange}


                                            />
                                        </label>
                                        <Button marginTop={"-4px"} onClick={(e) => handleSubmit(e)} bg={"AbleBlue"} textColor={"white"} size={"sm"} isDisabled={file === undefined} _hover={{ bg: file === undefined ? "AbleBlue" : "AbleYellow" }}>Upload</Button>
                                        <br />
                                    </form>
                                    <Text></Text>
                                </>


                            }
                            {inputMethod === "text" &&
                                <>
                                    <form>
                                        <Flex direction={"column"} justifyContent={"center"}>
                                            <label className="custom-file-upload">
                                                <chakra.textarea
                                                    id={"csvFileInput"}
                                                    onChange={handleOnChange}
                                                    borderColor={"black"}
                                                    borderWidth={"2px"}
                                                    width={"50%"}
                                                    cols={4}
                                                    height={"300px"}
                                                >

                                                </chakra.textarea>
                                            </label>
                                            <Button marginTop={"-4-px"}  w={"100px"} alignSelf={"center"} onClick={(e) => handleSubmit(e)} bg={"AbleBlue"} textColor={"white"} size={"sm"} isDisabled={textGuids === ""} _hover={{ bg: file === undefined ? "AbleBlue" : "AbleYellow" }}>Upload</Button>
                                            <br />
                                        </Flex>
                                    </form>
                                    <Text></Text>
                                </>
                            }
                            <Collapse in={showPreview && array.length > 0} animateOpacity>
                                <Flex overflowY={"scroll"} height={"40vh"} marginTop={"10px"} textAlign={"center"} justifyContent={"center"}>
                                    <Table layout="fixed" textAlign={"center"}>

                                        <Thead>
                                            <Tr key={"header"}>
                                                <Th>{"ID"}</Th>
                                                <Th w={"35%"}>{"is a valid guid?"}</Th>
                                            </Tr>

                                        </Thead>


                                        <Tbody>
                                            {array.length > 0 &&
                                                <>
                                                    {Object.values(array).map((item: IGuidCSVRow) => (
                                                        <Tr bg={item.is_valid ? "#e6ffe6" : "#ffcccc"} borderColor={item.is_valid ? "#d2f5d2" : "#ff9999"} borderTopWidth={"2px"}>

                                                            <Td >{item.id}</Td>
                                                            <Td>{item.is_valid ? <MdCheck /> : <MdClear />}</Td>

                                                        </Tr>
                                                    ))}
                                                </>}
                                        </Tbody>

                                    </Table>
                                </Flex>
                                {wereAnyGuidsInvalid ?
                                    <Box bg={"#ffcccc"} borderColor={"#ff9999"} borderWidth={"3px"} marginTop={"10px"} padding={"10px"}>
                                        <Text > Not all the guids you entered were valid!</Text>
                                    </Box>
                                    :
                                    <Box bg={"#e6ffe6"} borderColor={"#d2f5d2"} borderWidth={"3px"} marginTop={"10px"} padding={"10px"}>
                                        <Text > All Guids entered were valid ! </Text>
                                        <Button bg={"#006600"} _hover={{ bg: "#003300" }} textColor={"white"}>Process Guids</Button>
                                    </Box>
                                }


                            </Collapse>



                        </CardBody>
                    </Card>




                </Flex>
            </>

        </ScaleFade >
    )
}
