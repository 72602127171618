import * as React from 'react';
import { useState } from 'react';


import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, background, useToast,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";


// Custom Components
// import RoleManagement from '../modal-role-management/roles-table.component';
// import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
// import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useWebHelper } from "../../hooks/UseWebHelper";

import { validateLocaleAndSetLanguage } from "typescript";
import { BiAddToQueue, BiCalendar } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { ILateInvoiceDate } from '.';
import { GiSettingsKnobs } from 'react-icons/gi';



interface IEditNameserver {
    id: string,
    name: string,
    email: string,
    cb: Function,
}
export interface IAssembly {
    assembly_name: string;
    is_default: boolean;
}
const defaultWorkflow: IAssembly[] = [
    {
        assembly_name: "Test.Package",
        is_default: true,
    }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};
interface AssemblyModalProps {
    cb: Function,
}
export default function UpdateLateInvoiceDate(props: ILateInvoiceDate) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [name, setName] = useState<string>(props.name);
    const [endDate, setEndDate] = useState(props.end_date);
    const [targetDate, setTargetDate] = useState(props.target_date);
    const [startDate, setStartDate] = useState(props.start_date === null ? null : props.start_date);
    const [classID, setClassID] = useState(props.class_id);
    const [startDateNull, setStartDateNull] = useState<boolean>(props.start_date === null ? true : false);
    // const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [rows2, setRows2] = useState<IAssembly[]>(defaultWorkflow);
    const [newNamespace, setNewNamespace] = useState<string>("New Assembly");
    const webHelpers = useWebHelper();

    function handleClose() {
        setOpen(false);
    }
    function handleOpen() {
        setOpen(true);
        console.log("props = ", props.start_date, startDate);
    }

    const handleChangeName = (event: any) => {
        setName(event.target.value);
    }
    const handleChangeEndDate = (event: any) => {
        setEndDate(event.currentTarget.value);
    }
    const handleChangeTargetDate = (event: any) => {
        setTargetDate(event.currentTarget.value);
    }
    const handleChangeClassID = (event: any) => {
        setClassID(event.currentTarget.value);
    }
    const handleChangeStartDate = (event: any) => {
        setStartDate(event.currentTarget.value);
    }
    const handleSubmit = () => {
        let data = {
            id: props.id,
            name: name,
            end_date: endDate,
            target_date: targetDate,
            class_id: classID,
            start_date: startDateNull === true ? null : startDate,
            
        }
        console.log(data)
        webHelpers.PostAsync("/api/accounting/claims/lateinvoicing/update", "sales", data).then((res) => {
            console.log("post response => ", res);
        })
        setOpen(false);
    }
    // const handleSubmit = () => {
    //     props.cb(newNamespace);
    //     setOpen(false);
    // }
    return (
        <>
            <Button size={"md"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ textColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginTop={"-1px"}
                className='button-test' onClick={handleOpen} leftIcon={<GiSettingsKnobs />}>Edit</Button>
            <Modal
                isOpen={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                size={"5xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <div className="modal-header">
                            <Text >
                                Edit Late Invoice Date
                            </Text>
                            {/* Add an edit department bit here at some point */}


                        </div>

                        <Text as={"i"} fontSize={"sm"}>
                            Update the Late Invoice Date properties
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <InputGroup>
                            <InputLeftAddon pointerEvents={"none"} borderRightRadius={1}>
                                <BiCalendar></BiCalendar>
                            </InputLeftAddon>
                            <Select
                                id="number-type"
                                value={name}
                                onChange={handleChangeName}
                                marginBottom={"10px"}
                                borderLeftRadius={1}
                            >
                                <option value={"2018-2019"}>2018/2019</option>
                                <option value={"2019-2020"}>2019/2020</option>
                                <option value={"2020-2021"}>2020/2021</option>
                                <option value={"2021-2022"}>2021/2022</option>
                                <option value={"2022-2023"}>2022/2023</option>
                                <option value={"2023-2024"}>2023/2024</option>
                                <option value={"2024-2025"}>2024/2025</option>
                                <option value={"2025-2026"}>2025/2026</option>
                            </Select>
                        </InputGroup>
                        <Flex direction={"row"} marginBottom={"10px"}>
                            <InputGroup>
                                <Text w={"110px"} marginTop={"7px"}>
                                    End Date:
                                </Text>

                                <Input w={"375px"} type='datetime-local' value={endDate} onChange={handleChangeEndDate}></Input>
                            </InputGroup>
                            <InputGroup >
                                <Text w={"110px"} marginTop={"7px"} marginLeft={"10px"}>
                                    Target Date:
                                </Text>

                                <Input w={"370px"}  type='datetime-local' value={endDate} onChange={handleChangeTargetDate} marginLeft={"0px"}></Input>
                            </InputGroup>
                        </Flex>
                        <InputGroup marginBottom={"10px"}>
                            <Text w={"110px"} marginTop={"7px"}>Class ID: </Text>
                            <Input w={"375px"} value={classID} onChange={handleChangeClassID} ></Input>
                        </InputGroup>
                        <InputGroup marginBottom={"10px"}>
                            <Checkbox isChecked={startDateNull} onChange={() => setStartDateNull(!startDateNull)} w={"250px"}>Let StartDate be null? </Checkbox>
                            <Input type='datetime-local' value={startDate === null ? "" : startDate} onChange={handleChangeStartDate} marginLeft={"10px"} isDisabled={startDateNull}></Input>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                    <Button bg={"AbleBlue"} textColor={"white"} onClick={handleSubmit} _hover={{bg: "darkblue"}}>
                        Save Changes
                    </Button>
                    <Button marginLeft={"10px"} colorScheme='red' onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
                </ModalContent>
                
            </Modal>
        </>
    );
}