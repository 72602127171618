import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";

interface ICreateGroupProps {
    cb: Function
}

export default function CreateGroup(props: ICreateGroupProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [name, setName ] = useState<string>("");
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isSecurity, setIsSecurity ] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    const toast = useToast();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    function createGroup() {
        webHelpers.PutAsync('/api/management/group', 'auth',{ 'name': name, 'is_security': isSecurity }).then((data: any) => {
        })
        handleClose();
        props.cb();
        return toast({
            position: "bottom",
            title: `New Group Successfully Created`,
            status: "success",
            isClosable: true,
        });
    }

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={"30px"}
                _hover={{ bg: "AbleYellow", textColor: "white" }}
                _active={{bg: "#ECB500"}}
                
                onClick={handleClickOpen} leftIcon={<BiPlus />}>
                Create Group
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Create New Group"}</ModalHeader>
                    <ModalBody>

                        <FormControl>
                            <FormLabel>Please complete the form, ensuring all fields are correct, before submitting.</FormLabel>

                            <Input placeholder="Name" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>
                            
                            <Checkbox marginTop={"10px"} marginLeft={"3px"} isChecked={isSecurity} onChange={() => setIsSecurity(!isSecurity)}>Security? </Checkbox>
                        </FormControl>


                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={createGroup}
                        bg={"AbleBlue"}
                        textColor={"white"}
                        _hover={{ textColor: "AbleYellow" }}>Create Group</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
