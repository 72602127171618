import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, useToast, Spinner
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import RoleManagement from "../role/rolesComponent";
import PermissionManagement from "../permissions/permissionComponent";
import GroupManagement from "../groups/groupsComponent";
import { TSExportAssignment } from "@babel/types";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import GetUserConfirm from "../confirm";
import TableControlsPermissions from "../table/tableControlsPermissions";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface IPermission {
    id: string,
    name: string,
}
interface IUserPermissions {
    id: string,
    sid: string,
    name: string,
    revoked: boolean,
}
interface EditGroupProps {
    id: string,
    cb: Function,
}
interface UserGroups {
    id: string,
    sid: string,
    name: string,
    revoked: boolean,
}
export default function EditGroupModal(props: EditGroupProps) {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const [applications, setApplications] = useState<Application[]>([
        { id: "echo", ttl: 1235, enabled: false },
    ]);
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState<UserRole[]>();
    const [rows, setRows] = useState<IPermission[]>();
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [userGroups, setUserGroups] = useState<UserGroups[]>([]);
    const [userGroupsFiltered, setUserGroupsFiltered] = useState<UserGroups[]>([]);
    const [loading, setLoading] = useState<boolean>(true);


    const webHelpers = useWebHelper();
    const toast = useToast();


    const handleClickOpen = () => {
        setLoading(true);
        onOpen();
        searchPermissions();
        getGroupPermissions();
    };

    const onHandleClose = () => {
        onClose();
        props.cb();
    }

    function searchPermissions() {
        //pulls down all permissions
        webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1) + "/" + search, 'auth').then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                // @ts-ignore 
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
                setLoading(false);

            }
            else {
                console.log(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            setLoading(false);
        });
    }
    const getGroupPermissions = () => {
        webHelpers.GetAsync("/api/management/group/" + props.id + "/permissions", "auth").then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                setUserGroups(data)
                setLoading(false);

                setLoading(false);
            }
        })
    }
    useEffect(() => {
        searchPermissions();
    }, [page, rowsPerPage]);

    useEffect(() => {
        setUserGroupsFiltered([]);
        // @ts-ignore 
        let test = [];

        userGroups.forEach((each) => {
            if (!(each.revoked)) {
                // setUserPermissionsFiltered(userPermissionsFiltered => [...userPermissionsFiltered, each]);
                test.push(each);
            }
        })
        if (Math.floor(test.length / rowsPerPage) === 0) {
            setMaxPagesFirstTab(Math.ceil(test.length / rowsPerPage));
            // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
        }
        else {
            setMaxPagesFirstTab(Math.ceil(test.length / rowsPerPage));
            // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
        }
        // @ts-ignore 
        setUserGroupsFiltered(test.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))));
        //setLoading(false);

    }, [userGroups, page]);

    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        // setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        // setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    }, [page]);

    function handleDelete(permission_id: string) {
        setLoading(true);
        webHelpers.PostAsync('/api/management/permission/revoke/' + permission_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully revoked', { 'variant': 'success' });
                setPage(0);
                searchPermissions();
                getGroupPermissions();
            }
            else {
                toast({
                    position: "bottom",
                    title: `Unable to revoke this permission [${data.status}]`,
                    status: "error",
                    isClosable: true,
                })
            }
        })
        props.cb();

        return (

            toast({
                position: "bottom",
                title: `permission ${permission_id} has been removed from user ${props.id}`,
                status: "success",
                isClosable: true,
            })
        )
    }
    function handleAssign(name: string) {
        const data = {
            'permission': name
        };
        webHelpers.PostAsync('/api/management/permission/assign/' + props.id, 'auth', data).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully assigned', { 'variant': 'success' });
                setLoading(false);
                searchPermissions();
            }
            else {
                console.log(`${data.status}: Unable to assign this permission.`, { 'variant': 'error' });
            }
        })

        props.cb();
        getGroupPermissions();
        return (
            toast({
                position: "bottom",
                title: `permission ${name} has been added to user ${props.id}`,
                status: "success",
                isClosable: true,
            })
        )

    };

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        searchPermissions();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true)
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <Button onClick={handleClickOpen} leftIcon={<MdOutlineSettingsApplications />} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                Edit
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{base: "full", lg:"6xl"}}


            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Edit " + props.id}</ModalHeader>
                    <ModalBody>
                        {/* roles / permissions / groups */}
                        <>
                            {rows !== null && rows !== undefined ?

                                <div>
                                    <Tabs position="relative" variant="enclosed-colored" size={"lg"} onChange={() => {
                                        // populateSkills();
                                        setPage(0);
                                        getGroupPermissions();
                                        setLoading(true);

                                    }
                                    }>
                                        <TabList >
                                            <Tab bg={"AbleBlue"} textColor={"white"} fontSize={{ base: "sm", lg: "lg" }} _selected={{ textColor: "AbleYellow" }} as={"b"}>Manage Permissions</Tab>
                                            <Tab bg={"AbleBlue"} textColor={"white"} fontSize={{ base: "sm", lg: "lg" }} _selected={{ textColor: "AbleYellow" }} as={"b"}>Add Permissions</Tab>
                                        </TabList>
                                        <TabIndicator
                                            mt="-1.5px"
                                            height="2px"
                                            bg="AbleYellow"
                                            borderRadius="1px"
                                        />
                                        <TabPanels>
                                            <TabPanel>

                                                <Flex direction={"row"} justifyContent={"right"}>
                                                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                                                    <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                                        <option value={5} >5</option>
                                                        <option value={10}>10</option>
                                                        <option value={15}>15</option>
                                                    </Select>
                                                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPageFirstTab} </Text>
                                                    <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                        page !== 0 && setPage(page - 1);
                                                        setLoading(true);
                                                    }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                                    <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                        page !== maxPageFirstTab - 1 && setPage(page + 1)
                                                        setLoading(true);
                                                    }} isDisabled={page === maxPageFirstTab - 1}><AiFillCaretRight /></Button>
                                                </Flex>
                                                {!loading ?
                                                    <ScaleFade initialScale={1} in={!loading}>
                                                        <TableContainer>

                                                            <Table variant={"simple"}>
                                                                {userGroups.length === 0 && !loading ?
                                                                    <Text textAlign={"center"} color={"black"}>No Permissions Found! </Text>
                                                                    :
                                                                    <>
                                                                        <Thead>
                                                                            <Tr>
                                                                                {/* this will show all skills that are possible to see */}
                                                                                <Th textAlign={"left"}>Permission Name</Th>
                                                                            </Tr>
                                                                        </Thead>
                                                                        <Tbody>
                                                                            {userGroupsFiltered.map((permission) =>
                                                                                <Tr display={{ base: "none", lg: "revert" }}>
                                                                                    <Td>{permission.name}</Td>
                                                                                    <Td>
                                                                                        <GetUserConfirm

                                                                                            confirmFunction={() => handleDelete(permission.id)}
                                                                                            declineFunction={() => console.log("")}
                                                                                            buttonText="Remove"
                                                                                            buttonWarning={"Are you sure you want to remove this permission from " + props.id} />
                                                                                        {/* <Button leftIcon={<AiOutlineMinus/>} onClick={() => handleDelete(permission.id)}>Remove</Button> */}
                                                                                    </Td>
                                                                                </Tr>
                                                                            )}
                                                                        </Tbody>
                                                                        <Accordion allowToggle allowMultiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-30px"}>
                                                                            {userGroupsFiltered.map(permission => (
                                                                                <AccordionItem>
                                                                                    <AccordionButton>
                                                                                        <Flex justifyContent={"space-between"} w={"100%"}>
                                                                                            <Td display={{ base: "revert", lg: "none" }}>
                                                                                                {permission.name}
                                                                                            </Td>
                                                                                            <AccordionIcon />
                                                                                        </Flex>
                                                                                    </AccordionButton>
                                                                                    <AccordionPanel>
                                                                                        <Td display={{ base: "revert", lg: "none" }} marginLeft={"40px"} >
                                                                                            <GetUserConfirm
                                                                                                confirmFunction={() => handleDelete(permission.id)}
                                                                                                declineFunction={() => console.log("")}
                                                                                                buttonText="Remove"
                                                                                                buttonWarning={"Are you sure you want to remove this permission from " + props.id} />
                                                                                        </Td>
                                                                                    </AccordionPanel>
                                                                                </AccordionItem>
                                                                            ))}
                                                                        </Accordion>
                                                                    </>
                                                                }
                                                            </Table>
                                                        </TableContainer>
                                                    </ScaleFade>
                                                    :
                                                    <Flex width={"100%"} justifyContent={"center"}>
                                                        <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"250px"} />
                                                    </Flex>
                                                }

                                            </TabPanel>
                                            <TabPanel>
                                                <ScaleFade initialScale={1} in={isOpen}>
                                                    <Flex direction={"row"} justifyContent={"right"}>
                                                        <TableControlsPermissions search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />

                                                        <Input display={{ base: "none", lg: "revert" }} placeholder="Search Bar" onChange={handleSearchChange} value={search} width={"300px"}></Input>
                                                        <Text display={{ base: "none", lg: "revert" }} marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                                                        <Select display={{ base: "none", lg: "revert" }} onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                                            <option value={5} >5</option>
                                                            <option value={10}>10</option>
                                                            <option value={15}>15</option>
                                                        </Select>
                                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                                                        <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                            page !== 0 && setPage(page - 1);
                                                            setLoading(true);
                                                        }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                            page !== maxPages - 1 && setPage(page + 1);
                                                            setLoading(true);
                                                        }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                                                    </Flex>
                                                    {/* need to have a section or method of showing already selected skills */}
                                                    {!loading ?
                                                        <ScaleFade initialScale={1} in={!loading}>
                                                            <TableContainer>
                                                                <Table variant={"simple"} whiteSpace={"nowrap"} size={"sm"}>
                                                                    {rows.length === 0 ?
                                                                        <Text textAlign={"center"}>No Permissions Found! </Text>
                                                                        :
                                                                        <>
                                                                            <Thead>
                                                                                <Tr>
                                                                                    {/* this will show all skill that are possible to see */}
                                                                                    <Th textAlign={"left"}>Permission Name</Th>
                                                                                    <Th></Th>
                                                                                </Tr>
                                                                            </Thead>
                                                                            <Tbody>
                                                                                <Accordion allowToggle allowMultiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-19px"}>
                                                                                    {rows.map(permission => (
                                                                                        <AccordionItem>
                                                                                            <AccordionButton>
                                                                                                <Flex justifyContent={"space-between"} w={"100%"}>
                                                                                                    <Td display={{ base: "revert", lg: "none" }}>
                                                                                                        {permission.id}
                                                                                                        <br />
                                                                                                        <Text as={"i"} fontSize={"2xs"}>{permission.name}</Text> </Td>
                                                                                                    <AccordionIcon />
                                                                                                </Flex>
                                                                                            </AccordionButton>
                                                                                            <AccordionPanel>
                                                                                                <Td display={{ base: "revert", lg: "none" }} >
                                                                                                    <Button
                                                                                                        onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                                                        bg={"AbleBlue"}
                                                                                                        textColor={"white"}
                                                                                                        marginLeft={"30px"}
                                                                                                        _hover={{ textColor: "AbleYellow" }}
                                                                                                        isLoading={loading}>Assign Permission</Button></Td>
                                                                                            </AccordionPanel>
                                                                                        </AccordionItem>
                                                                                    ))}
                                                                                </Accordion>
                                                                                {rows.map((permission) =>
                                                                                    <Tr display={{base: "none", lg: "revert"}} >
                                                                                        <Td ></Td>
                                                                                        <Td width={"90px"} display={{ base: "none", lg: "revert" }}>
                                                                                            <Text w={"100px"}>
                                                                                                {permission.id}
                                                                                            </Text></Td>
                                                                                        <Td display={{ base: "none", lg: "revert" }} >
                                                                                            <Text marginLeft={"265px"} flexWrap={"wrap"} width={"490px"}>
                                                                                                {permission.name}
                                                                                            </Text>
                                                                                        </Td>
                                                                                        <Td>

                                                                                            <Button
                                                                                                display={{ base: "none", lg: "revert" }}
                                                                                                onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                                                bg={"AbleBlue"}
                                                                                                textColor={"white"}
                                                                                                _hover={{ textColor: "AbleYellow" }}
                                                                                                isLoading={loading}>+ Assign</Button>
                                                                                        </Td>
                                                                                    </Tr>
                                                                                )}
                                                                            </Tbody>
                                                                        </>
                                                                    }
                                                                </Table>
                                                            </TableContainer>
                                                        </ScaleFade>
                                                        :
                                                        <Flex width={"100%"} justifyContent={"center"}>
                                                            <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                                                        </Flex>
                                                    }
                                                </ScaleFade>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </div>

                                :
                                <Flex>
                                    <Text>No Permissions Found !</Text>
                                </Flex>
                            }
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onHandleClose}>Finish</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
