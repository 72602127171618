import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { Button, Box, useColorMode,  Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../../assets/loadingErrorScreen.png";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaRegClock, FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { StaffView } from "../../components/staff";
import { color } from "framer-motion";
import { Head } from "react-day-picker";
import { MdPendingActions } from "react-icons/md";
import { MdAppShortcut } from "react-icons/md";


interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
export const Dashboard = () => {
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [listJobBox, setListJobBox] = useState<JSX.Element[]>([]);
    const [listItems, setListItems] = useState<IWorkResult[]>([]);
    const [listItems2, setListItems2] = useState<IWorkResult[]>([]);
    const [listItems3, setListItems3] = useState<any[]>([]);
    const [listItems4, setListItems4] = useState<IWorkResult[]>([]);
    const [showActive, setActive] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [noActiveJobs, setNoActiveJobs] = useState<boolean>(true);
    const [defaultEngineer, setDefaultEngineer] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(false);
    const [grouping, setGrouping] = useState<string>("engineer");
    const [postcodeList, setPostcodeList] = useState<string[]>([]);
    const [acceptedList, setAcceptedList] = useState<string[]>(["Accepted", "Pending"])
    const [isOnOrderList, setIsOnOrderList] = useState<string[]>(["Is on Order", "Not on Order"]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [areAccepted, setAreAccepted] = useState<boolean>(false);
    const [arePending, setArePending] = useState<boolean>(false);
    const [quantityOnOrder, setQuantityOnOrder] = useState<number>(0);
    const [currentView, setCurrentView] = useState<string>("staff");
    const {colorMode, toggleColorMode} = useColorMode();

    const { isOpen, onToggle } = useDisclosure()
    const auth = useAuth();
    const cache = useCache();
    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);

    }


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
    const refresh = () => {
        setLoading(true);
        setIgnore(false);

    }
    const handleKeyDown = (event: any) => {

    }
    const handleStaffClick = () => {
        navigate("/staff")
    }
    const handleWorkflowClick = () => {
        navigate("/workflows")
    }
    const handleBlocklistClick = () => {
        navigate("/blocklist")
    }
    const handleTimeprofileClick = () => {
        navigate("/timeprofiles")
    }
    const handleOutcomeClick = () => {
        navigate("/outcomes")
    }
    const handleInteractionsClick = () => {
        navigate("/interactions")
    }
    function sortList(a: IWorkResult, b: IWorkResult) {
        if (a.RouteIndex < b.RouteIndex) {
            return -1;
        }
        if (a.RouteIndex > b.RouteIndex) {
            return 1;
        }
        return 0;
    }
    return (
        <ScaleFade initialScale={1} in={isPageOpen}>
            <Heading as={"i"} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"}>Dashboard</Heading>
            {loading ?
                <>
                    <Flex direction={'row'} h='calc(100vh - 150px)' justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}

                        <Flex direction={"column"} marginRight={"10px"}>
                            <Button colorScheme="blue" bgColor={"backgroundDarkGrey"} marginTop={"10px"} _hover={{ bg: colorMode === 'light' ?  "AbleBlue" : "AbleYellow", textColor: "backgroundDarkGrey" }} color={colorMode === "light" ? "AbleBlue" : "AbleYellow"}
                                onClick={handleStaffClick} size={"lg"} height={"150px"} width={"150px"} _active={{ bg: "AbleYellow" }}><BsFillPersonFill size={"md"} /></Button>
                            <Text as={"b"}>Staff Management</Text>
                        </Flex>
                        <Flex direction={"column"} marginRight={"10px"}>
                            <Button colorScheme="blue" bgColor={"backgroundDarkGrey"} marginTop={"10px"} _hover={{ bg: colorMode === 'light' ?  "AbleBlue" : "AbleYellow", textColor: "backgroundDarkGrey" }} color={colorMode === "light" ? "AbleBlue" : "AbleYellow"}
                                onClick={handleBlocklistClick} size={"lg"} height={"150px"} width={"150px"} _active={{ bg: "AbleYellow" }}><BiPhoneCall size={"md"} /></Button>
                            <Text as={"b"}>Blocklist</Text>
                        </Flex>
                        <Flex direction={"column"}>
                            <Button colorScheme="blue" bgColor={"backgroundDarkGrey"} marginTop={"10px"} _hover={{bg: colorMode === 'light' ?  "AbleBlue" : "AbleYellow", textColor: "backgroundDarkGrey" }} color={colorMode === "light" ? "AbleBlue" : "AbleYellow"}
                                onClick={handleWorkflowClick} size={"lg"} height={"150px"} width={"150px"} _active={{ bg: "AbleYellow" }}><IoIosGitNetwork size={"md"} /></Button>
                            <Text as={"b"}>Manage Workflows</Text>
                        </Flex>
                        <Flex direction={"column"} marginLeft={"10px"}>
                            <Button colorScheme="blue" bgColor={"backgroundDarkGrey"} marginTop={"10px"} _hover={{ bg: colorMode === 'light' ?  "AbleBlue" : "AbleYellow", textColor: "backgroundDarkGrey" }} color={colorMode === "light" ? "AbleBlue" : "AbleYellow"}
                                onClick={handleTimeprofileClick} size={"lg"} height={"150px"} width={"150px"} _active={{ bg: "AbleYellow" }}><FaRegClock size={"md"} /></Button>
                            <Text as={"b"}>Manage Timeprofiles</Text>
                        </Flex>
                        <Flex direction={"column"} marginLeft={"10px"}>
                            <Button colorScheme="blue" bgColor={"backgroundDarkGrey"} marginTop={"10px"} _hover={{ bg: colorMode === 'light' ?  "AbleBlue" : "AbleYellow", textColor: "backgroundDarkGrey" }} color={colorMode === "light" ? "AbleBlue" : "AbleYellow"}
                                onClick={handleOutcomeClick} size={"lg"} height={"150px"} width={"150px"} _active={{ bg: "AbleYellow" }}><MdPendingActions size={"md"} /></Button>
                            <Text as={"b"}>Manage Outcomes</Text>
                        </Flex>
                        <Flex direction={"column"} marginLeft={"10px"}>
                            <Button colorScheme="blue" bgColor={"backgroundDarkGrey"} marginTop={"10px"} _hover={{ bg: colorMode === 'light' ?  "AbleBlue" : "AbleYellow", textColor: "backgroundDarkGrey" }} color={ colorMode === "light" ? "AbleBlue" : "AbleYellow"}
                                onClick={handleInteractionsClick} size={"lg"} height={"150px"} width={"150px"} _active={{ bg: "AbleYellow" }}><MdAppShortcut size={"md"} /></Button>
                            <Text as={"b"}>Call Interactions</Text>
                        </Flex>
                    </Flex>
                </>
                :
                <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                    <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                </Flex>
            }
        </ScaleFade>
    )
}
