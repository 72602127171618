import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, AlertDialogCloseButton, useToast, useColorMode
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import AddSkill from "./addSkill";
import { StringMappingType } from "typescript";
import AddSkillRow from "./addSkillRow";
import { AiFillCaretLeft } from "react-icons/ai"
import { AiFillCaretRight } from "react-icons/ai";
import { floor } from 'mathjs'
import { useEnvironment } from "../../context/EnvironmentContext";
import { FaHandPointLeft } from "react-icons/fa";

interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
//const skills: { [skill_id: string]: SkillTest } = {};

export default function SkillsView(props: SkillsDialogProps) {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const {colorMode, toggleColorMode} = useColorMode();
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [role, setRole] = useState(props.role);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [userSkills, setUserSkills] = useState<Detail[]>(props.role.details.sort());
    const [pageUserSkills, setPageUserSkills] = useState<Detail[]>(props.role.details.sort());
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);



    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const webHelpers = useWebHelper();
    const EnvironmentContext = useEnvironment();
    const toast = useToast();


    let levels: Level[] = [];
    props.role.details.forEach(detail => {
        levels.push(detail.level)
    })
    function addSkills(additionalSkills: SkillTest[]) {
        let test_object = {
            skill_id: "",
            level_id: "",
            name: "",
            level_name: "",
        }
        // @ts-ignore
        test_object.skill_id = Object.values(additionalSkills)[0];
        // @ts-ignore
        test_object.level_id = Object.values(additionalSkills)[1];
        // @ts-ignore
        test_object.name = Object.values(additionalSkills)[2];
        // @ts-ignore
        test_object.level_name = Object.values(additionalSkills)[3];
        skills[test_object.skill_id] = test_object;
        updateRole();

        return (
            toast({
                position: "bottom",
                title: `permission ${test_object.name} has been added to user ${props.userId}`,
                status: "success",
                isClosable: true,
            })
        )
    }

    const handleDelete = (id: string) => {
        //setConfirmOpen(true);
        setSkillToDelete(id);
        handleConfirm(id);
    }

    function updateRole() {
        let updatedRole: UserRoleUpdate = {
            ...props.role,
            skills: Object.values(skills),
        }
        // Push up the new skill level
        webHelpers.PostAsync('/api/worker/role', 'zeus-api', updatedRole).then((data: any) => {
            if (data != undefined) {
                setRole(data);
                fetchSkills();
                return toast({
                    position: "bottom",
                    title: `Role updated!`,
                    status: "success",
                    isClosable: true,
                });
            }
            else {
                return toast({
                    position: "bottom",
                    title: `${data.status}: Unable to apply changes to the role`,
                    status: "error",
                    isClosable: true,
                });
                //enqueueSnackbar(`${data.status}: Unable to apply changes to the role`, { 'variant': 'error' })
            }
        });

        // Update the client side role
        //props.refreshRoles();

    }

    const handleConfirm = (id: string) => {
        if (id !== '') {
            //props.removeSkill(skillToDelete);
            removeSkill(id);
            setConfirmOpen(false);
            fetchSkills();

        }
    }

    function removeSkill(remove_skill_id: string) {
        for (let remove_skill in skills) {
            if (skills.hasOwnProperty(remove_skill_id)) {
                delete skills[remove_skill_id];
                console.log("updating role: ", skills);
                updateRole();
                populateSkills();
            }
        }

    }
    // Below effect is used purely for debugging
    // useEffect(() => {
    //     console.log("page user skills = ", pageUserSkills);
    //     console.log("unpaged user skills = ", userSkills);
    // },[pageUserSkills, userSkills])

    function fetchSkills() {
        // For updating the skills chips on change
        webHelpers.GetAsync('/api/worker/roles/' + props.userId, 'zeus-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                return toast({
                    position: "bottom",
                    title: `Unable to fetch skills!`,
                    status: "error",
                    isClosable: true,
                });
            }
            else {
                data.forEach((role: UserRole) => {

                    if (role.id == props.role.id) {
                        Object.values(skills).forEach(skill => {
                            delete skills[skill.skill_id];
                        })
                        setUserSkills(role.details.sort());
                        role.details.forEach((detail: Detail) => {
                            skills[detail.skill_id] = {
                                skill_id: detail.skill_id,
                                level_id: detail.level_id,
                                level_name: detail.level.name,
                                name: detail.id,
                            };
                        });
                        if (Object.keys(skills).length / rowsPerPage === 0) {
                            setMaxPagesFirstTab(Math.floor((Object.keys(skills).length / rowsPerPage) - 1));

                        }
                        else {
                            setMaxPagesFirstTab(Math.floor((Object.keys(skills).length / rowsPerPage)));
                        }
                        setPageUserSkills((role.details.sort()).slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    }
                })


            }
        });
    }

    useEffect(() => {
        role.details.forEach((detail: Detail) => {
            skills[detail.skill_id] = {
                skill_id: detail.skill_id,
                level_id: detail.level_id,

                level_name: detail.level.name,
                name: detail.id,
            };
        });
    }, [skills])

    useEffect(() => {
        if (open && allSkills === undefined) {
            fetchSkills();
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
        fetchSkills();
    }, [rowsPerPage])

    // useEffect(() => {
    //     onClearSkills();
    // }, [open])

    return (
        <>

            <Button bg={props.isSelected ? "AbleBlue" : "backgroundDarkGrey"} textColor={colorMode === 'light' ? props.isSelected ? "white" : "AbleBlue" : props.isSelected ? "white" : "white"}
                _hover={props.isSelected ? { bg: "AbleBlue", textColor: "AbleYellow" } : { bg: "backgroundDarkGrey", textColor: "AbleYellow" }}
                onClick={() => {
                    onOpen();
                    setOpen(true);
                    setPage(0);
                    populateSkills();
                }} leftIcon={<IoMan />}>
                Skills
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "full", lg: "4xl" }}

            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Edit " + props.role.name + " Skills"}</ModalHeader>
                    <ModalBody>
                        {userSkills.length === 0 || allSkills === undefined ? (
                            <>
                                <p>User doesn't have any skills!</p>
                                <AddSkill role={props.role} handleAddSkills={addSkills} updateSkills={fetchSkills} />
                            </>

                        ) : (
                            <Tabs position="relative" variant="enclosed-colored" size={"lg"} onChange={() => {
                                populateSkills();
                                setPage(0);
                            }
                            }>
                                <TabList >
                                    <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"}>Active Skills</Tab>
                                    <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"}>Add Skills</Tab>
                                </TabList>
                                <TabIndicator
                                    mt="-1.5px"
                                    height="2px"
                                    bg="AbleYellow"
                                    borderRadius="1px"
                                />
                                <TabPanels>
                                    <TabPanel>
                                        <Flex direction={"row"} justifyContent={"right"}>
                                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{base: "xs", lg: "md"}}>Rows per Page:</Text>
                                            <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                                <option value={5} >5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                            </Select>
                                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{base: "xs", lg: "md"}}>Page {page + 1} of {maxPageFirstTab + 1} </Text>
                                            <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                page !== 0 && setPage(page - 1);
                                            }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                            <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                page !== maxPageFirstTab + 1 && setPage(page + 1)
                                            }} isDisabled={page === maxPageFirstTab}><AiFillCaretRight /></Button>
                                        </Flex>
                                        <TableContainer>
                                            <Table variant={"simple"}>
                                                <TableCaption>Current Environment : {(CacheContext.lookUpCacheItem("environment") !== null && CacheContext.lookUpCacheItem("environment") !== undefined) ?
                                                    CacheContext.lookUpCacheItem("environment") : "live"}</TableCaption>
                                                <Thead>
                                                    <Tr>
                                                        <Th display={{ base: "none", lg: "revert" }}>Skill Name</Th>
                                                        <Th display={{ base: "none", lg: "revert" }}>Skill Level</Th>
                                                        <Th></Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Accordion allowToggle allowMultiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-30px"}>
                                                        {pageUserSkills.map(skill => (
                                                                <AccordionItem>
                                                                    <AccordionButton>
                                                                        <Flex justifyContent={"space-between"} w={"100%"}>
                                                                            <Td display={{ base: "revert", lg: "none" }}>
                                                                                {skill.skill.name}
                                                                                <br />
                                                                                <Text as={"i"} fontSize={"2xs"}>{"(" + skill.level.name + ")"}</Text> </Td>
                                                                            <AccordionIcon />
                                                                        </Flex>
                                                                    </AccordionButton>
                                                                    <AccordionPanel w={"1000px"}>
                                                                        <Td display={{ base: "revert", lg: "none" }} ><GetUserConfirm confirmFunction={() => handleDelete(skill.skill_id)} declineFunction={() => { console.log("decline") }} buttonText={"Delete"} buttonWarning="Are you sure you wamt to delete this skill?" />
                                                                        </Td>
                                                                    </AccordionPanel>
                                                                </AccordionItem>
                                                        ))}
                                                    </Accordion>
                                                    {pageUserSkills.map(skill => (
                                                        <Tr>
                                                            <Td display={{ base: "none", lg: "revert" }}>{skill.skill.name}</Td>
                                                            <Td display={{ base: "none", lg: "revert" }} >{"(" + skill.level.name + ")"}</Td>

                                                            <Td display={{ base: "none", lg: "revert" }} ><GetUserConfirm confirmFunction={() => handleDelete(skill.skill_id)} declineFunction={() => { console.log("decline") }} buttonText={"Delete"} buttonWarning="Are you sure you wamt to delete this skill?" />
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                    {/* <AddSkill role={props.role} handleAddSkills={addSkills} updateSkills={fetchSkills} /> */}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                    <TabPanel>
                                        <Flex direction={"row"} justifyContent={"right"}>
                                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{base: "xs", lg: "md"}}>Rows per Page:</Text>
                                            <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                                <option value={5} >5 </option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                            </Select>
                                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{base: "xs", lg: "md"}}>Page {page + 1} of {maxPages} </Text>
                                            <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                page !== 0 && setPage(page - 1);
                                            }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                            <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                page !== maxPages - 1 && setPage(page + 1)
                                            }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                                        </Flex>
                                        {/* need to have a section or method of showing already selected skills */}
                                        <TableContainer>
                                            <Table variant={"simple"}>
                                                <TableCaption>Current Environment : {CacheContext.lookUpCacheItem("environment")}</TableCaption>
                                                <Thead>
                                                    <Tr>
                                                        {/* this will show all skill that are possible to see */}
                                                        <Th>Skill Name</Th>
                                                        <Th display={{ base: "none", lg: "flex" }}>Skill Level</Th>
                                                        <Th></Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {pageSkills.map((skill) =>

                                                        <AddSkillRow {...skill} handleAddedSkills={addSkills} />
                                                    )}
                                                </Tbody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>

                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Finish</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
