import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Tooltip, Toast, useToast, Spinner,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillPhone, AiFillCaretLeft, AiFillCaretRight, AiOutlineMinus } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import RoleRow from "../role/roleRow";
import NewRole from "../role/newRole";
import { StringMappingType } from "typescript";
import SkillsView from "../skills/skillsView";
import GetUserConfirm from "../confirm";
import { color } from "framer-motion";
import { title } from "process";
import "../permissions/permissionStyles.css";
import TableControlsPermissions from "../table/tableControlsPermissions";



export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}
interface IPermission {
    id: string,
    name: string,
}
interface IUserPermissions {
    id: string,
    sid: string,
    name: string,
    revoked: boolean,
}
export default function PermissionManagement(props: RoleManagementProps) {
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState<UserRole[]>(props.roles);
    const [rows, setRows] = useState<IPermission[]>();
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [userPermissions, setUserPermissions] = useState<IUserPermissions[]>([]);
    const [userPermissionsFiltered, setUserPermissionsFiltered] = useState<IUserPermissions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const { isOpen, onToggle } = useDisclosure();




    const webHelpers = useWebHelper();

    function searchPermissions(search: string) {
        webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/1/' + search, 'auth').then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                // @ts-ignore 
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
            }
            else {
                return toast({
                    position: "bottom",
                    title: `No permissions matched search criteria "${search}"`,
                    status: "warning",
                    isClosable: true,
                })
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            if (maxPages !== 0) {
                return toast({
                    position: "bottom",
                    title: `Error fetching permissions [${error.status}]`,
                    status: "error",
                    isClosable: true,
                })
            }
        });
    }
    useEffect(() => {
        if (search !== "") {
            webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1) + "/" + search, 'auth',).then((data: any) => {
                if (data.status === 200 || data.status === undefined) {
                    setRows(data.permissions);
                    setMaxPages(data.max_pages);
                }
                else {
                    console.log(`${data.status}: Unable to find permissions under name`, { 'variant': 'error' })
                }
            })
        }
        else {
            webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1), 'auth',).then((data: any) => {
                if (data.status === 200 || data.status === undefined) {
                    setRows(data.permissions);
                    setMaxPages(data.max_pages);
                }
                else {
                    console.log(`${data.status}: Unable to find permissions under name`, { 'variant': 'error' })
                }
            })
        }

        updatePermissions();
    }, [page, rowsPerPage]);

    useEffect(() => {
        setUserPermissionsFiltered([]);
        // @ts-ignore 
        let test = [];

        userPermissions.forEach((each) => {
            if (!each.revoked) {
                // setUserPermissionsFiltered(userPermissionsFiltered => [...userPermissionsFiltered, each]);
                test.push(each);
            }
        })
        // @ts-ignore 
        setUserPermissionsFiltered(test.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))));
        setLoading(false);

    }, [userPermissions]);

    function updatePermissions() {
        webHelpers.GetAsync('/api/management/user/' + props.user_id + '/permissions', 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                if (maxPages !== 0) {
                    return toast({
                        position: "bottom",
                        title: `No permissions found for user "${props.user_name}"`,
                        status: "warning",
                        isClosable: true,
                    })
                }
            }
            else {
                if (Math.floor(data.length / rowsPerPage) === 0) {
                    setMaxPagesFirstTab(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                else {
                    setMaxPagesFirstTab(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                // setUserPermissions(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                setUserPermissions(data);
            }

        })
    }

    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        // setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        // setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    }, [page]);

    function handleDelete(permission_id: string) {
        webHelpers.PostAsync('/api/management/permission/revoke/' + permission_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                updatePermissions();
                return toast({
                    position: "bottom",
                    title: `Permission ${permission_id} has been removed from user ${props.user_name}`,
                    status: "success",
                    isClosable: true,
                })
            }
            else {
                return toast({
                    position: "bottom",
                    title: `There was an error trying to revoke this permissions [${data.status}]`,
                    status: "error",
                    isClosable: true,
                })

            }
        })
    }


    function handleAssign(name: string) {
        const data = {
            permission: name
        };
        webHelpers.PostAsync('/api/management/permission/assign/' + props.user_id, 'auth', data).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully assigned', { 'variant': 'success' });
                setLoading(false);
                updatePermissions();
            }
            else {
                return toast({
                    position: "bottom",
                    title: `There was an error trying to assign this permissions [${data.status}]`,
                    status: "error",
                    isClosable: true,
                })
            }
        })
        setLoading(false);
        // props.cb();
        return (
            toast({
                position: "bottom",
                title: `permission ${name} has been added to user ${props.user_name}`,
                status: "success",
                isClosable: true,
            })
        )

    };

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        searchPermissions(event.target.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true)
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            {rows !== null && rows !== undefined ?

                <div>
                    <Tabs position="relative" variant="enclosed-colored" size={"lg"} onChange={() => {
                        // populateSkills();
                        onToggle();
                        setPage(0);

                    }
                    }>
                        <TabList >
                            <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Active Permissions</Tab>
                            <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Add Permissions</Tab>
                        </TabList>
                        <TabIndicator
                            mt="-1.5px"
                            height="2px"
                            bg="AbleYellow"
                            borderRadius="1px"
                        />
                        <TabPanels>
                            <TabPanel>
                                <ScaleFade initialScale={1} in={!isOpen}>
                                    <Flex direction={"row"} justifyContent={"right"}>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                            <option value={5} >5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                        </Select>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPageFirstTab} </Text>
                                        <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== 0 && setPage(page - 1);
                                            setLoading(true);
                                        }} isDisabled={page === 0 || maxPageFirstTab - 1 === 0}><AiFillCaretLeft /></Button>
                                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== maxPageFirstTab - 1 && setPage(page + 1)
                                            setLoading(true);
                                        }} isDisabled={page === maxPageFirstTab - 1}><AiFillCaretRight /></Button>
                                    </Flex>
                                    {!loading ?
                                        <TableContainer>
                                            <Table variant={"simple"} w={"100%"}>
                                                {userPermissions.length === 0 ?
                                                    <Text textAlign={"center"}>No Permissions Found! </Text>
                                                    :
                                                    <>
                                                        <Thead>
                                                            <Tr>
                                                                {/* this will show all skill that are possible to see */}
                                                                <Th textAlign={"left"}>Permission Name</Th>
                                                                <Th display={{ base: "none", lg: "revert" }}></Th>
                                                                <Th display={{ base: "none", lg: "revert" }}></Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>

                                                            {userPermissionsFiltered.map((permission) =>
                                                                <Tr display={{ base: "none", lg: "revert" }}>
                                                                    <Td>{permission.name}</Td>
                                                                    <Td></Td>
                                                                    <Td>
                                                                        <GetUserConfirm
                                                                            confirmFunction={() => handleDelete(permission.id)}
                                                                            declineFunction={() => console.log("")}
                                                                            buttonText="Remove"
                                                                            buttonWarning={"Are you sure you want to remove this permission from " + props.user_name} />
                                                                        {/* <Button leftIcon={<AiOutlineMinus/>} onClick={() => handleDelete(permission.id)}>Remove</Button> */}
                                                                    </Td>
                                                                </Tr>
                                                            )}

                                                        </Tbody>
                                                        <Accordion allowToggle allowMultiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-30px"}>
                                                            {userPermissionsFiltered.map(permission => (
                                                                <AccordionItem>
                                                                    <AccordionButton>
                                                                        <Flex justifyContent={"space-between"} w={"100%"}>
                                                                            <Td display={{ base: "revert", lg: "none" }}>
                                                                                {permission.name}
                                                                            </Td>
                                                                            <AccordionIcon />
                                                                        </Flex>
                                                                    </AccordionButton>
                                                                    <AccordionPanel>
                                                                        <Td display={{ base: "revert", lg: "none" }} marginLeft={"40px"} >
                                                                            <GetUserConfirm
                                                                                confirmFunction={() => handleDelete(permission.id)}
                                                                                declineFunction={() => console.log("")}
                                                                                buttonText="Remove"
                                                                                buttonWarning={"Are you sure you want to remove this permission from " + props.user_name} />
                                                                        </Td>
                                                                    </AccordionPanel>
                                                                </AccordionItem>
                                                            ))}
                                                        </Accordion>
                                                    </>
                                                }
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Flex width={"100%"} justifyContent={"center"}>
                                            <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"250px"} />
                                        </Flex>
                                    }
                                </ScaleFade>
                            </TabPanel>
                            <TabPanel>
                                <ScaleFade initialScale={1} in={isOpen}>
                                    <Flex direction={"row"} justifyContent={"right"}>
                                        <TableControlsPermissions search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
                                        <Input placeholder="Search Bar" onChange={handleSearchChange} value={search} width={"300px"} display={{ base: "none", lg: "revert" }}></Input>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Rows per Page:</Text>
                                        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"} display={{ base: "none", lg: "revert" }}>
                                            <option value={5} >5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                        </Select>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPages === 0 ? 1 : maxPages} </Text>
                                        <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== 0 && setPage(page - 1);
                                            setLoading(true);
                                        }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== maxPages - 1 && setPage(page + 1);
                                            setLoading(true);
                                        }} isDisabled={page === maxPages - 1 || maxPages === 0}><AiFillCaretRight /></Button>
                                    </Flex>
                                    {/* need to have a section or method of showing already selected skills */}
                                    {!loading ?
                                        <TableContainer>
                                            <Table variant={"simple"} size={"sm"} className="table-tiny">
                                                {rows.length === 0 ?
                                                    <Text textAlign={"center"} whiteSpace={"nowrap"}>No Permissions Found! </Text>
                                                    :
                                                    <>
                                                        <Thead>
                                                            <Tr>
                                                                {/* this will show all skill that are possible to see */}
                                                                <Th textAlign={"left"}>Permission Name</Th>
                                                                <Th display={{ base: "none", lg: "revert" }}>Description</Th>
                                                                <Th display={{ base: "none", lg: "revert" }}></Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Accordion allowToggle allowMultiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-19px"}>
                                                                {rows.map(permission => (
                                                                    <AccordionItem>
                                                                        <AccordionButton>
                                                                            <Flex justifyContent={"space-between"} w={"100%"}>
                                                                                <Td display={{ base: "revert", lg: "none" }}>
                                                                                    {permission.id}
                                                                                    <br />
                                                                                    <Text as={"i"} fontSize={"2xs"}>{permission.name}</Text> </Td>
                                                                                <AccordionIcon />
                                                                            </Flex>
                                                                        </AccordionButton>
                                                                        <AccordionPanel>
                                                                            <Td display={{ base: "revert", lg: "none" }} >
                                                                                <Button
                                                                                    onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                                    bg={"AbleBlue"}
                                                                                    textColor={"white"}
                                                                                    marginLeft={"30px"}
                                                                                    _hover={{ textColor: "AbleYellow" }}
                                                                                    isLoading={loading}>Assign Permission</Button></Td>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                ))}
                                                            </Accordion>
                                                            {rows.map((permission) =>
                                                                <Tr display={{ base: "none", lg: "revert" }}>
                                                                    <Td width={"440px"}>
                                                                        <Text w={"100px"}>
                                                                            {permission.id}
                                                                        </Text></Td>
                                                                    <Td >
                                                                        <Text marginLeft={"0px"} flexWrap={"wrap"} width={"490px"}>
                                                                            {permission.name}
                                                                        </Text>
                                                                    </Td>
                                                                    <Td>

                                                                        <Button
                                                                            onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                            bg={"AbleBlue"}
                                                                            textColor={"white"}
                                                                            _hover={{ textColor: "AbleYellow" }}
                                                                            isLoading={loading}>+ Assign</Button>
                                                                    </Td>
                                                                </Tr>
                                                            )}
                                                        </Tbody>
                                                    </>
                                                }
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Flex width={"100%"} justifyContent={"center"}>
                                            <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                                        </Flex>
                                    }
                                </ScaleFade>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>

                :
                <Flex>
                    <Text>No Permissions Found !</Text>
                </Flex>
            }
        </>
    );
}
