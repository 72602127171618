import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import RoleManagement from "../role/rolesComponent";
import PermissionManagement from "../permissions/permissionComponent";
import GroupManagement from "../groups/groupsComponent";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

// Jesus Christ    'skills': [{ 'skill_id': 'dummy_skill_id', 'level_id': 'dummy_level_id' }],
export const dummyRoles: UserRole[] = [{
    'id': 'dummy_id',
    'name': 'dummy_name',
    'worker_id': 'dummy_worker_id',
    'allow_aux': true,
    'auto_answer': false,
    'outbound_rule': 'dummy_outbound_rule',
    'outbound_number_id': 'dummy_outbound_number_id',
    'details': [{
        'id': 'dummy_details_id',
        'level': {
            'id': 'dummy_level_id',
            'key': 'dummy_level_key',
            'name': 'dummy_level_name',
            'order': 100,
            'skill_id': 'dummy_level_skill_id',
        },
        'level_id': 'dummy_level_id_top_level',
        'role_id': 'dummy_role_id',
        'skill': {
            'id': 'dummy_skill_id',
            'key': 'dummy_skill_key',
            'name': 'dummy_kill_name',
            'levels': [{
                'id': 'dummy_skill_level1',
                'key': 'dummy_level_key',
                'name': 'dummy_level_name',
                'order': 100,
                'skill_id': 'dummy_level_skill_id',
            }],
        },
        'skill_id': 'dummy_skill_id',
        'worker_id': 'dummy_worker_id'
    }],
}]

export default function EditUserMobile(props: UserAppsDialogProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [applications, setApplications] = useState<Application[]>([
        { id: "echo", ttl: 1235, enabled: false },
    ]);
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [loading, setLoading] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    const toast = useToast();


    const handleClickOpen = () => {
        setChanges([]);
        console.log("props.User = , ", props.user);
        webHelpers.GetAsync('/api/worker/roles/' + props.user.id, "zeus-api").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                return toast({
                    position: "bottom",
                    title: `Unable to get information for this user [${data.status}]`,
                    status: "error",
                    isClosable: true,
                });
                // Some fallback ui needed here.
            }
            else {
                setRoles(data);
                onOpen();
            }
        }).catch((error) => {
            return toast({
                position: "bottom",
                title: `Error fetching roles, error: [${error}]`,
                status: "error",
                isClosable: true,
            });
        });

    };

    const onHandleClose = () => {
        onClose();
        props.cb();
    }

    function updateGroups() {
        webHelpers.GetAsync('/api/management/user/' + props.user.id + '/groups', 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {

                // Some fallback ui needed here.
            }
            else {
                setGroups(data);
            }
        })
    };

    useEffect(() => {
        if (props.user.applications !== null && props.user.applications !== undefined) { //this check prevents a rendering error
            applications.forEach(each => {
                props.user.applications.forEach((app: Application) => {
                    if (app.id == each.id) {
                        each.enabled = true;
                    }
                });
            });
        }
    }, [applications])
    return (
        <>
            <Button onClick={handleClickOpen} leftIcon={<MdOutlineSettingsApplications />} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                Edit
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"


            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Text textAlign={"center"} fontSize={"2xl"} as={"b"}>{props.user.full_name}</Text>
                        <br/>
                        <Text textAlign={"center"} fontSize={"xl"} as={"i"} marginBottom={"10px"}>{props.user.department}</Text>
                        {/* {"Edit " + props.user.full_name} */}
                    </ModalHeader>
                    <ModalBody justifyContent={"center"}>
                        {/* roles / permissions / groups */}
                        <Flex direction="column" alignContent={"center"} w={"100%"}>
                            <Flex marginBottom={"10px"} width={"100%"} justifyContent={"center"}>
                                <UserAppPermissionsModal user={props.user} cb={props.cb} />
                            </Flex>
                            <Flex marginBottom={"10px"} justifyContent={"center"}>
                                <PasswordReset {...props.user} />
                            </Flex>
                            <Flex marginBottom={"10px"} justifyContent={"center"}>
                                <EditUserModal user={props.user} cb={props.cb} />
                            </Flex>
                            <Flex marginBottom={"10px"} justifyContent={"center"}>
                                <EnableDisable user={props.user} cb={props.cb} />
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onHandleClose}>Finish</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
