import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, AlertDialogCloseButton, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { IoMdAdd } from "react-icons/io"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { forEachChild } from "typescript";
import AddSkillMobile from "./addSkillMobile";


interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}
interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}

export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
    handleAddedSkills: Function,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
const skills: { [skill_id: string]: ISkill } = {};

export default function AddSkillRow(props: Skill) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const CacheContext = useCache();
    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const [open, setOpen] = useState(false);
    const [level, setLevel] = useState<Level>(props.levels[0]);

    const webHelpers = useWebHelper();
    const toast = useToast();
    const [label, setLabel] = useState<string>(props.levels[0].id);

    useEffect(() => {
        console.log("props: ", props);
    },[])
    const addSkill = () => {
        if (level !== undefined) {
            const addedSkill: ISkillToAdd = {
                skill_id: props.id,
                level_id: level.id,
                name: props.name,
                level_name: level.name,
            }
            props.handleAddedSkills(addedSkill)
            
        } else {
            console.log(`Please choose a level before clicking Add`, { 'variant': 'warning' });
            return toast({
                position: "bottom",
                title: `Please choose a level before clicking Add`,
                status: "warning",
                isClosable: true,
            })

        }
    }
    const handleLevelChange = (e: any) => {
        setLabel(e.target.value);
        props.levels.forEach(element => {
            if (element.id === e.target.value) {
                setLevel(element);
            }

        });
    }

    useEffect(() => {
        onClearSkills();
    }, [open])

    return (
        <>
            <Tr >
                
                { }
                <Td>{props.name}</Td>
                <Td>
                    <Select value={label} onChange={handleLevelChange} defaultValue={props.levels[0].id} display={{ base: "none", lg: "revert" }}>
                        {props.levels.map((each) =>
                            <option value={each.id}>{each.name}</option>
                        )}
                    </Select>
                </Td>
                <Td>
                    
                    <Button onClick={addSkill}
                        bg={"AbleBlue"}
                        textColor={"white"}
                        display={{ base: "none", lg: "revert" }}
                        _hover={{ textColor: "AbleYellow" }}>Add Skill
                    </Button>     
                    <AddSkillMobile {...props}/>
                    
                    
                </Td>
            </Tr>
        </>
    );
}
