import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, AlertDialogCloseButton,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { IoMdAdd } from "react-icons/io"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "./addSkillRow";


interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}
interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}

export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
const skills: { [skill_id: string]: ISkill } = {};

export default function AddSkill(props: IAddSkillProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const CacheContext = useCache();
    const [currentSkills, setCurrentSkills] = useState(props.role.details);
    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const [open, setOpen] = useState(false);
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills ] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage ] = useState<number>(5);
    const [ page, setPage ] = useState<number>(0);
    
    const [maxPages, setMaxPages] = useState<number>(1);

    const webHelpers = useWebHelper();

    

    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if(data.length % rowsPerPage === 0){
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else{
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    },[page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    },[rowsPerPage])
    
    useEffect(() => {
        onClearSkills();
    },[open])

    return (
        <>
            <Button onClick={() => {
                onOpen();
                setOpen(true);    
                setPage(0)
                populateSkills();
            }} leftIcon={<IoMdAdd />}>
                Add Skill
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{base: "full", lg: "4xl"}}

            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Add Skills"}</ModalHeader>
                    <ModalBody>
                    <Flex direction={"row"} justifyContent={"right"}>
                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{base: "xs", lg: "md"}} >Rows per Page:</Text>
                        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                        </Select>
                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{base: "xs", lg: "md"}}>Page {page + 1} of {maxPages} </Text>
                        <Button bgColor={"AbleBlue"}  mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                            page !== 0 && setPage(page - 1);
                        }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                            page !== maxPages - 1 && setPage(page + 1)
                        }} isDisabled={page === maxPages -1}><AiFillCaretRight /></Button>
                        </Flex>
                        {/* need to have a section or method of showing already selected skills */}
                            <TableContainer>
                                <Table variant={"simple"}>
                                    <TableCaption>Current Environment : {CacheContext.lookUpCacheItem("environment")}</TableCaption>
                                    <Thead>
                                        <Tr>
                                            {/* this will show all skill that are possible to see */}
                                            <Th>Skill Name</Th>
                                            <Th display={{base: "none", lg: "revert"}}>Skill Level</Th>
                                            <Th></Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {pageSkills.map((skill) => 
                                            <AddSkillRow {...skill} handleAddedSkills={props.handleAddSkills}/>
                                        )}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Finish</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
