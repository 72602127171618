import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Tooltip, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import RoleRow from "./roleRow";
import NewRole from "./newRole";
import GetUserConfirm from "../confirm";
import { StringMappingType } from "typescript";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}
interface IWorker {
    id: string,
    twilio_sid: string,
    user_sid: string,
    name: string,
    activity_sid: string,
    avtivity_name: string,
    activity_last_updated: string,
    role_id: string,
    queues: string,
    channels: [],
    role: string,
}
// Jesus Christ    'skills': [{ 'skill_id': 'dummy_skill_id', 'level_id': 'dummy_level_id' }],
export const dummyRoles: UserRole[] = [{
    'id': 'dummy_id',
    'name': 'dummy_name',
    'worker_id': 'dummy_worker_id',
    'allow_aux': true,
    'auto_answer': false,
    'outbound_rule': 'dummy_outbound_rule',
    'outbound_number_id': 'dummy_outbound_number_id',
    'details': [{
        'id': 'dummy_details_id',
        'level': {
            'id': 'dummy_level_id',
            'key': 'dummy_level_key',
            'name': 'dummy_level_name',
            'order': 100,
            'skill_id': 'dummy_level_skill_id',
        },
        'level_id': 'dummy_level_id_top_level',
        'role_id': 'dummy_role_id',
        'skill': {
            'id': 'dummy_skill_id',
            'key': 'dummy_skill_key',
            'name': 'dummy_kill_name',
            'levels': [{
                'id': 'dummy_skill_level1',
                'key': 'dummy_level_key',
                'name': 'dummy_level_name',
                'order': 100,
                'skill_id': 'dummy_level_skill_id',
            }],
        },
        'skill_id': 'dummy_skill_id',
        'worker_id': 'dummy_worker_id'
    }],
}]

export default function RoleManagement(props: RoleManagementProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [applications, setApplications] = useState<Application[]>([
        { id: "echo", ttl: 1235, enabled: false },
    ]);
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [activeRole, setActiveRole] = useState<string>();
    const [roles, setRoles] = useState<UserRole[]>(props.roles);
    const [activeWorker, setActiveWorker] = useState();
    const [workerExists, setWorkerExists] = useState(false);
    const [activeWorkerId, setActiveWorkerId] = useState<string>('');

    const webHelpers = useWebHelper();
    const toast = useToast();

    function getActiveRole() {
        console.log('/api/worker/' + props.user_id)
        webHelpers.GetAsync<any>('/api/worker/' + props.user_id, 'zeus-api').then((data: any) => {
            console.log("get active role response = ", data);
            setActiveRole(data.role_id === undefined || data.role_id === null ? "" : data.role_id)
        }).catch(() => {
            console.log("NO ACTIVE ROLE HAS BEEN SET FOR THIS ROLE!")
        })
        // }).catch((error) => {
        //     console.log("getActiveRole Error : ", error);

        // })
    }
    function getWorker() {
        console.log("checking user " + props.user_id + " for existing workers")
        webHelpers.GetAsync<IWorker>("/api/worker/" + props.user_id, "zeus-api").then((res: any) => {
            //worker does not exist for this user
            if (res.length === 0 || res === undefined || res === null) {
                setWorkerExists(false);
            }
            else {
                if (res.name !== undefined) {
                    setWorkerExists(true);
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        getActiveRole();
        getWorker();

    }, [])
    
    function getRoles() {
        setRoles([]);
        getActiveRole();
        if (workerExists) {
            webHelpers.GetAsync('/api/worker/roles/' + props.user_id, 'zeus-api').then((data: any) => {

                if (data === undefined || data === null || data.status !== undefined) {
                    console.log(`${data.status}: Unable to fetch roles`, { 'variant': 'error' })
                }
                else {
                    findWorker(props.user_id);
                    console.log(data)
                    setRoles(data);

                }
            }).catch((error) => {
                return toast({
                    position: "bottom",
                    title: `An error occurred while fetching roles [${error.status}]`,
                    status: "error",
                    isClosable: true,
                })
            })
        }
    }
    // Takes changes applied to a locally stored role and pushes them to the database
    function handleSetActive(id: string) {

        webHelpers.PostAsync('/api/worker/role/' + id + '/set', 'zeus-api', {}).then((data: any) => {
            if (data.status === undefined) {
                console.log('role successfully set to active!', { 'variant': 'success' });

            }
        })
        getRoles();
    }
    // Removing a role
    function handleDelete(id: string) {
        if (roles.length == 1) {
            return toast({
                position: "bottom",
                title: `Cannot delete a user's last role!`,
                status: "warning",
                isClosable: true,
            })
        } else {
            webHelpers.DeleteAsync('/api/worker/role/' + id, 'zeus-api', {}).then((data: any) => {
                if (data.status != 200) {
                    return toast({
                        position: "bottom",
                        title: `Unable to remove this role [${data.status}]`,
                        status: "error",
                        isClosable: true,
                    })
                }
                else {
                    getRoles();
                    props.cb();
                    return toast({
                        position: "bottom",
                        title: `Role successfully deleted`,
                        status: "success",
                        isClosable: true,
                    })

                }
            }).catch((error) => {
                console.log("error occurred , ", error);
                getRoles();
                props.cb();
            }
            )
        }

    }
    function findWorker(id: string) {
        //testing to see if I can get active Role
        // webHelpers.GetAsync('/api/worker/' + id, 'zeus-api').then((data: any) => {
        //     console.log("worker test result = ", data);
        //     setActiveRole(data.role_id)

        // })
        webHelpers.GetAsync('/api/worker/roles/' + id, 'zeus-api').then((data: any) => {
            console.log("find worker result ", data);
            if (data !== null && data !== undefined && data.length !== 0) {
                console.log(data.length === 0)
                setActiveWorker(data);
                console.log("data.role_id = ", id)
                console.log("data: ", data)
                setActiveWorkerId(data.id);
                return true;

            }
            else {
                // No worker exists
                setWorkerExists(false);
                console.log("no workers found!")
                return false;
            }

        }).catch((error) => {
            console.log("find worker error", error);
            console.log("no workers found error")
        })

    }
    function createWorker() {
        console.log("/api/worker/" + props.user_id + "/put", "zeus-api", { user_id: props.user_id, name: props.user_name })
        webHelpers.PutAsync("/api/worker", "zeus-api", { user_id: props.user_id, name: props.user_name }).then((res: any) => {
            console.log("create worker response = ", res);
            setWorkerExists(true);
        })
    }
    useEffect(() => {
        if (!workerExists) {
            findWorker(props.user_id);
        }
    }, [])

    if (!workerExists) {
        return (
            <Flex justifyContent={"center"} direction={"column"}>
                <Flex justifyContent={"center"} direction={"row"}>
                    <Text mt={"3px"}><FaUserSlash color="gray" /></Text>
                    <Text ml={"10px"}>No Worker Found!</Text>
                </Flex>
                <GetUserConfirm buttonText="Create Worker" confirmFunction={createWorker} declineFunction={() => console.log("cancelled")} buttonWarning="Are you sure you want to Create a new Worker for this user?" />
                <Flex justifyContent={"center"} w={"150px"}>

                    <div className="button-tray"></div>
                </Flex>
            </Flex >
        );
    }
    if ((Object.values(props.roles).length === 0 || activeWorkerId === '') && workerExists) {

        return (
            <>
                <Flex justifyContent={"center"} direction={"column"}>
                    <Flex justifyContent={"center"} direction={"row"}>

                        <Text mt={"3px"}><FaUserSlash color="gray" /></Text>
                        <p>User has a worker but no roles! This shouldn't happen! Please create one.</p>

                    </Flex>
                    <NewRole userId={props.user_id} cb={getRoles} />
                    <div className="button-tray">

                        {/* Needs to be a dialog component, force creation of one role */}
                        {/* <CreateRole worker_id={activeWorkerId} cb={getRoles} /> */}
                    </div>
                </Flex>
            </>

        )
    }
    else {
        // Check which role is active by pinging the worker
        return (
            <div>
                <TableContainer>
                    <Table sx={{ minWidth: '100%' }} size='small' aria-label="custom pagination table">
                        <Thead>
                            <Tr>
                                <Th align="left"><strong>Role</strong></Th>
                                <Th align="left" display={{ base: "none", lg: "revert" }}><strong>Skills</strong>
                                </Th>
                                <Th align="left" display={{ base: "none", lg: "revert" }}><strong>Outbound Number</strong>
                                </Th>
                                <Th align="left" display={{ base: "none", lg: "revert" }}>
                                    <Tooltip label='Auto Answer' placement="top" >
                                        <Text w={"10px"}>
                                            <AiFillPhone />
                                        </Text>
                                    </Tooltip>
                                </Th>
                                <Th align="left" display={{ base: "none", lg: "revert" }}>
                                    <Tooltip label='Allow Aux' placement="top">
                                        <Text w={"10px"}>
                                            <BsFillSpeakerFill />
                                        </Text>
                                    </Tooltip>
                                </Th>
                                <Th align="left"></Th>
                                <Th align="left">

                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>

                            {Object.values(roles).map((role) => (
                                <RoleRow key={role.id} role={role} handleDelete={handleDelete} handleSetActive={handleSetActive} activeRoleId={activeRole} cb={getRoles} userId={props.user_id} second_cb={getActiveRole} />
                            ))}
                            {/* <NewRole userId={props.user_id} cb={getRoles}/> */}
                        </Tbody>
                        <NewRole userId={props.user_id} cb={getRoles} />
                    </Table>
                </TableContainer>
                <div className="button-tray">

                    {/* <CreateRole worker_id={roles[0].worker_id} cb={getRoles} /> */}
                </div>
            </div>
        );
    }
}