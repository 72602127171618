import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";

interface newOutcomeProps {
    cb: Function,
}

export default function CreateOutcome(props: newOutcomeProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    //form fields
    const [friendlyId, setFriendlyId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        setFriendlyId("");
        setName("");
        setDescription("");
    }

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = () => {
        if (friendlyId !== undefined && name !== undefined && description !== undefined) {
            const payload = {
                "friendly_id": friendlyId.replaceAll(" " ,"").toLowerCase(),
                "name": name,
                "description": description,
            }
            console.log(payload)
            webHelpers.PutAsync('/api/config/outcome', 'helios-api', payload).then((data: any) => {

            })
            props.cb();
            handleClose();
        }
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter" && !(friendlyId === "" || name === "" || description === "")){
            handleSubmit();
        }
    }
    const handleFriendlyIdChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFriendlyId(e.currentTarget.value)
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value)
    }

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                _hover={{ bg: "AbleYellow", textColor: "white" }}
                _active={{ bg: "#ECB500" }}

                onClick={handleClickOpen} leftIcon={<BiPlus />}>
                Create Outcome
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "full", lg: "md" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Create New Outcome"}</ModalHeader>
                    <ModalBody>

                        <FormControl>
                            <FormLabel>Please complete the form, ensuring all fields are correct, before submitting.</FormLabel>

                            <Input onKeyDown={handleKeyDown}  placeholder="Friendly Id" type="text" autoComplete="new-password" value={friendlyId} onChange={handleFriendlyIdChange} isRequired></Input>
                            <Input onKeyDown={handleKeyDown}  marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Name" value={name} onChange={handleNameChange} isRequired></Input>
                            <Input onKeyDown={handleKeyDown}  marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Description" value={description } onChange={handleDescriptionChange} isRequired></Input>
                        </FormControl>


                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => handleSubmit()}
                            isDisabled={friendlyId === "" || name === "" || description === ""}
                            bg={"AbleBlue"}
                            textColor={"white"}
                            _hover={{ textColor: "AbleYellow" }}>Create Outcome</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
