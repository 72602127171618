import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, AlertDialogCloseButton,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { IoMdAdd } from "react-icons/io"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "./addSkillRow";


interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
    handleAddedSkills: Function,
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}
interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}

export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
const skills: { [skill_id: string]: ISkill } = {};

interface IAddSkillMobileProps {
    levels: Level[],
    handleAddedSkills: Function,
    name: string,
    skill: Skill,

}
export default function AddSkillMobile(props: Skill) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const CacheContext = useCache();
    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const [open, setOpen] = useState(false);
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [label, setLabel] = useState<string>(props.levels[0].id);
    const [level, setLevel] = useState<Level>(props.levels[0]);

    const [maxPages, setMaxPages] = useState<number>(1);

    const webHelpers = useWebHelper();



    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    useEffect(() => {
        onClearSkills();
    }, [open])
    const handleLevelChange = (e: any) => {
        setLabel(e.target.value);
        props.levels.forEach(element => {
            if (element.id === e.target.value) {
                setLevel(element);
            }

        });
    }
    const addSkill = () => {
        if (level !== undefined) {
            const addedSkill: ISkillToAdd = {
                skill_id: props.id,
                level_id: level.id,
                name: props.name,
                level_name: level.name,
            }
            props.handleAddedSkills(addedSkill)
        } else {
            console.log(`Please choose a level before clicking Add`, { 'variant': 'warning' });
        }
    }

    return (
        <Flex>
            <Button
                bg={"AbleBlue"}
                textColor={"white"}
                display={{ base: "revert", lg: "none" }}
                marginLeft={"-40px"}
                onClick={() => {
                    onOpen();
                    setOpen(true);
                    setPage(0)
                    populateSkills();
                }}>
                +
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "full", lg: "4xl" }}

            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Add Skill: " + props.name}</ModalHeader>
                    <ModalBody>
                        <Select value={label} onChange={handleLevelChange} defaultValue={props.levels[0].id} display={{ base: "revert", lg: "none" }}>
                            {props.levels.map((each) =>
                                <option value={each.id}>{each.name}</option>
                            )}
                        </Select>
                        <Flex direction={"column"} justifyContent={"space-around"} marginTop={"20px"}>
                            <Button bg={"AbleBlue"}
                                textColor={"white"}
                                display={{ base: "revert", lg: "none" }}
                                marginBottom={"20px"}
                                onClick={addSkill}>Add Skill</Button>
                            <Button colorScheme={"red"}
                                onClick={onClose}>Cancel</Button>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
}

