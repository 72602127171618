import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Toast } from "@chakra-ui/react";
import { Table, useColorMode, Spinner, useToast, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { color } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateGroup from "../../components/groups/createGroup";
import EditGroupModal from "../../components/groups/editGroup";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import TableControlsPermissions from "../../components/table/tableControlsPermissions";
import Refresh from "../../components/refresh";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}

interface IGroups {
    id: string,
    is_security: boolean,
    name: string,
}
export const GroupsPage = () => {
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<IGroups[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [nextPage, setNextPage] = useState<IGroups[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(1);
    const { colorMode, toggleColorMode } = useColorMode();

    const { isOpen, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();
    const toast = useToast();


    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {

        }
    }, [])
    function goToNextPage(pageToLoad: number) {

        webHelpers.GetAsync('/api/management/groups/' + rowsPerPage + '/' + (pageToLoad + 1) + '/' + search, 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.groups)
                data.groups.forEach((element: IGroups) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                setMaxPages(data.max_pages)
                setLoading(false);
            }
        });
    }

    function searchGroups(search: string) {
        webHelpers.GetAsync('/api/management/groups/' + rowsPerPage + '/' + (page + 1) + '/' + search, 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                return toast({
                    position: "bottom",
                    title: `No groups found by the name "${search}", please try another search`,
                    status: "warning",
                    isClosable: true,
                });
            }
            else {
                setRows(data.groups);
                setMaxPages(data.max_pages);
                setLoading(false);
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            setLoading(false);
            return toast({
                position: "bottom",
                title: `No groups found by the name "${search}", please try another search`,
                status: "warning",
                isClosable: true,
            });

        });
    }

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchGroups(search)
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchGroups(search)
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);


    const refresh = () => {
        setIgnore(true);
        searchGroups("");
        setLoading(true);
        setNextPageNo(page + 1);
    }
    const handleProfileChange = (event: any) => {
        setLoading(true);
        setSelectedType(event.target.value);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.target.value);
    }
    useEffect(() => {
        onToggle();
    }, [loading]);

    useEffect(() => {
        setRows([])
        searchGroups(search);
    }, [EnvironmentContext.selectedEnvironment])

    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    return (

        <ScaleFade initialScale={1} in={isPageOpen}>

            <>
                <Heading color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"}
                >Group Management</Heading>

                <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                    <TableControlsPermissions handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} handleSearchChange={handleSearchChange} search={search} />
                    <Select onChange={handleEnvChange} value={EnvironmentContext.selectedEnvironment.id} width={"175px"} marginRight={"20px"} defaultValue={"live"} display={{ base: "none", lg: "revert" }}>
                        {EnvironmentContext.environments.map((workflow: IEnvironment) => (
                            <option value={workflow.id}>{workflow.name}</option>
                        ))}
                    </Select>
                    <Input placeholder="Search Bar" value={search} onChange={handleSearchChange} width={"300px"} display={{ base: "none", lg: "revert" }}></Input>
                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Rows per Page:</Text>
                    <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"} display={{ base: "none", lg: "revert" }}>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                    </Select>
                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Page {page + 1} of {maxPages} </Text>
                    <Button display={{ base: "none", lg: "revert" }} bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={() => {
                        page !== 0 && setPage(page - 1);
                        setLoading(true);
                    }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                    <Button display={{ base: "none", lg: "revert" }} size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={() => {
                        page !== maxPages - 1 && setPage(page + 1)
                        setLoading(true);
                    }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                    <Refresh cb={() => refresh()} />
                    <CreateGroup cb={() => searchGroups("")} />
                </Flex>
                {!loading ?
                    <>
                        <Flex direction={'row'} h={650 * ((rows.length / rowsPerPage) + 1) + 10 + "px"} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                            <ScaleFade initialScale={1} in={!isOpen} reverse={loading}>
                                <TableContainer>
                                    <Table variant={"simple"} width={"90%"}>

                                        <InfiniteScroll
                                            dataLength={rows.length}
                                            next={() => {
                                                if (nextPageNo < page) {
                                                    setNextPageNo(page);
                                                    goToNextPage(page + 1)
                                                }
                                                else {
                                                    goToNextPage(nextPageNo)
                                                    setNextPageNo(nextPageNo + 1);
                                                }


                                            }}
                                            hasMore={nextPageNo < maxPages - 2}
                                            loader={
                                                <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                                    <Spinner color="AbleBlue" marginTop={"-10px"} marginBottom={"600px"} size={"xl"}></Spinner>
                                                    {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                                </Flex>
                                            }
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                                </p>
                                            }
                                            scrollThreshold={1}
                                        >
                                            <TableCaption>
                                                <Text>
                                                    {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                                </Text>
                                            </TableCaption>
                                            <Thead>
                                                <Tr>
                                                    <Th>Name</Th>
                                                    <Th display={{ base: "none", lg: "revert" }}>Security</Th>
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                {rows.map((each) =>
                                                    <Tr>
                                                        <Td paddingRight={{ base: "10px", lg: "300px" }}>{each.name}</Td>
                                                        <Td paddingRight={"300px"} display={{ base: "none", lg: "revert" }}>{each.is_security ? "Yes" : "No"}</Td>
                                                        {/* edit modal goes here  */}
                                                        <Td></Td>
                                                        <Td><EditGroupModal id={each.id} cb={() => searchGroups(search)} /></Td>
                                                    </Tr>
                                                )}
                                            </Tbody>
                                        </InfiniteScroll>


                                    </Table>
                                </TableContainer>
                            </ScaleFade>
                        </Flex>
                    </>

                    :
                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"backgroundDarkGrey"}>
                        {colorMode === 'light' ?
                            <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                            :
                            <Spinner color="AbleYellow" marginTop={"0px"} marginBottom={"100px"} size={"xl"} />
                        }
                    </Flex>
                    // {/* </ScaleFade> */}

                }

                <ScaleFade initialScale={0.1} in={defaultView}>
                </ScaleFade>
            </>
        </ScaleFade>

    )
}
