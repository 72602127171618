import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";

interface CreatePermissionProps {
    cb: Function,
}
export default function CreatePermission(props: CreatePermissionProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [name, setName ] = useState<string>("");
    const [description, setDescription ] = useState<string>("");
    const [loading, setLoading ] = useState<boolean>(false);
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isSecurity, setIsSecurity ] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    const toast = useToast();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    const createPermission = () =>  {
        webHelpers.PutAsync('/api/management/permission', 'auth', {id: name, name: description}).then((data: any) => {
            setLoading(false);
            if (data.id !== name || data.name !== description) {
                
                return toast({
                    position: "bottom",
                    title: `${data.status}: Unable to create permission with ID: ${name}, please if check this permission doesn't already exist.`,
                    status: "error",
                    isClosable: true,
                });
            }
            else {
                handleClose();
                props.cb();
                return toast({
                    position: "bottom",
                    title: `Permission ${name} successfully created!`,
                    status: "success",
                    isClosable: true,
                });
            }
            
        }).catch((error) => {
            setLoading(false);
            return toast({
                position: "bottom",
                title: `${error.status}: Unable to create permission with ID: ${name}, please if check this permission doesn't already exist.`,
                status: "error",
                isClosable: true,
            });
        })
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value)
    }

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={"30px"}
                _hover={{ bg: "AbleYellow", textColor: "white" }}
                _active={{bg: "#ECB500"}}
                
                onClick={handleClickOpen} leftIcon={<BiPlus />}>
                Create Permission
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Create New Permission"}</ModalHeader>
                    <ModalBody>

                        <FormControl>
                            <FormLabel>Please complete the form, ensuring all fields are correct, before submitting.</FormLabel>

                            <Input placeholder="ID" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>
                            <Input placeholder="Description" type="text" autoComplete="new-password" value={description} onChange={handleDescriptionChange}></Input>
                            
                        </FormControl>


                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            setLoading(true);
                            createPermission();
                            
                        }}
                        isLoading={loading}
                        bg={"AbleBlue"}
                        textColor={"white"}
                        _hover={{ textColor: "AbleYellow" }}>Create Permission</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
