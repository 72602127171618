import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
  Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
  Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
  AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
  SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { useEnvironment } from "../../context/EnvironmentContext";
import e from "express";


interface Application {
  id: string;
  ttl: number;
  enabled: boolean | undefined;
}
interface UserAppsDialogProps {
  user: User;
  cb: Function;
}
export interface Type {
  id: string,
  name: string,
}
export interface User {
  id: string,
  account_id: string,
  applications: []
  created: string,
  created_by_id: string
  department: string
  email_address: string,
  first_name: string,
  last_name: string,
  full_name: string,
  groups: [],
  initiated: boolean,
  status: number,
  type: Type,
  type_id: string,
  user_type_id: string,
}


export default function UserAppPermissionsModal(props: UserAppsDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [applications, setApplications] = useState<Application[]>([
    { id: "echo", ttl: 1235, enabled: false },
  ]);
  const [loaded, setLoaded] = useState(false);
  const [changes, setChanges] = useState<any[]>([]);
  const CacheContext = useCache();
  const toast = useToast();
  const [state_change, setState_change] = useState<number>(0); //doesn't do anything but without it the checkbox won't update! 

  // useEffect(() => {
  //   console.log(state_change)
  // },[state_change]);

  const webHelpers = useWebHelper();
  const handleClickOpen = () => {
    setChanges([]);
    webHelpers.GetAsync(
      "/api/management/applications",
      "auth"
    ).then((data: any) => {
      setApplications(data);
      data.forEach((each: any) => {
        props.user.applications.forEach((app: Application) => {
          if (app.id == each.id) {
            each.enabled = true;
          }
        });
      });
      onOpen();
    }).catch((error) => {
      return toast({
        position: "bottom",
        title: `Error fetching applications, error: [${error}]`,
        status: "error",
        isClosable: true,
    }); 
    });

  };

  const handleClose = () => {
    changes.forEach((each) => {
      if (each[1] === false) {
        revokeApp(each[0]);
      }
      else {
        assignApp(each[0]);
      }
    })
    onClose();
    props.cb();
  }

  function handleRadioChange(appId: string, value: boolean) {
    console.log("appid = ", appId, " , value = ", value)
    let search = changes.some(item => appId === item[0]);
    if (search){
      changes[changes.findIndex((item => item[0] === appId))][1] = value;
    }
    else{
      setChanges(changes => [...changes, [appId, value]])
    }
    console.log(changes)
  }
  function assignApp(id: string) {
    webHelpers.PostAsync(
      "/api/management/application/" + id + "/assign/" + props.user.id,
      "auth",
      {}
    ).then((res) => {
      props.cb();
    }).catch((error) => {
      
      return toast({
        position: "bottom",
        title: `Error setting applications, error: [${error}]`,
        status: "error",
        isClosable: true,
    }); 
    });
  }

  function revokeApp(id: string) {
    webHelpers.PostAsync(
      "/api/management/application/" + id + "/revoke/" + props.user.id,
      "auth",
      {}
    ).then((res) => {
      props.cb();
    }).catch((error) => {
      return toast({
        position: "bottom",
        title: `Error removing applications, error: [${error}]`,
        status: "error",
        isClosable: true,
    }); 
    });
  }

  function userHasApplicationCheck(id: string) {
    let found = false;
    props.user.applications.forEach((app: Application) => {
      if (app.id == id) {
        found = true;

      }
    });
    return found;
  }

  useEffect(() => {
    if(props.user.applications !== null && props.user.applications !== undefined){ //this check prevents a rendering error
      applications.forEach(each => {
        props.user.applications.forEach((app: Application) => {
          if (app.id == each.id) {
            each.enabled = true;
          };
        });
      });
    }
    
    //@ts-ignore
  }, [applications])
  return (
    <>
      <Button onClick={handleClickOpen} leftIcon={<MdOutlineSettingsApplications />} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
        Apps
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        size={{base: "full", lg: "md"}}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{"Manage User Applications"}</ModalHeader>
          <ModalBody>
            <Stack
              direction="column"
              spacing={1}
              sx={{ padding: 1, alignItems: "left", justifyContent: "left" }}
            >

              {applications.map((app: Application) => (
                <FormControl>
                  <FormLabel>{app.id}</FormLabel>
                  <Checkbox

                    style={{ alignContent: "center" }}
                    isChecked={app.enabled === true}
                    onChange={() => {  
                      handleRadioChange(
                        app.id,
                        !(app.enabled)
                      )
                      app.enabled = !(app.enabled)
                      setState_change(state_change + 1)

                    }
                    }
                    value="a"
                    inputProps={{ "aria-label": app.id }}
                  />
                </FormControl>
              ))
              }

            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>Finish</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
