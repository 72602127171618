import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast, 
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io"
import { IoPersonRemoveSharp } from "react-icons/io5";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}


export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export default function EnableDisable(props: UserAppsDialogProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();

    const webHelpers = useWebHelper();
    const toast = useToast();
    const handleClickOpen = () => {
        onOpen();
    }
    function resetPassword() {
        let payload = { 'user_id': props.user.id }
        webHelpers.PostAsync('/api/management/password/reset/request', 'auth', payload).then((data: any) => {
            if (data.status === 200) {
                return toast({
                    position: "bottom",
                    title: `Password reset request sent!`,
                    status: "success",
                    isClosable: true,
                });
            }
            else {
                return toast({
                    position: "bottom",
                    title: `Unable to reset this User's password! [${data.status}]`,
                    status: "error",
                    isClosable: true,
                }); 
            }
        }).catch((error) => {
            handleClose();
        })
    }
    const enableUser = () => {
        webHelpers.PostAsync("/api/management/user/" + props.user.id + "/enable", 'auth', "",).then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                return toast({
                    position: "bottom",
                    title: `Error running API call, could not enable user`,
                    status: "error",
                    isClosable: true,
                }); 
            }
            else {
                props.cb();
                handleClose();
            }
        })
    }
    const disableUser = () => {
        webHelpers.PostAsync("/api/management/user/" + props.user.id + "/disable", 'auth', "",).then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                return toast({
                    position: "bottom",
                    title: `Error running API call, could not disable user`,
                    status: "error",
                    isClosable: true,
                }); 
                
            }
            else {
                props.cb(props.user.id);
                handleClose();
            }
        })
    }

    const handleClose = () => {
        onClose();
    }
  return (
        <>
            {String(props.user.status) === "Active" ?
                <Button  leftIcon={<IoPersonRemoveSharp />} onClick={handleClickOpen}bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>Disable</Button>
                :
                <Button  leftIcon={<IoMdPersonAdd />} onClick={handleClickOpen}bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>Enable</Button>
            }
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{String(props.user.status) === "Active" ? "Disable User? ": "Enable User?"}</ModalHeader>
                    <ModalBody>
                        {String(props.user.status) === "Active" ?
                            <>
                                <Text id="modal-modal-description" sx={{ mt: 2 }}>
                                    Are you sure you would like to disable this user?
                                </Text>
                                <Button 
                                    margin={"10px"}
                                    onClick={disableUser}

                                >
                                    Yes
                                </Button>
                            </>
                            :
                            <>
                                <Text id="modal-modal-description" sx={{ mt: 2 }}>
                                    Are you sure you want to enable this user?
                                </Text>
                                <Button
                                    onClick={enableUser}
                                >
                                    Yes
                                </Button>
                            </>
                        }
                        <Button
                            onClick={handleClose}
                            
                        >
                            No
                        </Button>

                    </ModalBody>
                </ModalContent>

            </Modal>
        </>
    );
}
