import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useColorMode
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";


interface CloneRoleDialogProps {
    cloneRoleFunction: Function;
    isSelected: boolean;
}

export default function CloneRole(props: CloneRoleDialogProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const {colorMode, toggleColorMode} = useColorMode();


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }


    return (
        <>
            <Button bg={props.isSelected ? "AbleBlue" : "backgroundDarkGrey"} textColor={colorMode === 'light' ? props.isSelected ? "white" : "AbleBlue" : props.isSelected ? "white" : "white"}
                _hover={props.isSelected ? { bg: "AbleBlue", textColor: "AbleYellow" } : { bg: "backgroundDarkGrey", textColor: "AbleYellow" }}
                onClick={handleClickOpen} leftIcon={<MdOutlineSettingsApplications />}>
                Clone
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Clone Role"}</ModalHeader>
                    <ModalBody>
                        <Text>Enter the name of the new role.</Text>
                        <Input placeholder="Name" value={text} onChange={handleTextChange}></Input>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleClose} colorScheme="red">Cancel</Button>
                        <Button onClick={() => { props.cloneRoleFunction(text); handleClose(); }}>Clone</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
