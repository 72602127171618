import * as React from "react";
import {
    Button,useDisclosure,AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, AlertDialogCloseButton,
} from '@chakra-ui/react';
interface ConfirmProps {
    confirmFunction: Function,
    declineFunction: Function,
    buttonText: string,
    buttonWarning: string,
}
export default function GetUserConfirm(props: ConfirmProps) {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef(null)

    return (
        <>
            <Button onClick={onOpen}
            bg={"AbleBlue"}
            textColor={"white"}
            _hover={{ textColor: "AbleYellow" }}>{props.buttonText}</Button>
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Are you Sure?</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {props.buttonWarning}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={() => {
                            props.declineFunction();
                            onClose();
                        }}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => {
                            props.confirmFunction();
                            onClose();
                        }}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )

}