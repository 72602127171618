import * as React from "react";
import { useState, useEffect } from "react";
import { IWebHelperErrorResponse, useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Spinner, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

interface Event {
    id: string,
    name: string,
}
interface CreateWorkflowProps {
    cb: Function,
}
export default function CreateWorkflow(props: CreateWorkflowProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [selectedEvent, setSelectedEvent] = useState<string>("");


    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const toast = useToast();
    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        setName("");
        setDescription("");
        setSelectedEvent("");
        setPage(0);
        setSearch("");
        searchEvents();
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    const handleSubmit = () => {
        let payload = {
            'name': name,
            'description': description,
            'event_id': selectedEvent,
            'code': '// start coding here',
            'steps': [
                {
                    'name': 'Default Step Name',
                    'description': 'Default Description',
                    'tag': 'default',
                    'cooldown_time': '00:01:00',
                    'estimated_length': '00:01:00',
                    'execution_type': 'CodeBased',
                    'execution_code': `//Event Code`,
                    'events': [],
                }
            ]
        }
        webHelpers.PostAsync('/api/workflow/create', 'helios-api', payload).then((data: any) => {
            if (data.status === 400 || data.status === 404 || data.status === 500 || data.status === 401) {
                console.log(`${data.status}: Some error ocurred processing this request.`, { 'variant': 'error' });
            }
            else {
                handleClose();
                props.cb();
                return toast({
                    position: "bottom",
                    title: `Workflow ${payload.name} successfully created!`,
                    status: "success",
                    isClosable: true,
                }); 

            }
        })
    }
    // useEffect(() => {
    //     searchEvents();
    // }, [search]);

    const searchEvents = () => {
        if (search !== '') {
            webHelpers.GetAsync('/api/events/?page=1&pageSize=200&filter=' + search, 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    return toast({
                        position: "bottom",
                        title: `Unable to fetch permissions with this name, please try another`,
                        status: "error",
                        isClosable: true,
                    }); 
                }
                else {
                    if (data.length / rowsPerPage < 1) {
                        
                        setMaxPages(Math.floor((data.length / rowsPerPage)) +1);

                    }
                    else {
                        
                        setMaxPages(Math.floor((data.length / rowsPerPage)));
                    }
                    setEvents(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    setLoading(false);
                }
            }).catch((error: IWebHelperErrorResponse) => {
                return toast({
                    position: "bottom",
                    title: `Unable to fetch permissions with this name, please try another`,
                    status: "error",
                    isClosable: true,
                }); 
            });
        }
        else {
            webHelpers.GetAsync('/api/events/?page=1&pageSize=200', 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
                }
                else {
                    if (data.length / rowsPerPage < 1) {
                        
                        setMaxPages(Math.floor((data.length / rowsPerPage)) +1);

                    }
                    else {
                        
                        setMaxPages(Math.floor((data.length / rowsPerPage)) +1);
                    }
                    setEvents(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    setLoading(false);
                }
            }).catch((error: IWebHelperErrorResponse) => {
                console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
            });
        }
        
    }
    useEffect(() => {
        searchEvents();
    },[page, rowsPerPage])

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value);
    }
    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        searchEvents();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleEventClick = (id: string) => {
        setSelectedEvent(id);
    }
    return (
        <>
            <Button  size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginLeft={"30px"}
                onClick={handleClickOpen} leftIcon={<BiPlus />}>
                Create Workflow
            </Button>
            <Modal
                isOpen={isOpen}

                onClose={onClose}
                size={"2xl"}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Create New Workflow"}</ModalHeader>
                    <ModalBody>

                        <FormControl marginBottom={"20px"}>
                            <FormLabel>Please complete the form, ensuring all fields are correct, before submitting.</FormLabel>

                            <Input placeholder="Name" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>
                            <Input marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Description" value={description} onChange={handleDescriptionChange}></Input>
                            
                        </FormControl>
                        <Text as={"i"} marginTop={"20px"}>Use the table below to select an Event for the new Workflow</Text>
                        <Flex direction={"row"}>
                        
                        <Input placeholder="Search Bar" value={search} onChange={handleSearchChange} width={"230px"}></Input>
                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </Select>
                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                        <Button bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                            page !== 0 && setPage(page - 1);
                            setLoading(true);
                        }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                        <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                            page !== maxPages - 1 && setPage(page + 1)
                            setLoading(true);
                        }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                        </Flex>
                        {events.length === 0 ?
                            <Text marginTop={"150px"} fontSize={"xl"}>No Workflows Found!</Text>
                            :

                            <TableContainer>
                                {!loading ?
                                    <Table variant={"simple"}>
                                        <Thead>
                                            <Tr>
                                                <Th>Name</Th>
                                                <Th>ID</Th>
                                            </Tr>
                                        </Thead>

                                        <Tbody>
                                            {events.map((each) =>
                                                <Tr _hover={{bg:"whitesmoke"}} onClick={() => handleEventClick(each.id)} borderColor={"AbleBlue"} borderWidth={each.id === selectedEvent ? "3px" : "0px"}
                                                bg={each.id === selectedEvent ? "whitesmoke" : "white"} pointerEvents={"visibleStroke"}>
                                                    <Td>{each.name}</Td>
                                                    <Td>{each.id}</Td>
                                                </Tr>
                                            )}

                                        </Tbody>

                                    </Table>
                                    :
                                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                        <Spinner color="AbleBlue" />
                                    </Flex>

                                }
                            </TableContainer>
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => handleSubmit()}
                            bg={"AbleBlue"}
                            textColor={"white"}
                            _hover={{ textColor: "AbleYellow" }}>Create Workflow</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
