import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, useToast, TabIndicator
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import StepsComponent from "./stepsComponent";
import { BsCodeSlash } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight, AiFillDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { CodeEditor } from "../code/codeEditor";
import GetUserConfirm from "../confirm";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
interface IWorkflow {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
    cb: Function
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
interface StepsProps {
    timeprofiles: Timeprofiles[],
    step: IWorkflowStep,
    workflow_id: string,
    cb: Function,
    refreshEventsFunction: Function,
}
interface Event {
    name: string,
    id: string,
}
interface StepOutcomeProps {
    stepId: string,
    outcomeId: string,
    cb: Function,
}
interface IOutcome {
    id: string,
    friendly_id: string,
    name: string,
    description: string,
}
interface IStepOutcomes {
    definitionId: string,
    id: string,
    isCompletionOutcome: boolean,
    isQuickOutcome: boolean,
    isRescheduleOutcome: boolean,
    outcomeDefinition: string | null,
    outcomeDefinitionId: string,
    step: string | null,
    stepId: string,
    workflow: string | null
}

export default function AddOutcome(props: StepOutcomeProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCompletionOutcome, setIsCompletionOutcome] = useState<boolean>(false);
    const [isQuickOutcome, setIsQuickOutcome] = useState<boolean>(false);
    const [isRescheduleOutcome, setIsRescheduleOutcome] = useState<boolean>(false);

    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const [initialOpen, setInitialOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
    const [steps, setSteps] = useState<IWorkflowStep[]>();
    const [value, setValue] = useState(1);
    const [timeprofiles, setTimeprofiles] = useState<Timeprofiles[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPagesFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [selectedEvent, setSelectedEvent] = useState<string>("");
    const [goToStage2, setGoToStage2] = useState<boolean>(false);
    const [code, setCode] = useState<string>("");
    const [outcomes, setOutcomes] = useState<IOutcome[]>([])
    const [stepOutcomes, setStepOutcomes] = useState<IStepOutcomes[]>([]);


    const [forcePassword, setForcePassword] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    const toast = useToast();

    const handleClickOpen = () => {
        setIsCompletionOutcome(false);
        setIsQuickOutcome(false);
        setIsRescheduleOutcome(false);
        onOpen();
        searchStepOutcomes();
    }
    const handleClose = () => {
        onClose();
        props.cb();
    }
    const handleSearchChange = (e: any) => {
        setSearch(e.currentTarget.value);
    }
    const searchStepOutcomes = () => {
        console.log("test")
        webHelpers.GetAsync<any>("/api/workflow/step/" + props.stepId + "/outcomes", "helios-api").then((res) => {
            console.log("Outcomes for this step ", res);
        })
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getStepOutcomes = () => {
        webHelpers.GetAsync<IStepOutcomes[]>("/api/workflow/step/" + props.stepId + "/outcomes", "helios-api").then((res) => {
            if (res !== null && res !== undefined) {
                console.log("getStepOutcomes response = ", res);
                setStepOutcomes(res);
                setMaxPagesFirstTab(Math.ceil(Number((res.length / rowsPerPage))));
            }
        })
    }
    const getOutcomes = () => {
        webHelpers.GetAsync<IOutcome[]>("/api/config/outcomes?page=" + (page + 1) + "&pageSize=" + rowsPerPage, "helios-api").then((res) => {
            if (res !== null && res !== undefined) {
                setOutcomes(res);
                setMaxPages(Math.ceil(Number((res.length / rowsPerPage))));
            }
        })
    }
    const handleDelete = (id: string) => {
        webHelpers.DeleteAsync<any, any>("/api/workflow/step/" + props.stepId + "/outcome/" + id, "helios-api", {}).then((res) => {
            console.log("delete step outcome reponse = ", res);
            getStepOutcomes();
        }).catch((error) => {
            console.log("caught error while deleting linked outcome [" + error.status + "" + "]");
            getStepOutcomes();
        })
    }
    const AddOutcomeToStep = () => {
        console.log("adding outcome")
        let payload = {
            is_completable: isCompletionOutcome,
            is_quick_outcome: isQuickOutcome,
            is_reschedulable: isRescheduleOutcome,
        }
        console.log(payload)
        webHelpers.PutAsync("/api/workflow/step/" + props.stepId + "/outcome/" + props.outcomeId, "helios-api", payload
        ).then((res) => {
            handleClose();
            return(
                toast({
                    position: "bottom",
                    title: "Successfully added outcome to workflow step",
                    status: "success",
                    isClosable: true
                })
            )
        }).catch((error) => {
            console.log("error adding outcome to workflow step ", error);
            return(
                toast({
                    position: "bottom", 
                    title: "Failed to add outcome to workflow step [" + error.status + "" + "]",
                    status: "error",
                    isClosable: true,
                })
            )
        })
    }

    // const handleSubmit = () => {
    //     props.cb(props.stepId, isCompletionOutcome, isQuickOutcome, isRescheduleOutcome);
    //     handleClose();
    // }

    return (
        <>
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                onClick={handleClickOpen} leftIcon={<BiAddToQueue />}
            >
                Add Outcome
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"xs"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Outcome Properties"}<br />
                        <Text as={"i"} marginBottom={"1px"} fontSize={"sm"}>Please select the properties of the outcome you want to apply.</Text></ModalHeader>
                    <ModalBody>
                        <>
                            <Flex direction={"column"} justifyContent={"center"} w={"100%"}>
                                <Checkbox size={"lg"}  checked={isCompletionOutcome} onChange={() => setIsCompletionOutcome(!isCompletionOutcome)}>Is Completable?</Checkbox>
                                <Checkbox size={"lg"}  checked={isQuickOutcome}      onChange={() => setIsQuickOutcome(!isQuickOutcome)}          >Is Quick Outcome?</Checkbox>
                                <Checkbox size={"lg"}  checked={isRescheduleOutcome} onChange={() => setIsRescheduleOutcome(!isRescheduleOutcome)}>Is Reschedulable?</Checkbox>
                            </Flex>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent={"space-between"}>
                            <Button bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                                onClick={AddOutcomeToStep}>Add Outcome</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
