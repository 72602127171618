import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { RiEdit2Line } from "react-icons/ri";
import { GrRefresh } from "react-icons/gr";
import { IoMdRefresh } from "react-icons/io";
import "./style.css"

interface RefreshProps {
    cb: Function,
}
export default function Refresh(props: RefreshProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [animate, setAnimate] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    
    const handleClick = () => {
        setAnimate(true);
        props.cb();
        setTimeout((() => setAnimate(false)), 750);
    }

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                _hover={{ bg: "AbleYellow", textColor: "white" }}
                _active={{ bg: "#ECB500" }} onClick={handleClick}>
                <Text fontSize={"20px"}><IoMdRefresh className={animate ? "icn-spinner" : "blank"}/> </Text>
            </Button>
        </>
    );
}
