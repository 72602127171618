import { ReactNode, createContext, useContext, useState } from 'react';

const CacheContext = createContext<ICacheContext | undefined>(undefined);

interface ICacheItem<T> {
    value: T,
    expiry: string | undefined
}

interface ICacheContext {
    lookUpCacheItem<T>(ref: string): T | null;
    addCacheItem<T>(ref: string, value: T): void;
}

function useCacheProvider() {
    let cacheStore: {[key: string]: ICacheItem<any>} = {};

    function lookUpCacheItem<T>(reference: string): T | null {
        let ret = cacheStore[reference];
        if (ret === undefined) return null;

        return ret.value;
    }

    function addCacheItem<T>(reference: string, value: T): void {
        let item: ICacheItem<T> = {
            value: value,
            expiry: undefined
        };
        cacheStore[reference] = item;
    }

    return {lookUpCacheItem, addCacheItem}
}

export function CacheProvider({children}: {children: ReactNode}) {
    const value = useCacheProvider();

    return (
        <CacheContext.Provider value={value}>
            {children}
        </CacheContext.Provider>
    )
}

export function useCache() {
    const context = useContext(CacheContext);
    if (context === undefined){
        throw new Error("useCache must be used within a CacheProvider");
    }

    return context;
}