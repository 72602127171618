import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "../skills/addSkillRow";

import { useDictionary } from "../../scripts/useDictionary";
import { StringMappingType } from "typescript";
import SelectedSkillsForRole from "./selectedSkillsForRole";
import e from "express";

interface NewRoleProps {
    userId: string,
    cb: Function,
}
interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}
export interface OutboundNumbers {
    id: string,
    name: string,
    phoneNumber: string,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}

export default function NewRole(props: NewRoleProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumbers[]>([]);
    const [selectedOutbound, setSelectedOutbound] = useState<string>();
    const [test_array, setTest_array] = useState<SkillTest[]>([]);



    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const toast = useToast();

    function addSkills(additionalSkills: SkillTest[]) {
        let test_object = {
            skill_id: "",
            level_id: "",
            name: "",
            level_name: "",
        }
        // @ts-ignore
        test_object.skill_id = Object.values(additionalSkills)[0];
        // @ts-ignore
        test_object.level_id = Object.values(additionalSkills)[1];
        // @ts-ignore
        test_object.name = Object.values(additionalSkills)[2];
        // @ts-ignore
        test_object.level_name = Object.values(additionalSkills)[3];
        // setTest_array(test_array => [...test_array, test_object]);

        skills[test_object.skill_id] = test_object;
        let found = false;
        test_array.forEach(element => {
            if (element.level_name === test_object.level_name){
                found = true;
            }
        });
        if(found === false){
            setTest_array(test_array => [...test_array, test_object]);
            return toast({
                position: "bottom",
                title: `Added skill ${test_object.level_name}`,
                status: "success",
                isClosable: true,
            })
        }
        else{
            return toast({
                position: "bottom",
                title: `You have already added this skill to the new Role`,
                status: "warning",
                isClosable: true,
            })
        }
        console.log(test_object)

    }


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        populateSkills();
        getOutboundNumbers();
    }

    const handleClose = () => {
        onClose();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSelectedOutboundChange = (event: any) => {
        setSelectedOutbound(event.target.value);
    }

    const handleSubmit = () => {
        if (text !== '' && selectedOutbound !== undefined) {
            
            let newSkills: ISkill[] = [];
            Object.values(skills).forEach(skill => {
                newSkills.push({ skill_id: skill.skill_id, level_id: skill.level_id });
            });

            let data = {
                'name': text,
                'outbound_number_id': selectedOutbound,
                'skills': newSkills,
                'worker_id': props.userId,
            }
            webHelpers.PutAsync('/api/worker/role', 'zeus-api', data).then((data: any) => {
                console.log("create role result ", data);
                if (data.status === 400) {
                    return toast({
                        position: "bottom",
                        title: `There was an error trying to create this role [${data.status}]`,
                        status: "error",
                        isClosable: true,
                    })
                }
                else {
                    //setCurrentPotentialSkills([])
                    props.cb();
                    return toast({
                        position: "bottom",
                        title: `${text} Created!`,
                        status: "success",
                        isClosable: true,
                    })
                }
            })
        }
        else {
            return toast({
                position: "bottom",
                title: `Please ensure you have added all relevant fields`,
                status: "warning",
                isClosable: true,
            })
        }
    }

    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }
    const getOutboundNumbers = () => {
        webHelpers.GetAsync('/api/hosted/outboundnumbers', 'zeus-api').then((data: any) => {
            if (data != undefined && data != null) {
                setOutboundNumbers(data)
            }
            else {
                console.log(`${data.status}: unable to fetch outbound numbers`)
            }
        })
    }
    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        if (open && allSkills === undefined) {
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    useEffect(() => {
        console.log("skills have changed")
    }, [skills]);

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} _hover={{ textColor: "AbleYellow" }} marginTop={"10px"}
                onClick={handleClickOpen} leftIcon={<AiOutlinePlus />}>
                Create Role
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"4xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Create a new Role"}</ModalHeader>
                    <ModalBody>
                        <Flex direction={"row"} marginBottom={"20px"} marginTop={"10px"}>
                            <Flex direction={"column"} width={"45%"}>
                                <Text> Enter the name of the new role. </Text>
                                <Input placeholder="Name" value={text} onChange={handleTextChange}></Input>
                            </Flex>
                            <Flex direction={"column"} width={"50%"} marginLeft={"30px"}>
                                <Text>Select the Outbound Phone Number</Text>
                                <Select value={selectedOutbound} onChange={handleSelectedOutboundChange} placeholder="Select an entry from the list below">
                                    {outboundNumbers.map((each) => (
                                        <option value={each.id}>{each.name + " " + each.phoneNumber}</option>
                                    ))}
                                </Select>
                            </Flex>
                        </Flex>
                        <Flex direction={"row"} justifyContent={"right"}>
                        <SelectedSkillsForRole skills={test_array} />
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                            <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                <option value={5} >5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Select>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                            <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== 0 && setPage(page - 1);
                            }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                            <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== maxPages - 1 && setPage(page + 1)
                            }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                        </Flex>
                        {/* need to have a section or method of showing already selected skills */}
                        <TableContainer>
                            <Table variant={"simple"}>
                                <TableCaption>Current Environment : {CacheContext.lookUpCacheItem("environment")}</TableCaption>
                                <Thead>
                                    <Tr>
                                        {/* this will show all skill that are possible to see */}
                                        <Th>Skill Name</Th>
                                        <Th>Skill Level</Th>
                                        <Th>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    
                                    {pageSkills.map((skill) =>

                                        <AddSkillRow {...skill} handleAddedSkills={addSkills} />
                                    )}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => { handleSubmit(); handleClose(); }}>Create</Button>
                        <Button onClick={handleClose} colorScheme="red">Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
