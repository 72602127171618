import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export default function PasswordReset(props: User) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();

    const webHelpers = useWebHelper();
    const toast = useToast();
    const handleClickOpen = () => {
        onOpen();
    }
    function resetPassword() {
        let payload = { 'user_id': props.id }
        webHelpers.PostAsync('/api/management/password/reset/request', 'auth', payload,).then((data: any) => {
            console.log("password reset response : ", data);
            if (data.status === 200) {
                return toast({
                    position: "bottom",
                    title: `Password reset request sent`,
                    status: "success",
                    isClosable: true,
                }); 
            }
            else {
                return toast({
                    position: "bottom",
                    title: `Unable to send out password reset request`,
                    status: "error",
                    isClosable: true,
                }); 
            }
        }).catch((error) => {
            console.log("caught error", error)
            handleClose();
        })
    }
    const handleClose = () => {
        onClose();
    }

    return (
        <>
            <Button onClick={handleClickOpen} leftIcon={<MdOutlineSettingsApplications />} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                Reset Password
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Reset User Password?"}</ModalHeader>
                    <ModalBody>
                        <Text>This will send an email to the user asking them to reset their password, the link expires in 15 minutes.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={resetPassword}>Reset Password</Button>
                        <Button onClick={handleClose} colorScheme="red">Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
