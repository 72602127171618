import { Button, Input, Flex, Image, Box, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Checkbox } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { useAuth } from '../../context/AuthContext';
import { useSearchParams } from 'react-router-dom';

export const SignIn = () => {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [userName, setUserName] = useState<string>("");
    const [passWord, setPassWord] = useState<string>("");
    const [isWorking, setIsWorking] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [remember, setRemember] = useState<boolean>(true);
    const [name, setName] = useState<string>("");
    const authContext = useAuth();



    useEffect(() => {
        if (authContext.signInState === true && authContext.user?.user_id) {
            navigate("/staff/users");
        }
    }, []);

    const handleSubmit = async () => {
        setIsWorking(true);
        setIsError(false);

        try {
            let response = await authContext.signIn(userName, passWord, remember);
            if (response) {
                let returnUrl = searchParams.get("returnUrl");

                if (returnUrl != null) {
                    navigate(decodeURIComponent(returnUrl));
                } else {
                    navigate("/dashboard");
                }
            } else {
                setIsError(true);
            }
        } catch (error) {
            setIsError(true);
        } finally {
            setIsWorking(false);
        }
    };

    if (authContext.loading) {
        return <h1>Loading ...</h1>
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter"){
            handleSubmit();
        }
    }
    return (
        <>
            <Flex direction={"column"} width={"100%"} height={"100%"}>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"} marginTop={"10%"} >
                    <Image src={viablLogo} width={"10%"} align={"center"} display={"flex"}></Image>
                    <Box marginTop={"1%"} paddingLeft={'10%'} paddingRight={"10%"}>

                        <Heading textColor='var(--Able-blue)' marginBottom={"4%"}> Welcome to the Able Group Management Tools</Heading>

                        <InputGroup marginBottom={"1%"} size={"lg"}>
                            <InputLeftAddon backgroundColor={"AbleBlue"}
                                pointerEvents={"none"}
                                children={<AiOutlineUser color='white' />}

                            />
                            <Input onKeyDown={handleKeyDown} placeholder='Username' value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                        </InputGroup>
                        <InputGroup marginBottom={"2%"} size={"lg"}>
                            <InputLeftAddon backgroundColor={"AbleBlue"}
                                pointerEvents={"none"}
                                children={<BiLockOpenAlt color='white' />}

                            />
                            <Input onKeyDown={handleKeyDown} placeholder='Password' value={passWord} onChange={(e) => { setPassWord(e.target.value) }} type='password' />

                        </InputGroup>

                        <Checkbox isChecked={remember} onChange={e => setRemember(e.target.checked)}>Stay logged in?</Checkbox>
                        <Button onClick={handleSubmit} isLoading={isWorking} background='var(--Able-blue)' color={"white"} marginTop={"2%"} width={"100%"} loadingText="Submitting" spinnerPlacement='end'>Log In</Button>
                        {isError &&
                            <Alert status='error' width={"100%"} justifyContent={"center"} marginTop={"1%"}>
                                <AlertIcon />
                                <AlertTitle> Error Signing In</AlertTitle>
                                <AlertDescription>Incorrect Email Address / Password combination</AlertDescription>
                            </Alert>
                        }
                    </Box>

                </Flex>


            </Flex>
            <Flex direction={"column"} position={"absolute"} left={0} bottom={-550} right={0} display={"flex"} marginTop={"auto"}>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"} marginTop={"8%"} backgroundColor={"AbleYellow"} >
                    <Box width={"100%"} color={"AbleYellow"} backgroundColor={"AbleBlue"} boxSize={"2"}>
                        TEST
                    </Box>
                </Flex>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"} backgroundColor={"AbleBlue"}>
                    <Box width={"100%"} color={"AbleBlue"} backgroundColor={"AbleBlue"}>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>

                    </Box>
                </Flex>
            </Flex>
        </>
    )
}