
import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
//import {Checkbox, CheckboxGroup, Stack} from "@chakra-ui/react";
//@ts-ignore
import { TimePicker } from "./TimePicker";
import { start } from "repl";
import { setDefaultResultOrder } from "dns";
import { ClassNames } from "@emotion/react";
import { time } from "console";
import { stringify } from "querystring";
import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Toast, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react";
import { Checkbox, Modal, Table, Spinner, useToast, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import { BiAddToQueue } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";
import { BsSendFill } from "react-icons/bs";
import { MdLoop } from "react-icons/md";
import "../timeprofiles/timeprofile.css"



interface ITimeProfileProps {
    id: string;
    is_bank_holiday_aware: boolean;
    name: string;
    days: {
        id: string;
        day: string;
        start_time: string;
        end_time: string;
    }
    cb: Function;
}
interface IWorkflowModalProps {
    id: string;
    initializer_code_id: string;
}
export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
}

export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '45vw',
    height: '65vh',
    borderRadius: "15px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

var startTimesDict = {
    "Monday": "10:00",
    "Tuesday": "10:00",
    "Wednesday": "10:00",
    "Thursday": "10:00",
    "Friday": "10:00",
    "Saturday": "10:00",
    "Sunday": "10:00",
};
var endTimesDict = {
    "Monday": "10:00",
    "Tuesday": "10:00",
    "Wednesday": "10:00",
    "Thursday": "10:00",
    "Friday": "10:00",
    "Saturday": "10:00",
    "Sunday": "10:00",
}
interface ITimeProfileCreateProps {
    cb: Function
}
export default function CreateTimeprofile(props: ITimeProfileCreateProps) {
    const [open, setOpen] = useState(false);
    const [steps, setSteps] = useState<IWorkflowStep[]>();
    const [value, setValue] = useState(1);
    const [initialOpen, setInitialOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
    const [is_bank_holiday_aware, setBankHoliday] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const webHelpers = useWebHelper();
    const toast = useToast();
    const [startTimes, setStartTimes] = useState({
        Monday: "10:00",
        Tuesday: "10:00",
        Wednesday: "10:00",
        Thursday: "10:00",
        Friday: "10:00",
        Saturday: "10:00",
        Sunday: "10:00",
    });
    const [endTimes, setEndTimes] = useState({
        Monday: "10:00",
        Tuesday: "10:00",
        Wednesday: "10:00",
        Thursday: "10:00",
        Friday: "10:00",
        Saturday: "10:00",
        Sunday: "10:00",
    })
    const [week, setWeek] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });
    const handleOpen = () => {
        setOpen(true);
        setInitialOpen(true);
    }
    const handleClose = () => {
        setOpen(false)
    };
    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>,) => {
    //     setWeek({ ...week, [event.target.name]: event.target.checked });
    // }
    // below is CHAKRA version
    const handleChange = (name: string, checked: boolean) => {
        setWeek({ ...week, [name]: checked });
    }
    const handleBankHolidayChange = (event: React.ChangeEvent<HTMLInputElement>,) => {
        setBankHoliday(!is_bank_holiday_aware);
    }
    const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>,) => {
        setName(event.target.value);
    }
    const handleSumbit = () => {
        setLoading(true);
        let data = {
            name: name,
            is_bank_holiday_aware: is_bank_holiday_aware,
            days: [],
        }
        //create data structure
        for (let days in week) {
            //@ts-ignore
            if (week[days] == true) {
                //@ts-ignore
                data.days.push({ week_day: days, start_time: startTimesDict[days], end_time: endTimesDict[days] })
            }
        }
        data.name = name;
        data.is_bank_holiday_aware = is_bank_holiday_aware;
        webHelpers.PutAsync("/api/workflow/timeprofile", 'helios-api', data).then((data: any) => {
            if (data.response === 400 || data === undefined) {
                return (toast({
                    position: "bottom",
                    title: `Some server error creating this event subscriber`,
                    status: "error",
                    isClosable: true,
                }))
            }
            else {
                setOpen(false);
                setLoading(false);
                props.cb();
                return (toast({
                    position: "bottom",
                    title: `New Time Profile Successfully Created`,
                    status: "success",
                    isClosable: true,
                }))
            }
        }).catch((error) => {
            setLoading(false);
            return (
                toast({
                    position: "bottom",
                    title: `Could not create new Timeprofile [${error.status}]`,
                    status: "error",
                    isClosable: true,
                })
            )

        })
    }

    const changeMonStart = (timeValue: string) => { startTimesDict["Monday"] = timeValue; console.log("dict monday :", startTimesDict["Monday"]) }
    const changeMonEnd = (timeValue: string) => { endTimesDict["Monday"] = timeValue };
    const changeTueEnd = (timeValue: string) => { endTimesDict["Tuesday"] = timeValue };
    const changeWedEnd = (timeValue: string) => { endTimesDict["Wednesday"] = timeValue };
    const changeThuEnd = (timeValue: string) => { endTimesDict["Thursday"] = timeValue };
    const changeFriEnd = (timeValue: string) => { endTimesDict["Friday"] = timeValue };
    const changeSatEnd = (timeValue: string) => { endTimesDict["Saturday"] = timeValue };
    const changeSunEnd = (timeValue: string) => { endTimesDict["Sunday"] = timeValue };
    const changeTueStart = (timeValue: string) => { startTimesDict["Tuesday"] = timeValue };
    const changeWedStart = (timeValue: string) => { startTimesDict["Wednesday"] = timeValue };
    const changeThuStart = (timeValue: string) => { startTimesDict["Thursday"] = timeValue };
    const changeFriStart = (timeValue: string) => { startTimesDict["Friday"] = timeValue };
    const changeSatStart = (timeValue: string) => { startTimesDict["Saturday"] = timeValue };
    const changeSunStart = (timeValue: string) => { startTimesDict["Sunday"] = timeValue };

    useEffect(() => {
        if (steps !== undefined) {
            setCurrentStep(steps[value - 1])
        }
    }, [value])

    return (
        <>
            <Button marginLeft={"30px"} onClick={handleOpen} bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                <BiAddToQueue />
                Create New Profile
            </Button>
            <Modal
                isOpen={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                size={"lg"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Text>Create New Time Profile</Text>

                    </ModalHeader>
                    <ModalBody>


                        <Box >
                            <div className="tab-button-container">
                                <div className="button-container">
                                    {/* TIME PROFILE FORM GOES HERE*/}
                                    <FormControl className="time-profile-form">
                                        {/* ENTER NAME GOES HERE */}
                                        <Flex direction={"row"} justifyContent={"space-between"}>
                                            <Text id="name-input" variant="outlined" onChange={handleChangeName}>Enter Name of Time Profile</Text>
                                            <Input placeholder="Name" type="text" autoComplete="new-password" value={name} onChange={handleChangeName} w={"250px"}></Input>
                                        </Flex>
                                        <Flex direction={"row"} className="input-row" marginTop={"10px"}>
                                            <Text>Is Bank Holiday Aware?</Text>
                                            <Checkbox checked={is_bank_holiday_aware} name="bank-holiday" onChange={handleBankHolidayChange} marginLeft={"10px"} />
                                        </Flex>
                                        <Table variant={"simple"} width={"90%"} marginTop={"10px"}>
                                            <Thead>
                                                <Tr>
                                                    <Th>Days of the Week</Th>
                                                    <Th>Active?</Th>
                                                    <Th>Start Time</Th>
                                                    <Th>End Time</Th>
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                <Tr height={"50px"}>
                                                    <Td><Text height={"10px"}>Monday</Text></Td>
                                                    <Td> <Checkbox isChecked={week.Monday} name="Monday" onChange={() => handleChange("Monday", !week.Monday)} /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Monday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeMonStart} value={startTimes.Monday} disabled={!week.Monday} id="Monday-Start" /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Monday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeMonEnd} value={endTimes.Monday} disabled={!week.Monday} id="Monday-End" /></Td>
                                                </Tr>
                                                <Tr height={"50px"}>
                                                    <Td><Text height={"10px"}>Tuesday</Text></Td>
                                                    <Td> <Checkbox isChecked={week.Tuesday} name="Tuesday" onChange={() => handleChange("Tuesday", !week.Tuesday)} /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Tuesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeTueStart} value={startTimes.Tuesday} disabled={!week.Tuesday} id="Tuesday-Start" /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Tuesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeTueEnd} value={endTimes.Tuesday} disabled={!week.Tuesday} id="Tuesday-End" /></Td>
                                                </Tr>
                                                <Tr height={"50px"}>
                                                    <Td><Text height={"10px"}>Wednesday</Text></Td>
                                                    <Td> <Checkbox isChecked={week.Wednesday} name="Wednesday" onChange={() => handleChange("Wednesday", !week.Wednesday)} /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Wednesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeWedStart} value={startTimes.Wednesday} disabled={!week.Wednesday} id="Wednesday-Start" /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Wednesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeWedEnd} value={endTimes.Wednesday} disabled={!week.Wednesday} id="Wednesday-End" /></Td>
                                                </Tr>
                                                <Tr height={"50px"}>
                                                    <Td><Text height={"10px"}>Thursday</Text></Td>
                                                    <Td> <Checkbox isChecked={week.Thursday} name="Thursday" onChange={() => handleChange("Thursday", !week.Thursday)} /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Thursday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeThuStart} value={startTimes.Thursday} disabled={!week.Thursday} id="Thursday-Start" /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Thursday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeThuEnd} value={endTimes.Thursday} disabled={!week.Thursday} id="Thursday-End" /></Td>
                                                </Tr>
                                                <Tr height={"50px"}>
                                                    <Td><Text height={"10px"}>Friday</Text></Td>
                                                    <Td> <Checkbox isChecked={week.Friday} name="Friday" onChange={() => handleChange("Friday", !week.Friday)} /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Friday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeFriStart} value={startTimes.Friday} disabled={!week.Friday} id="Friday-Start" /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Friday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeFriEnd} value={endTimes.Friday} disabled={!week.Friday} id="Friday-End" /></Td>
                                                </Tr>
                                                <Tr height={"50px"}>
                                                    <Td><Text height={"10px"}>Saturday</Text></Td>
                                                    <Td> <Checkbox isChecked={week.Saturday} name="Saturday" onChange={() => handleChange("Saturday", !week.Saturday)} /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Saturday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSatStart} value={startTimes.Saturday} disabled={!week.Saturday} id="Saturday-Start" /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Saturday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSatEnd} value={endTimes.Saturday} disabled={!week.Saturday} id="Saturday-End" /></Td>
                                                </Tr>
                                                <Tr height={"50px"}>
                                                    <Td><Text height={"10px"}>Sunday</Text></Td>
                                                    <Td> <Checkbox isChecked={week.Sunday} name="Sunday" onChange={() => handleChange("Sunday", !week.Sunday)} /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Sunday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSunStart} value={startTimes.Sunday} disabled={!week.Sunday} id="Sunday-Start" /></Td>
                                                    <Td><TimePicker cellHeight={35} inputClassName={week.Sunday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSunEnd} value={endTimes.Sunday} disabled={!week.Sunday} id="Sunday-End" /></Td>
                                                </Tr>
                                            </Tbody>

                                        </Table>


                                    </FormControl>
                                </div>


                            </div>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button alignSelf={"center"}
                            bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={"30px"}
                            _hover={{ bg: "AbleYellow", textColor: "white" }}
                            _active={{ bg: "#ECB500" }}
                            leftIcon={!loading ? <BsSendFill /> : <MdLoop />}
                            onClick={handleSumbit}
                            isLoading={loading}
                        >
                            Create Profile
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
