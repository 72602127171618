import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast, Tooltip
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "../skills/addSkillRow";
import { AiFillPhone } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { TfiControlPlay } from "react-icons/tfi";
import { AiFillDelete } from "react-icons/ai"
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { BiChevronRight } from "react-icons/bi"
import { BiChevronsRight } from "react-icons/bi";
import { BsFillCheckSquareFill } from "react-icons/bs"
import SkillsView from "../skills/skillsView";
import CloneRole from "./cloneRole";
import { color } from "framer-motion";
import { useDictionary } from "../../scripts/useDictionary";
import { StringMappingType } from "typescript";
import EditOutbound from "./editOutbound";

interface NewRoleProps {
    userId: string,
    cb: Function,
}
interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}
export interface OutboundNumbers {
    id: string,
    name: string,
    phoneNumber: string,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}
interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}
interface RoleProps {
    userId: string,
    role: UserRole,
    handleDelete: Function,
    handleSetActive: Function,
    activeRoleId?: string,
    second_cb: Function,
    cb: Function,
}
export default function EditRoleMobile(props: RoleProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumbers[]>([]);
    const [selectedOutbound, setSelectedOutbound] = useState<string>();
    const [role, setRole] = useState<UserRole>(props.role);





    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const toast = useToast();

    // function addSkills(additionalSkills: SkillTest[]) {
    //     let test_object = {
    //         skill_id: "",
    //         level_id: "",
    //         name: "",
    //         level_name: "",
    //     }
    //     // @ts-ignore
    //     test_object.skill_id = Object.values(additionalSkills)[0];
    //     // @ts-ignore
    //     test_object.level_id = Object.values(additionalSkills)[1];
    //     // @ts-ignore
    //     test_object.name = Object.values(additionalSkills)[2];
    //     // @ts-ignore
    //     test_object.level_name = Object.values(additionalSkills)[3];
    //     skills[test_object.skill_id] = test_object;

    // }


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        populateSkills();
        getOutboundNumbers();
    }

    const handleClose = () => {
        onClose();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSelectedOutboundChange = (event: any) => {
        setSelectedOutbound(event.target.value);
    }
    function submitAddedSkills(additionalSkills: { [skill_id: string]: ISkillToAdd }) {
        Object.values(additionalSkills).forEach(skill => {
            onUpdateSkill(skill.skill_id, skill);
        });
        updateRole();
    }
    function handleClone(role_name: string) {
        let newRole: UserRoleUpdate = {
            id: role.id,
            name: role_name,
            worker_id: role.worker_id,
            allow_aux: role.allow_aux,
            auto_answer: role.auto_answer,
            skills: [],
            outbound_rule: role.outbound_rule,
            outbound_number_id: role.outbound_number_id
        };

        role.details.forEach(s => {
            newRole.skills.push({ skill_id: s.skill_id, level_id: s.level_id })
        });
        webHelpers.PutAsync('/api/worker/role', 'zeus-api', newRole).then((data: any) => {
            if (data.status === undefined) {
                props.cb();
                return toast({
                    position: "bottom",
                    title: `New role successfully created`,
                    status: "success",
                    isClosable: true,
                })

            }
            else {
                return toast({
                    position: "bottom",
                    title: `Unable to create new role`,
                    status: "error",
                    isClosable: true,
                })
            }
        })
    }
    function handleAuxChange(aux_value: boolean) {
        let newRole = role;
        newRole.allow_aux = !aux_value;
        setRole(newRole);
        updateRole();
    }
    function handleAutoAnswerChange(auto_answer: boolean) {
        let newRole = role;
        newRole.auto_answer = !auto_answer;
        setRole(newRole)
        updateRole();
    }

    function removeSkill(id: string) {
        onClearSkill(id)
        updateRole();
    }
    function handleSetActive(id: string) {

        webHelpers.PostAsync('/api/worker/role/' + id + '/set', 'zeus-api', {}).then((data: any) => {
            if (data.status === undefined) {
                props.second_cb();
            }
        }).catch((error) => {
            return toast({
                position: "bottom",
                title: `Error occurred while trying to change active role [${error.status}]`,
                status: "warning",
                isClosable: true,
            })
        }
        )
    }
    function updateRole() {
        let r: UserRoleUpdate = {
            ...role,
            skills: Object.values(skills)
        };
        webHelpers.PostAsync('/api/worker/role', 'zeus-api', r).then((data: any) => {
            setRole(data);
        });
    }

    useEffect(() => {
        onClearSkills();

        role.details.forEach(detail => {
            onUpdateSkill(detail.skill_id, { skill_id: detail.skill_id, level_id: detail.level_id });
        });
    }, [role]);
    useEffect(() => {
        console.log("active role id3 = ", props.activeRoleId)
        console.log("role id = ", role.id)
    })

    const handleSubmit = () => {
        if (text !== '' && selectedOutbound !== undefined) {
            let newSkills: ISkill[] = [];
            Object.values(skills).forEach(skill => {
                newSkills.push({ skill_id: skill.skill_id, level_id: skill.level_id });
            });

            let data = {
                'name': text,
                'outbound_number_id': selectedOutbound,
                'skills': newSkills,
                'worker_id': props.userId,
            }
            webHelpers.PutAsync('/api/worker/role', 'zeus-api', data).then((data: any) => {
                console.log("create role result ", data);
                if (data.status === 400) {
                    return toast({
                        position: "bottom",
                        title: `There was an error trying to create this role [${data.status}]`,
                        status: "error",
                        isClosable: true,
                    })
                }
                else {

                    //setCurrentPotentialSkills([])
                    props.cb();
                    return toast({
                        position: "bottom",
                        title: `${text} Created!`,
                        status: "success",
                        isClosable: true,
                    })
                }
            })
        }
        else {
            return toast({
                position: "bottom",
                title: `Please ensure you have added all relevant fields`,
                status: "warning",
                isClosable: true,
            })
        }
    }

    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }
    const getOutboundNumbers = () => {
        webHelpers.GetAsync('/api/hosted/outboundnumbers', 'zeus-api').then((data: any) => {
            if (data != undefined && data != null) {
                setOutboundNumbers(data)
            }
            else {
                console.log(`${data.status}: unable to fetch outbound numbers`)
            }
        })
    }
    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        if (open && allSkills === undefined) {
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} _hover={{ textColor: "AbleYellow" }} marginTop={"10px"}
                onClick={handleClickOpen} leftIcon={<AiOutlinePlus />}>
                Edit
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"4xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Editing " + role.name}</ModalHeader>
                    <ModalBody>
                        <Flex direction={"column"} alignItems={"center"}>

                            <Flex align="left" textColor={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "revert", lg: "none" }}>
                                {skills !== undefined &&
                                    <>

                                        {props.activeRoleId === role.id ?
                                            <SkillsView role={role} submitAddedSkills={submitAddedSkills} removeSkill={removeSkill} userId={props.userId} refreshRoles={props.cb} isSelected={true} />
                                            :
                                            <SkillsView role={role} submitAddedSkills={submitAddedSkills} removeSkill={removeSkill} userId={props.userId} refreshRoles={props.cb} isSelected={true} />
                                        }
                                    </>
                                }
                            </Flex>
                            <Flex align="left" textColor={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "revert", lg: "none" }} marginTop={"10px"}>
                                {skills !== undefined &&
                                    <>

                                        {props.activeRoleId === role.id ?
                                            <EditOutbound role={role} isSelected={true} userId={props.userId} />
                                            :
                                            <EditOutbound role={role} isSelected={true} userId={props.userId} />
                                        }
                                    </>
                                }
                            </Flex>
                            <Flex direction={"row"} display={{ base: "flex", lg: "none" }} marginTop={"10px"} >

                                <Text marginTop={"2px"}>
                                    <AiFillPhone size={"25px"} />
                                </Text>
                                <Switch
                                    size={"lg"}
                                    isChecked={role.auto_answer}
                                    onChange={() => handleAutoAnswerChange(role.auto_answer)}
                                    borderWidth={"2px"}
                                    borderColor={"white"}
                                    borderRadius={"2xl"}
                                />
                            </Flex>

                            <Flex display={{ base: "flex", lg: "none" }} marginBottom={"10px"} marginTop={"10px"}>
                                <Tooltip label='Allow Aux' placement="top">
                                    <Text marginTop={"4px"}>
                                        <BsFillSpeakerFill size={"25px"} />
                                    </Text>
                                </Tooltip>
                                <Switch
                                    size={"lg"}
                                    borderWidth={"2px"}
                                    borderColor={"white"}
                                    borderRadius={"2xl"}
                                    isChecked={role.allow_aux}
                                    onChange={() => handleAuxChange(role.allow_aux)}
                                />
                            </Flex>
                            <Flex align="right" textColor={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "revert", lg: "none" }}>
                                {/* <CloneRoleDialog cloneRoleFunction={handleClone} /> */}
                                {props.activeRoleId === role.id ?
                                    <CloneRole cloneRoleFunction={handleClone} isSelected={true} />
                                    :
                                    <CloneRole cloneRoleFunction={handleClone} isSelected={true} />
                                }
                            </Flex>
                            <Flex direction={"row"} textColor={"white"} display={{ base: "flex", lg: "none" }} marginTop={"10px"}>
                                <Button bg={"AbleBlue"} textColor={"white"} onClick={() => props.handleDelete(role.id)} leftIcon={<AiFillDelete />}
                                >
                                    <Text>
                                        Delete Role
                                    </Text>
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => { handleSubmit(); handleClose(); }}>Create</Button>
                        <Button onClick={handleClose} colorScheme="red">Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
