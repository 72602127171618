import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
    AlertDialogOverlay, AlertDialogCloseButton, useToast, useColorMode
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import { StringMappingType } from "typescript";
import { AiFillCaretLeft } from "react-icons/ai"
import { AiFillCaretRight } from "react-icons/ai";
import { floor } from 'mathjs'
import { useEnvironment } from "../../context/EnvironmentContext";
import { FaHandPointLeft } from "react-icons/fa";
import { AiFillPhone } from "react-icons/ai"

interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
export interface OutboundNumbers {
    id: string,
    name: string,
    phoneNumber: string,
}
interface RoleProps {
    userId: string,
    role: UserRole,
    activeRoleId?: string,
    isSelected: Boolean,
}
//const skills: { [skill_id: string]: SkillTest } = {};

export default function EditOutbound(props: RoleProps) {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [role, setRole] = useState(props.role);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [userSkills, setUserSkills] = useState<Detail[]>(props.role.details.sort());
    const [pageUserSkills, setPageUserSkills] = useState<Detail[]>(props.role.details.sort());
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumbers[]>([]);
    const [selectedOutbound, setSelectedOutbound] = useState<string>(props.role.outbound_number_id);

    const { colorMode, toggleColorMode } = useColorMode();



    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const webHelpers = useWebHelper();
    const EnvironmentContext = useEnvironment();
    const toast = useToast();

    const handleSelectedOutboundChange = (event: any) => {
        setSelectedOutbound(event.target.value);
    }

    let levels: Level[] = [];
    props.role.details.forEach(detail => {
        levels.push(detail.level)
    })
    function fetchSkills() {
        // For updating the skills chips on change
        webHelpers.GetAsync('/api/worker/roles/' + props.userId, 'zeus-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                return toast({
                    position: "bottom",
                    title: `Unable to fetch skills!`,
                    status: "error",
                    isClosable: true,
                });
            }
            else {
                data.forEach((role: UserRole) => {
                    if (role.id == props.role.id) {
                        Object.values(skills).forEach(skill => {
                            delete skills[skill.skill_id];
                        })
                        setUserSkills(role.details.sort());
                        role.details.forEach((detail: Detail) => {
                            skills[detail.skill_id] = {
                                skill_id: detail.skill_id,
                                level_id: detail.level_id,
                                level_name: detail.level.name,
                                name: detail.id,
                            };
                        });
                    }
                })
            }
        });
    }

    function updateRole() {
        let updatedRole: UserRoleUpdate = {
            ...props.role,
            skills: Object.values(skills),
        }
        console.log("new role to be posted : ", updatedRole)
        // Push up the new skill level
        webHelpers.PostAsync('/api/worker/role', 'zeus-api', updatedRole).then((data: any) => {
            if (data != undefined) {
                setRole(data);
                return toast({
                    position: "bottom",
                    title: `Role updated!`,
                    status: "success",
                    isClosable: true,
                });
            }
            else {
                return toast({
                    position: "bottom",
                    title: `${data.status}: Unable to apply changes to the role`,
                    status: "error",
                    isClosable: true,
                });
                //enqueueSnackbar(`${data.status}: Unable to apply changes to the role`, { 'variant': 'error' })
            }
        });

        // Update the client side role
        //props.refreshRoles();

    }

    const handleConfirm = (id: string) => {
        if (id !== '') {
            //props.removeSkill(skillToDelete);
            removeSkill(id);
            setConfirmOpen(false);

        }
    }
    const handleSubmit = () => {
        let newRole = role;
        newRole.outbound_number_id = selectedOutbound;
        setRole(newRole)
        console.log("role : ", newRole)
        updateRole();
    }
    const getOutboundNumbers = () => {
        webHelpers.GetAsync('/api/hosted/outboundnumbers', 'zeus-api').then((data: any) => {
            if (data != undefined && data != null) {
                setOutboundNumbers(data)
                fetchSkills();
            }
            else {
                console.log(`${data.status}: unable to fetch outbound numbers`)
            }
        })
    }
    function removeSkill(remove_skill_id: string) {
        for (let remove_skill in skills) {
            if (skills.hasOwnProperty(remove_skill_id)) {
                delete skills[remove_skill_id];
                console.log("updating role: ", skills);
                updateRole();
            }
        }

    }
    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
    }, [])

    // useEffect(() => {
    //     onClearSkills();
    // }, [open])

    return (
        <>

            <Button bg={props.isSelected ? "AbleBlue" : "backgroundDarkGrey"} textColor={colorMode === 'light' ? props.isSelected ? "white" : "AbleBlue" : props.isSelected ? "white" : "white"}
                _hover={props.isSelected ? { bg: "AbleBlue", textColor: "AbleYellow" } : { bg: "backgroundDarkGrey", textColor: "AbleYellow" }}
                onClick={() => {
                    onOpen();
                    setOpen(true);
                    setPage(0);
                    getOutboundNumbers();
                }} leftIcon={<AiFillPhone />}>
                Change
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "full", lg: "4xl" }}

            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Edit " + props.role.name + " Outbound Number"}</ModalHeader>
                    <ModalBody>
                        <Flex direction={"column"} width={"50%"} marginLeft={"30px"}>
                            <Text>Select the Outbound Phone Number</Text>
                            <Select value={selectedOutbound} onChange={handleSelectedOutboundChange} placeholder="Select an entry from the list below">
                                {outboundNumbers.map((each) => (
                                    <option value={each.id}>{each.name + " " + each.phoneNumber}</option>
                                ))}
                            </Select>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => { handleSubmit(); onClose(); }}>Finish</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
