import { useEffect, useState } from "react";
import { IUser } from "./AuthContext";
import { useLocation, useNavigate } from "react-router";

export function useAuthProvider() {
    const [user, setUser] = useState<IUser | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [signInState, setState] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    const signIn = async (userName: string, passWord: string, remember: boolean): Promise<boolean> => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user_name: userName,
                password: passWord,
                remember: remember
            })
        }


        return new Promise<boolean>((result, rej) => {
            fetch('/api/authenticate', requestOptions).then(async res => {
                if (res.status === 200) {
                    let data = await res.json();

                    if (data !== null && data.user_id !== null) {
                        setUser(data);
                        setState(true);
                        result(true);
                    } else {
                        result(false);
                        setState(false);
                    }
                } else {
                    result(false);
                    setState(false);
                    console.log("Sign in failed: " + (await res.json()).Message);
                }
            });
        });
    }

    const signOut = () => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }
        fetch('/api/signout', requestOptions).then(res => {
            if (!res.ok) {
                setUser(null);
            }
        }).catch(err => {
            console.warn("Failed to sign out session");
        })

        setState(false);

        navigate("/signin");
    }

    useEffect(() => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('/api/session', requestOptions).then(async res => {
            if (res.ok) {
                let data = await res.json();
                if (data && data.user_id) {
                    setUser(data);
                    setState(true);
                } else {
                    setUser(null);
                }
            } else {
                setUser(null);
            }

            setLoading(false);
        })
    }, []);

    return {user, loading, signInState, signIn, signOut};
}