import * as React from 'react';
import { ReactNode, createContext, useContext } from "react";
import { useAuthProvider } from "./AuthProvider";

export interface IUser {
    user_id: string,
    user_name: string,
}

interface AuthContextType {
    user: IUser | null,
    loading: boolean,
    signInState: boolean,
    signIn: (userName: string, passWord: string, remember: boolean) => Promise<boolean>;
    signOut: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({children}: {children: ReactNode}) {
    const auth = useAuthProvider();

    if (auth.loading) {
        return <h1>Loading ...</h1>
    }

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return React.useContext(AuthContext);
}