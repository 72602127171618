import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Tab, Tabs, TabList, TabPanel, TabPanels, Card, CardBody, CardHeader } from "@chakra-ui/react";
import { Table, Thead, useColorMode,  Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { color } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateWorkflow from "../../components/workflows/createWorkflow";
import EditWorkflow from "../../components/workflows/editWorkflow";
import { CodeEditor } from "../../components/code/codeEditor";
import StepsComponent from "../../components/workflows/stepsComponent";
import AddStep from "../../components/workflows/createStep";
import GoBack from "../../components/goBack";
import { ShowSteps } from "../../components/workflows/showSteps";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IWorkflows {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
}
export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {

    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
export const ViewWorkflow = () => {
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<IWorkflows[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [userTypes, setUserTypes] = useState<IUserTypes[]>([]);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [workflow, setWorkflow] = useState<IWorkflows>();
    const {colorMode, toggleColorMode} = useColorMode();

    const [timeprofiles, setTimeprofiles] = useState<Timeprofiles[]>([]);

    const [steps, setSteps] = useState<IWorkflowStep[]>();
    const { workflow_id, environment } = useParams();


    const { isOpen, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    // @ts-ignore
    const EnvironmentContext = useEnvironment();

    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {
            console.log("environment is null?")
        }
    }, [])
    useEffect(() => {
        if (environment !== undefined) {
            EnvironmentContext.setEnvironmentById(environment);
        }
    }, []);

    function getWorkflow() {
        console.log(EnvironmentContext.selectedEnvironment.id);
        if (environment !== undefined) {
            EnvironmentContext.setEnvironmentById(environment);
        }
        
        if (EnvironmentContext.selectedEnvironment.id !== undefined) {
            webHelpers.GetAsync('/api/workflow/' + workflow_id, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflow', { 'variant': 'error' })
                    // setRows(defaultWorkflow);
                }
                else {
                    setWorkflow(data);

                    getStepsDefinition(data);
                    getTimeprofiles();
                }
            }).catch((error) => {
                console.log("caught error", error);
            })
        }

    }
    function getStepsDefinition(Workflow: IWorkflows) {
        if (Workflow !== undefined && Workflow !== null) {
            webHelpers.GetAsync("/api/workflow/" + Workflow?.id + "/steps", "helios-api").then((data) => {
                if (data === undefined || data == null) {
                    console.log("Unable to fetch steps from API", {
                        variant: "error",
                    });
                } else {
                    setSteps(Object.values(data));
                }
            }
            );
        }
        else {
            console.log("workflow is null!")
        }
    }
    function getTimeprofiles() {
        //webHelpers.get('/api/workflow/timeprofiles/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, environment, 'helios-api', token, (data: any) => {
        webHelpers.GetAsync('/api/workflow/timeprofiles', 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                console.log("Could not fetch time profiles");
            }
            else {
                //@ts-ignore
                setTimeprofiles(Object.values(data));
                setLoading(false);
                //setMaxPages(data.maximum_pages)
            }
        })
    }

    useEffect(() => {
        setLoading(true);
        getWorkflow()

    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);

    const refresh = () => {
        setIgnore(true);
        getWorkflow();
        setLoading(true);

    }
    return (

        <>
            {(workflow !== null && workflow !== undefined) &&
                <ScaleFade initialScale={1} in={!loading}>

                    <Heading color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"}
                    >{"Managing Workflow: " + String(workflow.name.slice(0)[0].toUpperCase()) + String(workflow.name.slice(1))} </Heading>
                    <Flex direction={'row'} h='calc(100vh - 250px)' justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                        {/* table to show list of users */}
                        {!loading ?
                            <Flex justifyContent={"space-around"} marginTop={"50px"}>
                                <Tabs marginTop={"-40px"} variant={"soft-rounded"} align="center">
                                    <TabList>
                                        <Tab _hover={{ bg: "var(--Able-yellow" }}>Details</Tab>
                                        <Tab _hover={{ bg: "var(--Able-yellow" }}>Steps</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <Card height={"-webkit-fit-content"} bg={"backgroundLightGrey"}>
                                                <CardHeader>
                                                    <Text as={"b"} color={colorMode === 'light' ? "AbleBlue" : "white"}>Workflow Details</Text>
                                                </CardHeader>
                                                <CardBody>
                                                    <Text>ID: {workflow?.id}</Text>
                                                    <Text>Event: {workflow?.event_id}</Text>
                                                    <Text marginBottom={"10px"}>Description: {workflow?.description}</Text>
                                                    <CodeEditor stepFriendlyName={workflow.name} code_id={workflow?.initializer_code_id} buttonText="Edit Initialiser Code" mode="" cb={undefined} intital_value={undefined} quick_outcome_code_id={null} />
                                                </CardBody>
                                            </Card>
                                        </TabPanel>
                                        <TabPanel>
                                            <ShowSteps />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>

                            </Flex>
                            :
                            <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"5%"} bgColor={"white"}>
                                <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                            </Flex>

                        }

                        <ScaleFade initialScale={0.1} in={defaultView}>
                        </ScaleFade>

                    </Flex>
                    <Flex marginBottom={"10px"} justifyContent={"center"} marginTop={"0px"} position={"absolute"} top={248} left={"30%"}>
                        <GoBack goBackTo="/workflows" />
                    </Flex>
                </ScaleFade>
            }

        </>
    )
}
