import * as React from 'react';
import { useState } from 'react';


import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, background, useToast,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";


// Custom Components
// import RoleManagement from '../modal-role-management/roles-table.component';
// import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
// import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useWebHelper } from "../../hooks/UseWebHelper";

import { validateLocaleAndSetLanguage } from "typescript";
import { BiAddToQueue } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';



interface IEditNameserver {
    id: string,
    name: string,
    email: string,
    cb: Function,
}
export interface IAssembly {
    assembly_name: string;
    is_default: boolean;
}
const defaultWorkflow: IAssembly[] = [
    {
        assembly_name: "Test.Package",
        is_default: true,
    }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};
interface AssemblyModalProps {
    cb: Function,
}
export default function AssembliesModal(props: AssemblyModalProps) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    // const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [rows2, setRows2] = useState<IAssembly[]>(defaultWorkflow);
    const [newNamespace, setNewNamespace] = useState<string>("New Assembly");
    const webHelpers = useWebHelper();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)


    // Grabs data to fill out seperate tabs
    const handleOpen = () => {
        webHelpers.GetAsync('/api/code/config/assemblies', 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                console.log('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                //@ts-ignore
                setRows2(Object.values(data));
                setOpen(true);
                //setMaxPages(data.maximum_pages)
            }
        })

        // webHelpers.get('/api/worker/roles/' + props.id, environment, 'zeus-api', token, (data: any) => {
        //     if (data === undefined || data === null || data.status !== undefined) {
        //         console.log('bad fetch call');

        //         enqueueSnackbar(`${data.status}: Unable to get information for this user`, {'variant': 'error'})
        //         // Some fallback ui needed here.
        //     }
        //     else {
        //         setRoles(data);
        //         setOpen(true);
        //     }
        // });
    };
    function handleClose() {
        setOpen(false);
    }
    const handleProfileChange = (event: any) => {
        setNewNamespace(event.target.value);
    }
    const handleSubmit = () => {
        props.cb(newNamespace);
        setOpen(false);
    }
    return (
        <>
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                className='button-test' onClick={handleOpen} leftIcon={<BiAddToQueue />}>Add new Assembly</Button>
            <Modal
                isOpen={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <div className="modal-header">
                            <Text >
                                Add New Assembly
                            </Text>
                            {/* Add an edit department bit here at some point */}


                        </div>

                        <Text as={"i"} fontSize={"sm"}>
                            Select a new assembly from the drop down box below
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <Text id="demo-simple-select-label">Assembly</Text>
                        <Select

                            id="demo-simple-select"
                            value={newNamespace}
                            onChange={handleProfileChange}
                            placeholder='New Assembly'

                        >
                            {rows2.map((workflow: IAssembly) => (
                                (workflow.is_default ?
                                    <p></p>
                                    :
                                    <option value={workflow.assembly_name}>{workflow.assembly_name}</option>
                                )))}
                        </Select>
                        <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                            className="modal-button-submit" onClick={handleSubmit} variant="contained" marginTop={"10px"} isDisabled={newNamespace === "New Assembly"}>Add to Code</Button>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}