import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Toast } from "@chakra-ui/react";
import { Table, useColorMode, Spinner, useToast, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { color } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreatePermission from "../../components/permissions/createPermission";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import TableControlsPermissions from "../../components/table/tableControlsPermissions";
import Refresh from "../../components/refresh";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IPermission {
    id: string,
    name: string,
}

export const PermissionsPage = () => {
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState<IPermission[]>([]);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(15);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const { isOpen, onToggle } = useDisclosure();
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [nextPage, setNextPage] = useState<IPermission[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const {colorMode, toggleColorMode} = useColorMode();




    const webHelpers = useWebHelper();

    const EnvironmentContext = useEnvironment();
    function goToNextPage(pageToLoad: number) {
        webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (pageToLoad + 1) + '/' + search, "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.permissions)
                data.permissions.forEach((element: IPermission) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                setMaxPages(data.max_pages)
                setLoading(false);
            }
        });
    }
    function searchPermissions(search: string) {
        setLoading(true)
        setNextPageNo(page);
        webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1) + '/' + search, 'auth').then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                // @ts-ignore 
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
                setLoading(false);
            }
            else {
                console.log(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
                return toast({
                    position: "bottom",
                    title: `Unable to find permissions under name ${search} [${data.status}]`,
                    status: "warning",
                    isClosable: true,
                });
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            console.log("no permissions match search criteria \"" + search + "\"");
            return toast({
                position: "bottom",
                title: `no permissions match search criteria ${search}`,
                status: "warning",
                isClosable: true,
            });
        });
    }
    useEffect(() => {
        searchPermissions(search)
    }, [page, rowsPerPage]);


    // function updatePermissions() {
    //     webHelpers.GetAsync('/api/management/user/' + props.user_id + '/permissions', 'auth').then((data: any) => {
    //         if (data === undefined || data === null || data.status !== undefined) {
    //             console.log("no permissions found for ", props.user_name);
    //         }
    //         else {
    //             if (Math.floor(data.length / rowsPerPage) === 0) {
    //                 setMaxPagesFirstTab(Math.floor(data.length / rowsPerPage));
    //                 // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
    //             }
    //             else {
    //                 setMaxPagesFirstTab(Math.floor(data.length / rowsPerPage));
    //                 // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
    //             }
    //             // setUserPermissions(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    //             setUserPermissions(data);
    //         }

    //     })
    // }

    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        // setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        // setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    }, [page]);

    useEffect(() => {
        setNextPageNo(1);
    }, [page]);
    useEffect(() => {
        console.log("now loading page ", nextPageNo + 2, " of ", maxPages);
    }, [page, nextPageNo])

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        searchPermissions(event.target.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true)
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setRows([])
        searchPermissions(search);
    }, [EnvironmentContext.selectedEnvironment])
    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.target.value);
    }


    return (
        <ScaleFade initialScale={1} in={isPageOpen}>
            <Heading color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"}
            >Permission Management</Heading>
            <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>

                <div>

                    <ScaleFade initialScale={1} in={true}>
                        <Flex direction={"row"} justifyContent={"center"}>
                            <TableControlsPermissions handleChangeRowsPerPage={handleChangeRowsPerPage} handleSearchChange={handleSearchChange} search={search} rowsPerPage={rowsPerPage}/>

                            <Select onChange={handleEnvChange} value={EnvironmentContext.selectedEnvironment.id} width={"175px"} marginRight={"20px"} defaultValue={"live"} display={{ base: "none", lg: "revert" }}>
                                {EnvironmentContext.environments.map((workflow: IEnvironment) => (
                                    <option value={workflow.id}>{workflow.name}</option>
                                ))}
                            </Select>

                            <Input placeholder="Search Bar" onChange={handleSearchChange} value={search} width={"300px"} display={{ base: "none", lg: "revert" }}></Input>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Rows per Page:</Text>
                            <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"} display={{ base: "none", lg: "revert" }}>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </Select>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Page {page + 1} of {maxPages} </Text>
                            <Button display={{ base: "none", lg: "revert" }} bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== 0 && setPage(page - 1);
                                setLoading(true);
                            }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                            <Button display={{ base: "none", lg: "revert" }} size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== maxPages - 1 && setPage(page + 1);
                                setLoading(true);
                            }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                            <Refresh cb={() => searchPermissions(search)} />
                            <CreatePermission cb={() => searchPermissions(search)} />
                        </Flex>
                        {/* need to have a section or method of showing already selected skills */}
                        {!loading ?
                            <>
                                {rows.length > 0 ?
                                    <ScaleFade initialScale={1} in={!loading}>
                                        <Flex h={750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"}>
                                            <TableContainer>
                                                <Table variant={"simple"} marginLeft={"50px"}>
                                                    <TableCaption>
                                                        <Text>
                                                            {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                                        </Text>
                                                    </TableCaption>
                                                    {rows.length === 0 ?
                                                        <Text textAlign={"center"}>No Permissions Found! </Text>
                                                        :
                                                        <>

                                                            <InfiniteScroll

                                                                dataLength={rows.length}
                                                                next={() => {

                                                                    if (nextPageNo < page) {
                                                                        setNextPageNo(page + 1);
                                                                        goToNextPage(page + 1)
                                                                    }
                                                                    else {
                                                                        goToNextPage(nextPageNo + 1)
                                                                        setNextPageNo(nextPageNo + 1);
                                                                    }
                                                                }}
                                                                hasMore={nextPageNo <= maxPages - 2}
                                                                loader={
                                                                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                                                        <Spinner color="AbleBlue" marginTop={"-10px"} marginBottom={"100px"} size={"xl"}></Spinner>
                                                                        {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                                                    </Flex>
                                                                }
                                                                endMessage={
                                                                    <p style={{ textAlign: 'center' }}>
                                                                        <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                                                    </p>
                                                                }
                                                                scrollThreshold={1}
                                                            >
                                                                <Thead>
                                                                    <Tr>
                                                                        {/* this will show all skill that are possible to see */}
                                                                        <Th textAlign={"left"}>Permission Name</Th>
                                                                        <Th display={{ base: "none", lg: "revert" }}>Description</Th>
                                                                        <Th></Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {rows.map((permission) =>
                                                                        <Tr>
                                                                            <Td display={{ base: "none", lg: "revert" }} >{permission.id}</Td>
                                                                            <Td display={{ base: "none", lg: "revert" }} textOverflow={"ellipsis"}>{permission.name}</Td>
                                                                            <Td display={{ base: "revert", lg: "none" }} >{permission.id} <br/> 
                                                                                <Text as={"i"} fontSize={"md"}> {permission.name}</Text>
                                                                            </Td>
                                                                        </Tr>
                                                                    )}
                                                                </Tbody>
                                                            </InfiniteScroll>

                                                        </>
                                                    }
                                                </Table>
                                            </TableContainer>
                                        </Flex>
                                    </ScaleFade>


                                    :
                                    <Flex>
                                        <Text>No Permissions Found !</Text>
                                    </Flex>
                                }

                            </>

                            :

                            <Flex width={"100%"} justifyContent={"center"}>
                               {colorMode === 'light' ? 
                                    <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                                    : 
                                    <Spinner color="AbleYellow" marginTop={"100px"} marginBottom={"100px"} size={"xl"}/>
                                        }
                            </Flex>
                        }
                    </ScaleFade>
                </div>




            </Flex>
        </ScaleFade>
    )
}
