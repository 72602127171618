import { useState, ReactNode, createContext, useContext, useEffect } from "react";
import { useWebHelper } from "../hooks/UseWebHelper";

export interface IEnvironment {
    id: string,
    name: string
}

interface IEnvironmentContext {
    environments: IEnvironment[];
    selectedEnvironment: IEnvironment;
    setEnvironment(env: IEnvironment): void;
    setEnvironmentById(id: string): boolean;
}

const EnvironmentContext = createContext<IEnvironmentContext | undefined>(undefined);

function useEnvironmentProvider() {
    // let defaultEnv = {id: "live", name: "Live System"};
    let defaultEnv = {id: "live", name: "Live System"};
    const [environments, setEnvironments] = useState<IEnvironment[]>([defaultEnv]);
    const [selectedEnvironment, setSelectedEnvironment] = useState<IEnvironment>(defaultEnv);

    function setEnvironment(env: IEnvironment) {
        setSelectedEnvironment(env);
    }

    function setEnvironmentById(id: string): boolean {
        let chosenEnv: IEnvironment | undefined = environments.find(x => x.id == id);
        if (chosenEnv !== undefined) {
            setSelectedEnvironment(chosenEnv);
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        let requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('/api/environments', requestOptions).then(async res => {
            if (res.status === 200) {
                let data: IEnvironment[] = await res.json();

                if (data !== null) {
                    setEnvironments(data);
                }
            }
        });
    }, []);

    return {environments, selectedEnvironment, setEnvironment, setEnvironmentById};
}

export function EnvironmentProvider({children}: {children: ReactNode}) {
    const value = useEnvironmentProvider();

    return (
        <EnvironmentContext.Provider value={value}>
            {children}
        </EnvironmentContext.Provider>
    )
}

export function useEnvironment() {
    const context = useContext(EnvironmentContext);
    if (context === undefined) {
        throw new Error("useEnvironment must be used within an Environment Provider");
    }

    return context;
}