import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Tooltip, Toast, useToast, Spinner
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillPhone, AiFillCaretLeft, AiFillCaretRight, AiOutlineMinus } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import RoleRow from "../role/roleRow";
import NewRole from "../role/newRole";
import { StringMappingType } from "typescript";
import SkillsView from "../skills/skillsView";
import GetUserConfirm from "../confirm";
import { color } from "framer-motion";
import { title } from "process";
import TableControlsPermissions from "../table/tableControlsPermissions";



export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}
interface IGroups {
    id: string,
    is_security: boolean,
    name: string,
}

export default function GroupManagement(props: RoleManagementProps) {
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState<UserRole[]>(props.roles);
    const [rows, setRows] = useState<IGroups[]>();
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [userPermissions, setUserPermissions] = useState<any[]>([]);
    const [userPermissionsFiltered, setUserPermissionsFiltered] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userGroups, setUserGroups] = useState<IGroups[]>([]);
    const toast = useToast();
    const { isOpen, onToggle } = useDisclosure();




    const webHelpers = useWebHelper();

    function searchGroups(search: string) {
        webHelpers.GetAsync('/api/management/groups/' + rowsPerPage + '/' + (page + 1) + '/' + search, 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No groups found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                setRows(data.groups);
                setMaxPages(data.max_pages);
                setLoading(false);
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            console.log("no groups match search criteria \"" + search + "\"");
        });
    }
    function searchUserGroups() {
        webHelpers.GetAsync('/api/management/user/' + props.user_id + '/groups', "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log("could not find any groups associated with user ", props.user_name);
            }
            else {
                if (Math.floor(data.length / rowsPerPage) === 0) {
                    setMaxPagesFirstTab(Math.floor(data.length / rowsPerPage) + 1);
                }
                else {
                    setMaxPagesFirstTab(Math.floor(data.length / rowsPerPage) + 1);
                }
                setUserGroups(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))));
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error fetching user groups")
        })
    }
    useEffect(() => {
        webHelpers.GetAsync('/api/management/groups/' + rowsPerPage + '/' + (page + 1), 'auth',).then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                setRows(data.groups);
                setMaxPages(data.max_pages);
            }
            else {
                console.log(`${data.status}: Unable to find groups under name`, { 'variant': 'error' })
            }
        })
        searchUserGroups();
    }, [page, rowsPerPage]);

    function handleDelete(group_id: string) {
        webHelpers.PostAsync('/api/management/group/' + group_id + '/revoke/' + props.user_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                console.log('Group successfully revoked', { 'variant': 'success' });
            }
            else {
                console.log(`${data.status}: Unable to revoke this Group.`, { 'variant': 'error' })
            }
        })
        searchUserGroups();
        setLoading(false);
        return (
            toast({
                position: "bottom",
                title: `group ${group_id} has been removed from user ${props.user_name}`,
                status: "success",
                isClosable: true,
            })
        )
    }
    function handleAssign(id: string) {
        webHelpers.PostAsync('/api/management/group/' + id + '/assign/' + props.user_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully assigned', { 'variant': 'success' });
                setLoading(false);
            }
            else {
                console.log(`${data.status}: Unable to assign this permission.`, { 'variant': 'error' });
            }
        })
        setLoading(false);
        searchUserGroups();
        return (
            toast({
                position: "bottom",
                title: `user ${props.user_name} has been added to group ${id}`,
                status: "success",
                isClosable: true,
            })
        )

    };

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        searchGroups(event.target.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            {rows !== null && rows !== undefined ?

                <div>
                    <Tabs position="relative" variant="enclosed-colored" size={"lg"} onChange={() => {
                        // populateSkills();
                        setPage(0);
                        onToggle();
                    }
                    }>
                        <TabList >
                            <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Active Groups</Tab>
                            <Tab bg={"AbleBlue"} textColor={"white"} _selected={{ textColor: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Add Groups</Tab>
                        </TabList>
                        <TabIndicator
                            mt="-1.5px"
                            height="2px"
                            bg="AbleYellow"
                            borderRadius="1px"
                        />
                        <TabPanels>
                            <TabPanel>
                                <ScaleFade initialScale={1} in={!isOpen}>
                                    <Flex direction={"row"} justifyContent={"right"}>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                                        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                            <option value={5} >5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                        </Select>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPageFirstTab} </Text>
                                        <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== 0 && setPage(page - 1);
                                            setLoading(true);
                                        }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== maxPageFirstTab - 1 && setPage(page + 1);
                                            setLoading(true);
                                        }} isDisabled={page === maxPageFirstTab - 1}><AiFillCaretRight /></Button>
                                    </Flex>
                                    {!loading ?
                                        <TableContainer>
                                            <Table variant={"simple"} w={"100%"}>
                                                {userGroups.length === 0 ?
                                                    <Text textAlign={"center"}>No Permissions Found! </Text>
                                                    :
                                                    <>
                                                        <Thead>
                                                            <Tr>
                                                                {/* this will show all skill that are possible to see */}
                                                                <Th textAlign={"left"}>Group Name</Th>
                                                                <Th display={{ base: "none", lg: "revert" }}>Security</Th>
                                                                <Th display={{ base: "none", lg: "revert" }}></Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Accordion allowToggle allowMultiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-21px"}>
                                                                {userGroups.map(permission => (
                                                                    <Flex justifyContent={"space-between"} w={"100%"}>
                                                                        <Td display={{ base: "revert", lg: "none" }} marginTop={{ base: "10px", lg: "0px" }}>
                                                                            {permission.name}
                                                                        </Td>

                                                                        <Td display={{ base: "revert", lg: "none" }} >
                                                                            <Button
                                                                                onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                                bg={"AbleBlue"}
                                                                                textColor={"white"}
                                                                                marginLeft={{ base: "-20px", lg: "30px" }}
                                                                                _hover={{ textColor: "AbleYellow" }}
                                                                                isLoading={loading}>Delete</Button></Td>
                                                                    </Flex>
                                                                ))}
                                                            </Accordion>
                                                            {userGroups.map((group) =>
                                                                <Tr display={{ base: "none", lg: "revert" }}>
                                                                    <Td>{group.name}</Td>
                                                                    <Td>{group.is_security ? "Yes" : "No"}</Td>
                                                                    <Td>
                                                                        <GetUserConfirm
                                                                            confirmFunction={() => handleDelete(group.id)}
                                                                            declineFunction={() => console.log("")}
                                                                            buttonText="Remove"
                                                                            buttonWarning={"Are you sure you want to remove this group from " + props.user_name} />
                                                                        {/* <Button leftIcon={<AiOutlineMinus/>} onClick={() => handleDelete(permission.id)}>Remove</Button> */}
                                                                    </Td>
                                                                </Tr>
                                                            )}
                                                        </Tbody>
                                                    </>
                                                }
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Flex width={"100%"} justifyContent={"center"}>
                                            <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"250px"} />
                                        </Flex>
                                    }
                                </ScaleFade>
                            </TabPanel>
                            <TabPanel>
                                <ScaleFade initialScale={1} in={isOpen}>
                                    <Flex direction={"row"} justifyContent={"right"}>
                                        <TableControlsPermissions search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
                                        <Input placeholder="Search Bar" onChange={handleSearchChange} value={search} width={"300px"} display={{ base: "none", lg: "revert" }}></Input>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Rows per Page:</Text>
                                        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"} display={{ base: "none", lg: "revert" }}>
                                            <option value={5} >5</option>
                                            <option value={10}>10</option>
                                            <option value={15}>15</option>
                                        </Select>
                                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                                        <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== 0 && setPage(page - 1);
                                            setLoading(true);
                                        }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                        <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                            page !== maxPages - 1 && setPage(page + 1)
                                            setLoading(true);
                                        }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                                    </Flex>
                                    {/* need to have a section or method of showing already selected skills */}
                                    {!loading ?
                                        <TableContainer>
                                            <Table variant={"simple"} w={"100%"} marginLeft={"-20px"}>
                                                {rows.length === 0 ?
                                                    <Text textAlign={"center"}>No Permissions Found! </Text>
                                                    :
                                                    <>
                                                        <Thead>
                                                            <Tr>
                                                                {/* this will show all skill that are possible to see */}
                                                                <Th textAlign={"left"}>Group Name</Th>
                                                                <Th display={{ base: "none", lg: "revert" }}>Security</Th>
                                                                <Th display={{ base: "none", lg: "revert" }}></Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Accordion allowToggle allowMultiple={false} display={{ base: "revert", lg: "none" }}>
                                                                {rows.map(permission => (
                                                                    <AccordionItem>
                                                                        <AccordionButton w={"100%"} justifyContent={"space-between"}>
                                                                            <Flex justifyContent={"space-between"} w={"100%"}>
                                                                                <Td>{permission.name}</Td>
                                                                                <AccordionIcon />
                                                                            </Flex>
                                                                        </AccordionButton>
                                                                        <AccordionPanel>
                                                                            <Td display={{ base: "revert", lg: "none" }} marginLeft={"30px"} >
                                                                                <Button
                                                                                    onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                                    bg={"AbleBlue"}
                                                                                    textColor={"white"}
                                                                                    display={{ base: "revert", lg: "none" }}
                                                                                    marginLeft={{ base: "0px", lg: "0px" }}
                                                                                    _hover={{ textColor: "AbleYellow" }}
                                                                                    isLoading={loading}>+ Assign Permission</Button>
                                                                            </Td>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                ))}
                                                            </Accordion>
                                                            {rows.map((permission) =>
                                                                <Tr display={{ base: "none", lg: "revert" }}>
                                                                    <Td>{permission.name}</Td>
                                                                    <Td display={{ base: "none", lg: "revert" }} textOverflow={"ellipsis"}>{permission.is_security ? "Yes" : "No"}</Td>
                                                                    <Td >

                                                                        <Button
                                                                            onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                            bg={"AbleBlue"}
                                                                            textColor={"white"}
                                                                            marginLeft={{ base: "-45px", lg: "0px" }}
                                                                            display={{ base: "none", lg: "revert" }}
                                                                            _hover={{ textColor: "AbleYellow" }}
                                                                            isLoading={loading}>+ Assign Permission</Button>
                                                                    </Td>
                                                                </Tr>
                                                            )}
                                                        </Tbody>
                                                    </>
                                                }
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Flex width={"100%"} justifyContent={"center"}>
                                            <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                                        </Flex>
                                    }
                                </ScaleFade>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>

                :
                <Flex>
                    <Text textAlign={"center"}>No Permissions Found !</Text>
                </Flex>
            }
        </>
    );
}
