import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, useToast, useColorMode
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import StepsComponent from "./stepsComponent";
import { BsCodeSlash } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight, AiFillDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { CodeEditor } from "../code/codeEditor";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
interface IWorkflow {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
    cb: Function
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
interface StepsProps {
    timeprofiles: Timeprofiles[],
    step: IWorkflowStep,
    workflow_id: string,
    cb: Function,
    refreshEventsFunction: Function,
}
interface Event {
    name: string,
    id: string,
}
export default function AddEvent(props: StepsProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [events, setEvents] = useState<Event[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [selectedEvent, setSelectedEvent] = useState<string>("");
    const [goToStage2, setGoToStage2] = useState<boolean>(false);
    const [code, setCode] = useState<string>("");
    const {colorMode, toggleColorMode} = useColorMode();

    const webHelpers = useWebHelper();
    const toast = useToast();

    const handleClickOpen = () => {
        onOpen();
        searchEvents();
        setName("");
        setDescription("");
        setGoToStage2(false);
        setSelectedEvent("");
    }
    const handleClose = () => {
        onClose();
        props.refreshEventsFunction();
    }

    function searchEvents() {
        if (search !== '') {
            webHelpers.GetAsync('/api/events?page=1&filter=' + search, 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
                    
                    
                }
                else {
                    if (data.length / rowsPerPage < 1) {

                        setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);

                    }
                    else {

                        setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);
                    }
                    setLoading(false);
                    setEvents(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

                }
            })
        }
        else {

            webHelpers.GetAsync('/api/events/?page=1', 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    return toast({
                        position: "bottom",
                        title: `No permissions match your search criteria`,
                        status: "warning",
                        isClosable: true,
                    }); 
                }
                else {
                    if (data.length / rowsPerPage < 1) {

                        setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);

                    }
                    else {

                        setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);
                    }
                    setLoading(false);
                    setEvents(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))


                }
            })
        }
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value);
    }
    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        searchEvents();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleEventClick = (id: string) => {
        setSelectedEvent(id);
    }
    useEffect(() => {
        searchEvents();
    }, [page, rowsPerPage])

    useEffect(() => {
        if (selectedEvent === "") {
            setGoToStage2(false);
        }
        else {
            setGoToStage2(true);
        }
    });
    const handleSubmit = () => {
        if (code === '' || selectedEvent === '') {
            return toast({
                position: "bottom",
                title: `Please select an event to subscribe to and enter some code before writing to the database`,
                status: "warning",
                isClosable: true,
            })
        }
        else {
            let payload = {
                code: code
            }
            webHelpers.PutAsync('/api/workflow/step/' + props.step.id + '/event?event=' + selectedEvent, 'helios-api', payload).then((data: any) => {
                if (data.response === 400 || data === undefined) {
                    console.log('Some server error creating this event subscriber', { 'variant': 'error' });
                }
                else {
                    handleClose();
                    props.cb();
                    return toast({
                        position: "bottom",
                        title: `Event subscriber successfully created!`,
                        status: "success",
                        isClosable: true,
                    }); 
                }
            }).catch((error) => {
                handleClose()
                return (
                    toast({
                        position: "bottom",
                        title: `That Event already exists and can't be added!`,
                        status: "error",
                        isClosable: true,
                    })
                )
            })
        }
    }


    return (
        <>
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                onClick={handleClickOpen} leftIcon={<BiAddToQueue />}
            >
                Add Event
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"4xl"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Creating Event for: " + props.step.name}<br />
                        <Text as={"i"} marginBottom={"1px"} fontSize={"sm"}>Please select which event you would like to add to the Workflow</Text></ModalHeader>
                    <ModalBody>
                        <>


                            <Flex direction={"row"}>
                                <Input placeholder="Search Bar" value={search} onChange={handleSearchChange} width={"230px"}></Input>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                                <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                </Select>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                                <Button bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== 0 && setPage(page - 1);
                                    setLoading(true);
                                }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                                <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== maxPages - 1 && setPage(page + 1)
                                    setLoading(true);
                                }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                            </Flex>
                            {events.length === 0 ?
                                <Text marginTop={"150px"} fontSize={"xl"}>No Workflows Found!</Text>
                                :

                                <TableContainer>
                                    {!loading ?
                                        <Table variant={"simple"}>
                                            <Thead>
                                                <Tr>
                                                    <Th>Name</Th>
                                                    <Th>ID</Th>
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                {events.map((each) =>
                                                    <Tr _hover={{ bg: colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey" }} onClick={() => handleEventClick(each.id)} borderColor={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} borderWidth={each.id === selectedEvent ? "3px" : "0px"}
                                                        bg={each.id === selectedEvent ? (colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey") : "backgroundDarkGrey"} pointerEvents={"visibleStroke"}>
                                                        <Td>{(each.name)}</Td>
                                                        <Td>{each.id}</Td>
                                                    </Tr>
                                                )}

                                            </Tbody>

                                        </Table>
                                        :
                                        <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                            <Spinner color="AbleBlue" />
                                        </Flex>

                                    }
                                </TableContainer>
                            }
                        </>
                        <Collapse in={goToStage2} animateOpacity>
                            <Text display={code === "" ? "flex" : "none"}>You still need to write some code before you can add this event!</Text>
                            <CodeEditor stepFriendlyName={props.step.name} code_id="" buttonText="Edit Code" mode="create-event" cb={setCode} intital_value={code} quick_outcome_code_id={null}/>

                        </Collapse>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent={"space-between"}>
                            <Button bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                                onClick={handleSubmit} isDisabled={selectedEvent === ""}>Add Event</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
