import { Flex, Heading, Text } from "@chakra-ui/react";
import { error } from "console";
import { ReactNode, Component, ErrorInfo } from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error | undefined;
    errorInfo: ErrorInfo | undefined;
}

export class DefaultErrorBoundary extends Component<Props, State> {
    state: State = {
        hasError: false,
        error: undefined,
        errorInfo: undefined
    };

    static getDerivedStateFromError(_: Error): State {
        return { 
            hasError: true,
            error: undefined,
            errorInfo: undefined
        };
    }

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
        console.error("An uncaught error was caught by the error boundary", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Oops! Something has gone wrong.</h1>
                    <h2>If you're seeing this page you really weren't meant to.</h2>
                    <h2>Please contact IT if this is a continuing issue.</h2>
                </div>
            )
        }

        return this.props.children;
    }
}

export class DashboardErrorBoundary extends Component<Props, State> {
    state: State = {
        hasError: false,
        error: undefined,
        errorInfo: undefined
    };

    static getDerivedStateFromError(_: Error): State {
        return { 
            hasError: true,
            error: undefined,
            errorInfo: undefined
        };
    }

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
        console.error("An uncaught error was caught by the dashboard error boundary", error, errorInfo);
        return {
            hasError: true,
            error: error,
            errorInfo: errorInfo
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Flex align='center'>
                    <Heading size='md'>Oops! Something has gone wrong.</Heading>
                    <Text>If the issue persists, please contact IT.</Text>

                    <Text>Error Messages:</Text>
                    <Text>{this.state.error?.name}</Text>
                    <Text>{this.state.error?.message}</Text>
                    <Text>{this.state.error?.stack}</Text>
                    <Text>{this.state.error?.toString()}</Text>
                    <Text>{this.state.errorInfo?.componentStack}</Text>
                </Flex>

                // <div>
                //     <h1>Oops! Something has gone wrong.</h1>
                //     <h2>If you're seeing this page you really weren't meant to.</h2>
                //     <h2>Please contact IT if this is a continuing issue.</h2>
                // </div>
            )
        }

        return this.props.children;
    }
}
