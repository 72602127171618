import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, useToast, 
    InputRightAddon, useClipboard,
    Editable,
    EditablePreview,
    EditableInput,
    Tooltip,
    InputRightElement
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { re } from "mathjs";
import { HiOutlineClipboardCopy } from "react-icons/hi";

interface newUserProps {
    cb: Function,
}
interface IKeyResponse {
    expires: string,
    token: string,
}

export default function NewKey(props: newUserProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const [token, setToken] = useState<string>("");
    const [expires, setExpires] = useState<string>("");
    const [showFirstPage, setShowFirstPage] = useState<boolean>(true);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const { onCopy, value, setValue, hasCopied } = useClipboard('')
    const toast = useToast();


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        setName("");
        setShowFirstPage(true);
    }

    const handleClose = () => {
        onClose();
    }

    //const handleForcePasswordChange = () => setForcePassword(!forcePassword);


    const handleSubmit = () => {
        if (name !== undefined && name !== "") {
            webHelpers.PutAsync<IKeyResponse, unknown>("/api/management/key", "auth", { name: name }).then((res) => {

                console.log("successfully added key, response: ", res);
                if (res !== null) {
                    setShowFirstPage(false);
                    if (res.token !== "" && res.token !== undefined) {
                        setToken(res.token);
                        setValue(res.token)
                    }
                    if (res.expires !== "" && res.expires !== null && res.expires !== undefined) {
                        setExpires(res.expires)
                    }
                }
            }).catch((error) => {
                console.log("error creating key, error: ", error);
                return toast({
                    position: "bottom",
                    title: `An error occurred while trying to create your key`,
                    status: "error",
                    isClosable: true,
                });
            })
        }
        props.cb();
    }

    const handleCopy = () => {

    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                _hover={{ bg: "AbleYellow", textColor: "white" }}
                _active={{ bg: "#ECB500" }}

                onClick={handleClickOpen} leftIcon={<BiPlus />}>
                Create Key
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "full", lg: "md" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Create New Key"}</ModalHeader>
                    <ModalBody>

                        {showFirstPage ?
                            <FormControl>
                                <FormLabel>Please enter a name for the new key.</FormLabel>

                                <Input placeholder="Name" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>

                            </FormControl>
                            :
                            <Flex direction={"column"}>
                                <Text marginBottom={"10px"}>Here is your generated key, make sure to copy it and store it somewhere safe!</Text>
                                <InputGroup>
                                    
                                    <Input paddingRight={"4.5rem"} value={token}></Input>
                                    <Tooltip label={hasCopied ? "Copied!" : "Copy to Clipboard"} closeOnClick={false} placement="top" hasArrow>
                                    <InputRightElement width='4.5rem'>
                                        
                                            <Button onClick={onCopy} h='1.75rem' size='sm'>
                                                <HiOutlineClipboardCopy fontSize={"20px"} />
                                                
                                            </Button>
                                        
                                    </InputRightElement>
                                    </Tooltip>
                                </InputGroup>
                                <br />
                                <Text>Expires: {expires} </Text>
                            </Flex>
                        }


                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => handleSubmit()}
                            isDisabled={name === ""}
                            bg={"AbleBlue"}
                            textColor={"white"}
                            _hover={{ textColor: "AbleYellow" }}
                            display={showFirstPage ? "flex": "none"}
                            >Create Key</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>{showFirstPage ? "Cancel" : "Close"}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
