import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Toast } from "@chakra-ui/react";
import { Table, useColorMode, Spinner, useToast, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { color } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import EditUserMobile from "../../components/user/editUserMobile"
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import { forEach } from "mathjs";
import { BsChevronDoubleDown } from "react-icons/bs";
import TableControls from "../../components/table/tableControls";
import Refresh from "../../components/refresh";
import NewKey from "../../components/user/newKey";

export interface User {
    id: string,


    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}

export const UsersPage = () => {
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<User[]>([]);
    const [nextPage, setNextPage] = useState<User[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [userTypes, setUserTypes] = useState<IUserTypes[]>([]);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const {colorMode, toggleColorMode} = useColorMode();
    

    const { isOpen, onToggle } = useDisclosure();
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();
    const toast = useToast();


    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        setPage(0);
        
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {

        }
    }, [])

    function goToNextPage(pageToLoad: number) {
        webHelpers.GetAsync('/api/management/users/' + selectedType + "/" + rowsPerPage + '/' + (pageToLoad + 1) + '/' + search, "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.users)
                data.users.forEach((element: User) => {
                    setRows(rows => [...rows, element])
                })
                console.log(rows)
                setMaxPages(data.max_pages)
                setLoading(false);
            }
        });
    }
 
    function searchUsers(search: string) {
        //@ts-ignore
        webHelpers.GetAsync('/api/management/users/' + selectedType + "/" + rowsPerPage + '/' + (page + 1) + '/' + search, "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setRows(data.users)
                setMaxPages(data.max_pages)
                setLoading(false);
            }
        });
    }
    function getUserTypes() {
        webHelpers.GetAsync('/api/usertypes', "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log("data undefined or null ");
            }
            else {
                setUserTypes(data);
            }
        }).catch((error) => {
            console.log("error bad API call, error message : ", error)
        });
    }

    useEffect(() => {
        setNextPageNo(page + 1);
    }, [page]);

    useEffect(() => {
        getUserTypes();
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchUsers(search)
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchUsers(search)
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
    const syncHandler = () => {
        setIsSyncing(true);
        webHelpers.PostAsync('/api/workers/roles/sync', "zeus-api", {}).then((data: any) => {
            console.log("data: ", data);
            if (data === null) {
                setIsSyncing(false);
                return toast({
                    title: "Successfully synced with Twilio",
                    status: "success",
                    isClosable: true,
                    position: "top"
                })

            }
            else {
                setIsSyncing(false);
                return toast({
                    title: "Sync with Twilio failed",
                    status: "error",
                    isClosable: true,
                    position: "top"
                })
            }
        }).catch((error) => {
            console.log("sync error : ", error)
            setIsSyncing(false);
        })

    };

    const refresh = () => {
        setIgnore(true);
        searchUsers(search);
        setLoading(true);
        setNextPageNo(page);
        userTypes.map((workflow: IUserTypes) => (
            console.log(workflow.id, workflow.name)
        ))
    }
    const removeFromRows = (id: string) => {
        // @ts-ignore
        let newRows = [];
        rows.forEach(element => {
            if (element.id === id){
                console.log("user to be disabled is : ", element.id);
            }
            else{
                newRows.push(element);
            }
        //@ts-ignore
        setRows(newRows);
        });
    }
    const handleStaffClick = () => {
        navigate("/staff");
    }
    const handleKeyDown = (event: any) => {

    }

    function sortList(a: IWorkResult, b: IWorkResult) {
        if (a.RouteIndex < b.RouteIndex) {
            return -1;
        }
        if (a.RouteIndex > b.RouteIndex) {
            return 1;
        }
        return 0;
    }
    const handleProfileChange = (event: any) => {
        setLoading(true);
        setSelectedType(event.target.value);
        console.log(event.target.value);
        setPage(0);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.target.value);
    }
    useEffect(() => {
        onToggle();
    }, [loading]);

    useEffect(() => {
        setRows([])
    }, [EnvironmentContext.selectedEnvironment])

    useEffect(() => {
        console.log("pageNo = ", page);
        console.log("nextPageNo = ", nextPageNo);
        console.log("max pages = ", maxPages)
    },[page, nextPageNo])

    return (

        <ScaleFade initialScale={1} in={isPageOpen}>

            <>
                <Heading color={colorMode === 'light' ? "AbleBlue": "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"}
                >User Management</Heading>
                <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                    <Select onChange={handleEnvChange} value={EnvironmentContext.selectedEnvironment.id} width={"175px"} marginRight={"20px"} defaultValue={"live"} display={{base: "none", lg: "flex"}}>
                        {EnvironmentContext.environments.map((workflow: IEnvironment) => (
                            <option value={workflow.id}>{workflow.name}</option>
                        ))}
                    </Select>
                    <Input placeholder="Search Bar" value={search} onChange={handleSearchChange} width={"300px"} display={{base: "none", lg: "flex"}}></Input>
                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{base: "none", lg: "flex"}}>User Type:</Text>
                    <Select onChange={handleProfileChange} value={selectedType} width={"150px"} display={{base: "none", lg: "flex"}}>
                        {userTypes.map((workflow: IUserTypes) => (
                            <option value={workflow.id}>{workflow.name}</option>
                        ))}
                    </Select>
                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{base: "none", lg: "flex"}}>Rows per Page:</Text>
                    <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"} display={{base: "none", lg: "flex"}}>
                        <option value={5} >5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                    </Select>
                    <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{base: "none", lg: "flex"}}>Page {page + 1} of {maxPages} </Text>
                    <Button display={{base: "none", lg: "flex"}} bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={() => {
                        page !== 0 && setPage(page - 1);
                        setLoading(true);
                    }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                    <Button display={{base: "none", lg: "flex"}} size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={() => {
                        page !== maxPages - 1 && setPage(page + 1)
                        setLoading(true);
                    }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                    <Button display={{base: "flex", lg: "flex"}} size={"sm"} bg={"AbleBlue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={syncHandler} isLoading={isSyncing} marginLeft={{base: "10px", lg:"30px"}}
                        leftIcon={<AiOutlineSync />} _loading={{ bg: "AbleYellow", textColor: "AbleBlue" }} loadingText={"Syncing"}>
                        Sync With Twilio
                    </Button>
                    <Refresh cb={() => refresh()} />
                    {selectedType === "77be288b-5caf-4d55-86a2-59b51db66dae" ? 
                    <NewKey cb={() => {refresh();}} /> :
                    <NewUser cb={() => {refresh();}} />
                    }
                    {/* <NewUser cb={() => {refresh();}} /> */}
                    <Flex display={{base: "flex", lg: "none"}}>
                        <TableControls handleEnvChange={handleEnvChange} handleSearchChange={handleSearchChange} handleProfileChange={handleProfileChange} syncHandler={syncHandler} search={search} selectedType={selectedType}
                        userTypes={userTypes} />
                    </Flex>
                </Flex>
                <Flex direction={'row'} h={750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                    {/* table to show list of users */}

                    <TableContainer>
                        {!loading ?
                            <ScaleFade initialScale={1} in={!loading} reverse={loading}>
                                <Table variant={"simple"}>

                                    <InfiniteScroll
                                        dataLength={rows.length}
                                        next={() => {
                                            if (nextPageNo < page) {
                                                setNextPageNo(page);
                                                goToNextPage(page + 1)
                                            }
                                            else {
                                                goToNextPage(nextPageNo + 1)
                                                setNextPageNo(nextPageNo + 1);
                                            }
                                        }}
                                        hasMore={nextPageNo < maxPages}
                                        loader={
                                            <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                                <Spinner color="AbleBlue" marginTop={"-10px"} marginBottom={"100px"} size={"xl"}></Spinner>
                                                {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                            </Flex>
                                        }
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                                <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                            </p>
                                        }
                                        scrollThreshold={1}
                                    >
                                        <TableCaption>
                                            <Text>
                                                {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                            </Text>
                                        </TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th display={{base: "revert"}}>Name</Th>
                                                <Th display={{base: "none", lg: "revert"}}>Department</Th>
                                                <Th display={{base: "none", lg: "revert"}} >Email</Th>
                                            </Tr>
                                        </Thead>

                                        <Tbody>
                                            {rows.map((each) =>
                                                <Tr>
                                                    <Td display={{base: "revert"}} >{each.full_name}</Td>
                                                    <Td display={{base: "none", lg: "revert"}}>{each.department}</Td>
                                                    <Td display={{base: "none", lg: "revert"}}>{each.email_address}</Td>
                                                    <Td display={{base: "none", lg: "revert"}}><UserAppPermissionsModal user={each} cb={refresh} /></Td>
                                                    <Td display={{base: "none", lg: "revert"}}><PasswordReset {...each} /></Td>
                                                    <Td display={{base: "none", lg: "revert"}}><EditUserModal user={each} cb={refresh} /></Td>
                                                    <Td display={{base: "none", lg: "revert"}}><EnableDisable user={each} cb={removeFromRows} /></Td>
                                                    <Td display={{base: "revert", lg: "none"}}><EditUserMobile user={each} cb={refresh}/></Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </InfiniteScroll>
                                </Table>
                            </ScaleFade>
                            :
                            // <ScaleFade initialScale={0.5} in={isOpen} reverse={!loading}>
                            <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"backgroundDarkGrey"}>
                                {/* <Spinner color="AbleBlue" marginTop={"300px"}></Spinner> */}
                                {colorMode === 'light' ? 
                                    <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                                    : 
                                    <Spinner color="AbleYellow" marginTop={"100px"} marginBottom={"100px"} size={"xl"}/>
                                        }
                            </Flex>
                            // {/* </ScaleFade> */}

                        }
                    </TableContainer>

                    <ScaleFade initialScale={0.1} in={defaultView}>
                    </ScaleFade>

                </Flex>
            </>

        </ScaleFade>
    )
}
