import * as React from 'react';
import { useState } from 'react';


import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, background, useToast,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";


// Custom Components
// import RoleManagement from '../modal-role-management/roles-table.component';
// import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
// import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useWebHelper } from "../../hooks/UseWebHelper";



import { BiAddToQueue, BiCloset } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';






interface IEditNameserver {
    id: string,
    name: string,
    email: string,
    cb: Function,
}
export interface INamespace {
    name_space: string;
    is_default: boolean;
}
const defaultWorkflow: INamespace[] = [
    {
        name_space: "Test.Package",
        is_default: true,
    }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};
interface NamespceModalProps {
    cb: Function
}

export default function NamespaceModal(props: any) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    // const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [rows2, setRows2] = useState<INamespace[]>(defaultWorkflow);
    const [newNamespace, setNewNamespace] = useState<string>("New Namespace");

    const webHelpers = useWebHelper();
    const toast = useToast();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)


    // Grabs data to fill out seperate tabs
    const handleOpen = () => {
        webHelpers.GetAsync('/api/code/config/namespaces', 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                toast({
                    position: "bottom",
                    title: `Unable to fetch workflows [${data.status}]`,
                    status: "success",
                    isClosable: true,
                })
            }
            else {
                setRows2(Object.values(data));
                setOpen(true);
                //setMaxPages(data.maximum_pages)
            }
        })
    };

    function handleClose() {
        setOpen(false);
    }
    const handleProfileChange = (event: any) => {
        setNewNamespace(event.target.value);
    }
    const handleSubmit = () => {
        props.cb(newNamespace);
        setOpen(false);
    }
    return (
        <>
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                className='button-test' onClick={handleOpen} leftIcon={<BiAddToQueue />}>Add new Namespace</Button>
            <Modal
                isOpen={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <div className="modal-header">
                            <Text>
                                Add New Namespace:
                            </Text>
                        </div>

                        <Text as={"i"} fontSize={"sm"}>
                            Select a new namespace from the drop down box below
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <Select
                            id="demo-simple-select"
                            value={newNamespace}
                            onChange={handleProfileChange}
                            placeholder='New Namespace'
                        >
                            {rows2.map((workflow: INamespace) => (
                                (workflow.is_default ?
                                    <p></p> :
                                    <option value={workflow.name_space}>{workflow.name_space}</option>

                                )))}
                        </Select>
                        <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginTop={"10px"}
                        className="modal-button-submit" onClick={handleSubmit} variant="contained" leftIcon={<BiAddToQueue />} isDisabled={newNamespace === "New Namespace"}>Add to Code</Button>

                    </ModalBody>
                </ModalContent>
            </Modal >
        </>
    );
}