import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { RiEdit2Line } from "react-icons/ri";

interface IOutcome {
    id: string,
    friendly_id: string,
    name: string,
    description: string,
}
interface editOutcomeProps {
    outcome: IOutcome,
    cb: Function,
}

export default function EditOutcome(props: editOutcomeProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    //form fields
    const [id, setId] = useState<string>(props.outcome.id)
    const [friendlyId, setFriendlyId] = useState<string>(props.outcome.friendly_id);
    const [name, setName] = useState<string>(props.outcome.name);
    const [description, setDescription] = useState<string>(props.outcome.description);
    const [outcome , setOutcome] = useState<IOutcome>();

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = () => {
        if (friendlyId !== undefined && name !== undefined && description !== undefined) {
            const payload = {
                "id": id,
                "friendly_id": friendlyId,
                "name": name,
                "description": description,
            }
            webHelpers.PostAsync('/api/config/outcome', 'helios-api', payload).then((data: any) => {
                console.log("submit new outsome response : ", data)
            })
            props.cb();
            handleClose();
        }
    }
    const handleIdChange = (e: React.FormEvent<HTMLInputElement>) => {
        setId(e.currentTarget.value)
    }
    const handleFriendlyIdChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFriendlyId(e.currentTarget.value)
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value)
    }

    return (
        <>
            <Button bg={"AbleBlue"} textColor={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                _hover={{ bg: "AbleYellow", textColor: "white" }}
                _active={{ bg: "#ECB500" }}

                onClick={handleClickOpen} leftIcon={<RiEdit2Line />}>
                Edit
            </Button>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "full", lg: "md" }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{"Edit Outcome: " + name}</ModalHeader>
                    <ModalBody>

                        <FormControl>
                            <FormLabel>Please complete the form, ensuring all fields are correct, before submitting.</FormLabel>
                            <Text as={"b"} marginLeft={"10px"}>Id</Text>
                            <Input marginBottom={"10px"} placeholder="Id" type="text" autoComplete="new-password" value={id} onChange={handleIdChange} isDisabled></Input>
                            <Text as={"b"} marginLeft={"10px"}>Friendly Id</Text>
                            <Input marginBottom={"10px"} placeholder="Friendly Id" type="text" autoComplete="new-password" value={friendlyId} onChange={handleFriendlyIdChange} isRequired></Input>
                            <Text as={"b"} marginLeft={"10px"}>Name</Text>
                            <Input marginBottom={"10px"} type="text" autoComplete="new-password" placeholder="Name" value={name} onChange={handleNameChange} isRequired></Input>
                            <Text as={"b"} marginLeft={"10px"}>Description</Text>
                            <Input type="text" autoComplete="new-password" placeholder="Description" value={description} onChange={handleDescriptionChange} isRequired></Input>
                        </FormControl>


                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => handleSubmit()}
                            isDisabled={friendlyId === "" || name === "" || description === ""}
                            bg={"AbleBlue"}
                            textColor={"white"}
                            _hover={{ textColor: "AbleYellow" }}>Save Changes</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
