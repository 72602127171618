import * as React from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuth } from './AuthContext';

export function RequireAuth({children}: {children: JSX.Element}) {
    let auth = useAuth();
    let location = useLocation();

    if (auth?.user?.user_id === undefined) {
        return <Navigate to={"/signin?returnUrl=" + encodeURIComponent(location.pathname + location.search)}/>;
    }

    return children;
}

// export function RequireAuthRingmaster({children}: {children: JSX.Element}) {
//     let auth = useAuth();

//     if (auth?.user?.engineer_id === undefined || auth.user?.contractor_type !== AllocationStyle.Ringmaster) {
//         return <Navigate to="/" replace />
//     }

//     return children;
// }