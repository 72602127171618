import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Toast } from "@chakra-ui/react";
import { Table, useColorMode, Spinner, useToast, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { color } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreatePermission from "../../components/permissions/createPermission";
import BlockNumber from "../../components/blocklist/blockNumber";
import GetUserConfirm from "../../components/confirm";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import TableControlsPermissions from "../../components/table/tableControlsPermissions";
import Refresh from "../../components/refresh";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IPermission {
    id: string,
    name: string,
}
interface IBlocklist {
    id: string,
    created: string,
    createdById: string,
    createdByName: string,
    expires: string,
    reason: string,
    type: number,
}
export const BlockedNumbersPage = () => {
    const CacheContext = useCache();
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState<IBlocklist[]>([]);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();
    const { isOpen, onToggle } = useDisclosure();
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [nextPage, setNextPage] = useState<IBlocklist[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const {colorMode, toggleColorMode} = useColorMode();




    const webHelpers = useWebHelper();

    const EnvironmentContext = useEnvironment();



    // function searchPermissions(search: string) {
    //     webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1) + '/' + search, 'auth').then((data: any) => {
    //         if (data.status === 200 || data.status === undefined) {
    //             // @ts-ignore 
    //             setRows(Object.values(data.permissions));
    //             setMaxPages(data.max_pages);
    //             setLoading(false);
    //         }
    //         else {
    //             console.log(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
    //         }
    //     }).catch((error) => {
    //         setRows([]);
    //         setMaxPages(0);
    //         console.log("no permissions match search criteria \"" + search + "\"");
    //     });
    // }
    function goToNextPage(pageToLoad: number) { //used for infinite scrolling
        webHelpers.GetAsync("/api/call/control/block/?limit=" + rowsPerPage + "&page=" + (pageToLoad + 1) + "&filter=" + search, "zeus-api").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                console.log(data);
                setNextPage(data.results)
                data.results.forEach((element: IBlocklist) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                setMaxPages(data.maxPage)
                setLoading(false);
            }
        });
    }
    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    function updateTable() {
        setLoading(true)
        setNextPageNo(page);
        webHelpers.GetAsync(
            "/api/call/control/block/?limit=" + rowsPerPage + "&page=" + (page + 1) + "&filter=" + search, "zeus-api").then((data: any) => {
                if (data === undefined) {
                    return toast({
                        position: "bottom",
                        title: `${data.status}: Unable to fetch numbers from the API`,
                        status: "error",
                        isClosable: true,
                    });
                } else {
                    setRows(data.results);
                    setMaxPages(data.maxPage);
                    setLoading(false);
                }
            }
            ).catch((error) => {
                setLoading(false);
                return toast({
                    position: "bottom",
                    title: `${error.status}: Unable to fetch numbers from the API`,
                    status: "error",
                    isClosable: true,
                });
            });
    }

    const unblockNumber = (id: string) => {
        let number_id = id;
        number_id = number_id.replace('+', '$');
        webHelpers.DeleteAsync("/api/call/control/block/?id=" + number_id, "zeus-api", {}).then((data: any) => {
            if (data.id === id) {
                //success
                setLoading(true);
                updateTable();
                return toast({
                    position: "bottom",
                    title: `Phone Number ${data.id} has been unblocked`,
                    status: "success",
                    isClosable: true,
                });
            }
            else {
                setLoading(true);
                return toast({
                    position: "bottom",
                    title: `Phone Number ${data.id} could not be unblocked`,
                    status: "error",
                    isClosable: true,
                });
            }

        }).catch((error) => {
            setLoading(true);
            updateTable();
            return toast({
                position: "bottom",
                title: `Phone Number ${id} could not be unblocked [${error.status}]`,
                status: "error",
                isClosable: true,
            });
        })
    }

    useEffect(() => {
        updateTable();
    }, [page, rowsPerPage]);

    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        // setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        // setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    }, [page]);

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        updateTable();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true)
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setRows([])
        updateTable();
    }, [EnvironmentContext.selectedEnvironment])
    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.target.value);
    }


    return (
        <ScaleFade initialScale={1} in={isPageOpen}>
            <Heading color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"}
            >Blocklist Management</Heading>
            <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>

                <div>

                    <ScaleFade initialScale={1} in={true}>
                        <Flex direction={"row"} justifyContent={"center"}>
                            <TableControlsPermissions handleChangeRowsPerPage={handleChangeRowsPerPage} search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} />
                            <Select onChange={handleEnvChange} value={EnvironmentContext.selectedEnvironment.id} width={"175px"} marginRight={"20px"} defaultValue={"live"} display={{ base: "none", lg: "revert" }}>
                                {EnvironmentContext.environments.map((workflow: IEnvironment) => (
                                    <option value={workflow.id}>{workflow.name}</option>
                                ))}
                            </Select>

                            <Input placeholder="Search Bar" onChange={handleSearchChange} value={search} width={"300px"} display={{ base: "none", lg: "revert" }}></Input>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Rows per Page:</Text>
                            <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"} display={{ base: "none", lg: "revert" }}> 
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                            </Select>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }}>Page {page + 1} of {maxPages} </Text>
                            <Button display={{ base: "none", lg: "revert" }} bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== 0 && setPage(page - 1);
                                setLoading(true);
                            }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                            <Button display={{ base: "none", lg: "revert" }} size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== maxPages - 1 && setPage(page + 1);
                                setLoading(true);
                            }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                            <Refresh cb={() => updateTable()} />
                            <BlockNumber cb={updateTable} />
                        </Flex>
                        {/* need to have a section or method of showing already selected skills */}
                        {!loading ?
                            <>
                                {rows.length > 0 ?
                                    <ScaleFade initialScale={1} in={!loading}>
                                        <Flex h={750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"}>
                                            <TableContainer>

                                                <Table variant={"simple"}>
                                                    {rows.length === 0 ?
                                                        <Text textAlign={"center"}>No Permissions Found! </Text>
                                                        :
                                                        <>

                                                            <InfiniteScroll
                                                                dataLength={rows.length}
                                                                next={() => {

                                                                    if (nextPageNo < page) {
                                                                        setNextPageNo(page + 1);
                                                                        goToNextPage(page + 1)
                                                                    }
                                                                    else {
                                                                        goToNextPage(nextPageNo + 1)
                                                                        setNextPageNo(nextPageNo + 1);
                                                                    }
                                                                }}
                                                                hasMore={nextPageNo <= maxPages - 2}
                                                                loader={
                                                                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                                                        <Spinner color="AbleBlue" marginTop={"-10px"} marginBottom={"100px"} size={"xl"}></Spinner>
                                                                        {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                                                    </Flex>
                                                                }
                                                                endMessage={
                                                                    <p style={{ textAlign: 'center' }}>
                                                                        <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                                                    </p>
                                                                }
                                                                scrollThreshold={1}
                                                            >
                                                                <TableCaption>
                                                                    <Text>
                                                                        {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                                                    </Text>
                                                                </TableCaption>
                                                                <Thead>
                                                                    <Tr>
                                                                        {/* this will show all skill that are possible to see */}
                                                                        <Th textAlign={"center"}>Phone Number</Th>
                                                                        <Th display={{ base: "none", lg: "revert" }}>Created</Th>
                                                                        <Th display={{ base: "none", lg: "revert" }}>Created By</Th>
                                                                        <Th display={{ base: "none", lg: "revert" }}>Expires</Th>
                                                                        <Th display={{ base: "none", lg: "revert" }}>Reason</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {rows.map((number) =>
                                                                        <Tr>
                                                                            <Td >{number.id}</Td>
                                                                            <Td textOverflow={"ellipsis"} display={{ base: "none", lg: "revert" }}>{
                                                                                number.created.slice(8, 10) + "/" +
                                                                                number.created.slice(5, 7) + "/" +
                                                                                number.created.slice(0, 4) + " " + number.created.slice(11)
                                                                            }</Td>
                                                                            <Td display={{ base: "none", lg: "revert" }}>{number.createdByName}</Td>
                                                                            <Td textOverflow={"ellipsis"} display={{ base: "none", lg: "revert" }}>{
                                                                                number.expires.slice(8, 10) + "/" +
                                                                                number.expires.slice(5, 7) + "/" +
                                                                                number.expires.slice(0, 4) + " " + number.expires.slice(11)
                                                                            }</Td>
                                                                            <Td display={{ base: "none", lg: "revert" }}>{number.reason}</Td>
                                                                            <Td>
                                                                                <GetUserConfirm buttonText="Unblock" buttonWarning="Are you sure you want to unblock this number?"
                                                                                    declineFunction={() => console.log("")} confirmFunction={() => unblockNumber(number.id)} />
                                                                            </Td>
                                                                        </Tr>
                                                                    )}
                                                                </Tbody>
                                                            </InfiniteScroll>

                                                        </>
                                                    }
                                                </Table>
                                            </TableContainer>
                                        </Flex>
                                    </ScaleFade>


                                    :
                                    <Flex justifyContent={"center"} marginTop={"100px"}>
                                        <Text>No Blocked Numbers Found !</Text>
                                    </Flex>
                                }

                            </>

                            :

                            <Flex width={"100%"} justifyContent={"center"}>
                                <Spinner color={ colorMode === 'light' ? "AbleBlue" : "AbleYellow"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                            </Flex>
                        }
                    </ScaleFade>
                </div>




            </Flex>
        </ScaleFade >
    )
}
