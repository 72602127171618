import { Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack, SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { Spinner,useColorMode,  Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react';
import * as React from 'react';
import { switchAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { color } from "framer-motion";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateWorkflow from "../../components/workflows/createWorkflow";
import EditWorkflow from "../../components/workflows/editWorkflow";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import Refresh from "../../components/refresh";
import "./loading.css"

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IWorkflows {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
}
export const WorkflowsPage = () => {
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<IWorkflows[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [userTypes, setUserTypes] = useState<IUserTypes[]>([]);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [selectedWorkflow, setSelectedWorkflow] = useState<string>("");
    const [nextPage, setNextPage] = useState<IWorkflows[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);

    const { isOpen, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();
    const {colorMode, toggleColorMode} = useColorMode();
    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {
            console.log("environment is null?")
        }
    }, [])
    function goToNextPage(pageToLoad: number, searchQuery: string) { //used for infinite scrolling
        webHelpers.GetAsync('/api/workflows/?page=' + (pageToLoad + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.workflows)
                data.workflows.forEach((element: IWorkflows) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                setMaxPages(data.maximum_pages)
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    function searchWorkflows(searchQuery: string) {
        if (searchQuery !== '') {
            webHelpers.GetAsync('/api/workflows/?page=' + (page + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                    // setRows(defaultWorkflow);
                }
                else {
                    setRows(Object.values(data.workflows));
                    setMaxPages(data.maximum_pages)
                    setTimeout(() => {
                        setLoading(false);
                    },1000)
                }
            }).catch((error) => {
                console.log("error fetching workflows (with search), error message: ", error);
            })
        }
        else {
            webHelpers.GetAsync('/api/workflows/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    setRows(Object.values(data.workflows));
                    setMaxPages(data.maximum_pages)
                    setTimeout(() => {
                        setLoading(false);
                    },1000)
                }
            }).catch((error) => {
                console.log("error fetching workflows, error message : ", error);
                setLoading(false);
            })
        }

    }

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchWorkflows(search)
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchWorkflows(search)
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);

    const refresh = () => {
        setIgnore(true);
        searchWorkflows("");
        setLoading(true);
        setNextPageNo(page);

    }

    const handleProfileChange = (event: any) => {
        setSelectedType(event.target.value);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.target.value);
    }
    const handleNavigate = (target: string) => {
        navigate("/workflows/view/" + EnvironmentContext.selectedEnvironment.id + "/" + target);
    }

    useEffect(() => {
        setRows([])
    }, [EnvironmentContext.selectedEnvironment])

    useEffect(() => {
        setLoading(true);
    },[])
    

    return (
        <>


            <Flex direction={"column"} className="animate__animated animate__fadeIn">
                <>
                    <Heading color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"}
                    >Workflow Management</Heading>
                    <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                        <GoBack goBackTo="/dashboard" />
                        <Select onChange={handleEnvChange} value={EnvironmentContext.selectedEnvironment.id} width={"175px"} marginRight={"20px"} defaultValue={"live"}>
                            {EnvironmentContext.environments.map((workflow: IEnvironment) => (
                                <option value={workflow.id}>{workflow.name}</option>
                            ))}
                        </Select>
                        <Input placeholder="Search Bar" value={search} onChange={handleSearchChange} width={"300px"}></Input>
                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                        <Select onChange={handleChangeRowsPerPage} value={rowsPerPage} width={"100px"}>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </Select>
                        <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                        <Button bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                            page !== 0 && setPage(page - 1);
                            setLoading(true);
                        }} isDisabled={page === 0}><AiFillCaretLeft /></Button>
                        <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                            page !== maxPages - 1 && setPage(page + 1)
                            setLoading(true);
                        }} isDisabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                        <Refresh cb={() => refresh()} />
                        <CreateWorkflow cb={() => { setLoading(true); searchWorkflows(search) }} />
                    </Flex>
                    <Flex direction={'row'} h={loading ? "fit-content" : 750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                        {/* table to show list of users */}
                            <Text display={rows.length === 0 && !loading ? "flex": "none"} className={"animated__animated animate__fadeIn"} marginTop={"150px"} fontSize={"xl"}>No Workflows Found!</Text>
                            <TableContainer >
                                <Flex display={loading ? "flex" : "none"} direction={"column"} justifyContent={"center"} marginTop={"170px"} className="animate__animated animate__fadeIn">
                                    <div className="scene">
                                        <div className="cube-wrapper">
                                            <div className="cube">
                                                <div className="cube-faces">
                                                    <div className="cube-face shadow"></div>
                                                    <div className="cube-face bottom"></div>
                                                    <div className="cube-face top"></div>
                                                    <div className="cube-face left"></div>
                                                    <div className="cube-face right"></div>
                                                    <div className="cube-face back"></div>
                                                    <div className="cube-face front"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Text as={"b"} fontSize={"md"}  marginTop={"60px"}>Currently fetching workflows</Text>
                                    <Text as={"i"} fontSize={"sm"}>{"(This may take a moment)"}</Text>
                                </Flex >
                                    <Table variant={"simple"}  display={rows.length === 0 || loading ? "none": "flex"} className="animate__animated animate__fadeIn">
                                        <InfiniteScroll
                                            dataLength={rows.length}
                                            next={() => {

                                                if (nextPageNo < page) {
                                                    setNextPageNo(page + 1);
                                                    goToNextPage(page + 1, search)
                                                }
                                                else {
                                                    goToNextPage(nextPageNo + 1, search)
                                                    setNextPageNo(nextPageNo + 1);
                                                }
                                            }}
                                            hasMore={nextPageNo <= maxPages - 2}
                                            loader={
                                                <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                                    <Spinner color="AbleBlue" marginTop={"-10px"} marginBottom={"100px"} size={"xl"}></Spinner>
                                                    {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                                </Flex>
                                            }
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                                </p>
                                            }
                                            scrollThreshold={1}
                                        >
                                            <TableCaption>
                                                <Text>
                                                    {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                                </Text>
                                            </TableCaption>
                                            <Thead>
                                                <Tr>
                                                    <Th>Name</Th>
                                                    <Th>Event Id</Th>
                                                    <Th>Description</Th>
                                                </Tr>
                                            </Thead>

                                            <Tbody>

                                                {rows.map((each) =>
                                                    <Tr _hover={{ bg: colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey" }} bg={each.id === selectedWorkflow ? "backgroundLightGrey" : "backgroundDarkGrey"} borderColor={"AbleBlue"}
                                                        borderWidth={each.id === selectedWorkflow ? "2px" : "0px"}>
                                                        <Td>{each.name}</Td>
                                                        <Td>{each.event_id}</Td>
                                                        <Td>{each.description}</Td>
                                                        <Td>
                                                            <Button onClick={() => handleNavigate(each.id)} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} size={"sm"}>
                                                                View</Button>
                                                        </Td>
                                                    </Tr>
                                                )}

                                            </Tbody>
                                        </InfiniteScroll>


                                    </Table>

                            </TableContainer>
                        
                        <ScaleFade initialScale={0.1} in={defaultView}>
                        </ScaleFade>

                    </Flex>
                </>

            </Flex>
        </>
    )
}
