import * as React from 'react';
import { useState, useEffect } from 'react';
import "@fontsource/fira-code"
import AceEditor from "react-ace";
import { IWebHelperErrorResponse, useWebHelper } from "../../hooks/UseWebHelper";
import {
    Radio, RadioGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,
    Button, Box, Select, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Flex, Image, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle,
    AlertDescription, Text, Badge, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Fade, ScaleFade, useDisclosure, Slide, SlideFade, Collapse, Grid, GridItem, HStack,
    SimpleGrid, Stack, Switch, VStack, Wrap, WrapItem, Checkbox, TabList, Tabs, Tab, TabPanels, TabPanel, Spinner, background, useToast,
} from '@chakra-ui/react';
import "../code/code.css";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

import { useCache } from "../../context/CacheContext";
import { SidePane } from 'react-side-pane';
import { split as splitEditior } from "react-ace";
import { AiOutlineClose } from 'react-icons/ai';
import GetUserConfirm from '../confirm';
import { StringMappingType } from 'typescript';
// import "ace-builds/src-noconflict/theme-monokai";
// import "ace-builds/src-noconflict/theme-github";
// import "ace-builds/src-noconflict/mode-csharp";
// import "ace-builds/src-noconflict/mode-java";
// import "ace-builds/src-noconflict/ext-inline_autocomplete";
// import "ace-builds/src-noconflict/mode-c_cpp";
import DisplayNamespaces from '../namespaces/display-namespaces-component';
import ShowAssembliesNamespaces from '../namespaces/display-namespaces-component';
import { LuCode2 } from "react-icons/lu";
import { FaCodeBranch } from "react-icons/fa";
import 'animate.css'; 


interface ICodeEditorDialogProps {
    stepFriendlyName: string,
    code_id: string,
    buttonText: string
    mode: string,
    cb: Function | undefined; //only used when mode is create-event
    intital_value: string | undefined;  //only used when mode is create-event
    quick_outcome_code_id: string | null;
}
interface CompileErrors {
    id: string,
    warning_level: number,
    severity: number,
    is_error: boolean,
    message: string,
    location: string,
}

// I've used a field here called 'mode' in order to simplify this component, Sam's code had 4 different code editors!
// Mode will tell the editor what functions it should and shouldn't run, like how for example if you're creating a new event subscriber there isn't an existing code_id to read from
//
//--MODES--
// "" - used when the CodeEditor can function as normal 
//"create-event" - used when creating a new event subscriber, there's no code id so instead the code is saved by passing it to a callback function 
//"edit-outcome" - used when the code to be edited is for an outcome instead of a conventional workflow step


export function CodeEditor(props: ICodeEditorDialogProps) {
    const [open, setOpen] = useState(false);
    const [initialCode, setInitialCode] = useState<string>();
    const [code, setCode] = useState<string>('');
    const [code_id, setCodeId] = useState<string | null>(props.code_id !== null ? props.code_id : null);
    const [unsavedCode, setUnsavedCode] = useState(false);
    const [version, setVersion] = useState(0);
    const [compileErrors, setCompileErrors] = useState<CompileErrors[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const webHelpers = useWebHelper();
    const toast = useToast();
    const monaco = useMonaco();
    // ill be honest, this code isn't mine
    // i got it from https://stackoverflow.com/questions/11000826/ctrls-preventdefault-in-chrome
    document.onkeydown = function (e) {
        e = e || window.event;//Get event
        if (e.ctrlKey) {
            var c = e.which || e.keyCode;//Get key code
            switch (c) {
                case 83://Block Ctrl+S
                    e.preventDefault();     
                    e.stopPropagation();
                    handleSave();
                break;
            }
        }
    }; // This overrides ctrl-s to compile & save instead of prompting to save the webpage to file

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    useEffect(() => {
        setCompileErrors([]);
    }, [open]);

    const handleClose = () => {
        if (code !== initialCode) {
            setUnsavedCode(true);
        }
        else {
            setOpen(false);
        }
    };
    const handleCancel = () => {
        setUnsavedCode(false);
    }
    useEffect(() => {
        console.log("unsaved code : " ,unsavedCode);
    },[unsavedCode])

    function saveCode() {
        if (props.mode === "create-event" && props.cb !== undefined) {
            props.cb(code);
            setInitialCode(code);
            setLoading(false);
            return toast({
                position: "bottom",
                title: `Code Successfully saved`,
                status: "success",
                isClosable: true,
            });
        }
        else if (props.mode === "edit-outcome"){
            console.log("/api/code/" , props.quick_outcome_code_id, props.mode)
            if (code !== initialCode) {
                var payload = {
                    'id': props.quick_outcome_code_id,
                    'code': code
                }
                console.log("payload: ", payload)
                webHelpers.PostAsync('/api/code/' + props.quick_outcome_code_id, 'helios-api', payload).then((data: any) => {
                    //If save was successful, display happy message
                    if (data === undefined || data === null) {
                        console.log('${data.status}: Unable to update code', { 'variant': 'error' });
                        setLoading(false);
                    }

                    console.log('Code successfully updated!', { 'variant': 'success' });
                    setCompileErrors([]);
                    setUnsavedCode(false);
                    setInitialCode(code);
                    setLoading(false);
                    toast({
                        position: "bottom",
                        title: `Code Successfully saved and compiled with no errors`,
                        status: "success",
                        isClosable: true,
                    });
                }).catch((error: IWebHelperErrorResponse) => {
                    //Otherwise catch error and display error messages if it has them
                    console.log(error);
                    if (error.response?.is_success === false) {
                        //If error messages available
                        if (error.response.messages?.length > 0) {
                            setCompileErrors(error.response.messages);
                            toast({
                                position: "bottom",
                                title: `Code could not compile, check error log for details`,
                                status: "error",
                                isClosable: true,
                            });
                        }
                    }
                    else {
                        toast({
                            position: "bottom",
                            title: `Error, could not save code (error message : ${error})`,
                            status: "error",
                            isClosable: true,
                        });
                    }
                }).finally(() => {
                    setLoading(false);
                })
            }
            else {
                console.log('No changes to code made!', { 'variant': 'warning' });
                toast({
                    position: "bottom",
                    title: `Code has not changed since last save`,
                    status: "info",
                    isClosable: true,
                });
                setLoading(false);
                setCompileErrors([]);
            }
        }
        else {
            if (code !== initialCode) {
                var payload = {
                    'id': props.mode === "edit-outcome" ? props.quick_outcome_code_id : props.code_id,
                    'code': code
                }

                webHelpers.PostAsync('/api/code/' + props.code_id, 'helios-api', payload).then((data: any) => {
                    //If save was successful, display happy message
                    if (data === undefined || data === null) {
                        console.log('${data.status}: Unable to update code', { 'variant': 'error' });
                        setLoading(false);
                    }

                    console.log('Code successfully updated!', { 'variant': 'success' });
                    setCompileErrors([]);
                    setUnsavedCode(false);
                    setLoading(false);
                    setInitialCode(code);
                    toast({
                        position: "bottom",
                        title: `Code Successfully saved and compiled with no errors`,
                        status: "success",
                        isClosable: true,
                    });
                }).catch((error: IWebHelperErrorResponse) => {
                    //Otherwise catch error and display error messages if it has them
                    console.log(error);
                    if (error.response?.is_success === false) {
                        //If error messages available
                        if (error.response.messages?.length > 0) {
                            setCompileErrors(error.response.messages);
                            toast({
                                position: "bottom",
                                title: `Code could not compile, check error log for details`,
                                status: "error",
                                isClosable: true,
                            });
                        }
                    }
                    else {
                        toast({
                            position: "bottom",
                            title: `Error, could not save code (error message : ${error})`,
                            status: "error",
                            isClosable: true,
                        });
                    }
                }).finally(() => {
                    setLoading(false);
                })
            }
            else {
                console.log('No changes to code made!', { 'variant': 'warning' });
                toast({
                    position: "bottom",
                    title: `Code has not changed since last save`,
                    status: "info",
                    isClosable: true,
                });
                setLoading(false);
                setCompileErrors([]);
            }
        }
    }

    const handleSave = () => {
        setLoading(true);
        saveCode();
    }

    function getCode() {
        //setCompileErrors([]);
        monaco?.editor.defineTheme("myCustomTheme", {
            base: "vs-dark", // can also be vs-dark or hc-black
            inherit: true, // can also be false to completely replace the builtin rules

            rules: [
                { token: '', fontStyle: 'italic' },
                {
                    token: "comment",
                    foreground: "ffa500",
                    fontStyle: "italic underline",
                },
                { token: "comment.js", foreground: "008800", fontStyle: "bold" },
                { token: "comment.css", foreground: "0000ff" }, // will inherit fontStyle from `comment` above
            ],
            colors: {
                "editor.foreground": "#000000",
            },
        });
        setLoading(false);
        if (props.mode === "edit-outcome") {
            console.log("props.quick_ouitcome_code_id = ", props.quick_outcome_code_id)
            webHelpers.GetAsync('/api/code/' + props.quick_outcome_code_id, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data === "") {
                    console.log("API error: Unable to fetch code for this step", { "variant": "error" })
                }
                else {
                    setCode(data.code);
                    setInitialCode(data.code);
                    setVersion(data.version);
                }
            }).catch(((error) => {
                setCode("");
            }))
        }
        else {
            if (props.code_id !== 'null' && props.code_id !== null) {
                webHelpers.GetAsync('/api/code/' + props.code_id, 'helios-api').then((data: any) => {
                    if (data === undefined || data === null || data === "") {
                        console.log('API error: Unable to fetch code for this step', { 'variant': 'error' });
                    }
                    else {
                        setCode(data.code);
                        setInitialCode(data.code);
                        setVersion(data.version);
                    }
                }).catch((error) => {
                    setCode("");
                }
                )
            }
        }

    }

    useEffect(() => {
        if (props.mode !== "create-event") {
            getCode();
        }
        else if (props.intital_value === undefined || props.intital_value === "") {
            setCode("//Start Writing New Event Subscriber Here...");
        }
        else {
            setCode(props.intital_value);
        }
        // if (props.mode !== "create-event") {
        //     getCode();
        // }
        // else if (props.intital_value === undefined || props.intital_value === "" || props.intital_value === null){
        //     setCode("//Start Writing New Event Subscriber Here...");
        //     console.log("should set code to //start writing ");

        // }
        // else{
        //     console.log("setting code to ", props.intital_value);
        //     setCode(props.intital_value);
        // }
    }, [open, props.code_id])

    return (
        <>
            <Button margin={"5px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"} width={"100%"} onClick={handleClickOpen} leftIcon={props.mode === "edit-outcome" ? <FaCodeBranch /> : <LuCode2 />}>

                {props.buttonText}
            </Button>
            <Modal
                isOpen={open}
                size={"full"}
                onClose={handleClose}


            >
                <ModalContent overflow={"hidden"}>
                    <ModalHeader className={compileErrors.length > 0 ? "blink" : "blank"}>

                        <Flex direction={'row'} justifyContent={'space-between'} >
                            <Button bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <AiOutlineClose />
                            </Button>
                            <p>   Step: {props.stepFriendlyName} | Version: {version}</p>
                            <Button isLoading={loading} className={compileErrors.length > 0 ? "animate__animated animate__headShake" : "blank"}
                                autoFocus bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"} onClick={handleSave}>
                                Save
                            </Button>
                        </Flex>
                    </ModalHeader>
                    <ModalBody style={{ backgroundColor: "#272822" }} overflowX={"hidden"}>
                        <Flex backgroundColor={"#272822"} direction={"row"}>

                            <Editor
                                height={compileErrors.length > 0 ? "63vh" : "95vh"}
                                width={"80%"}
                                defaultLanguage="csharp"
                                defaultValue='//some comment'
                                value={code}
                                onChange={(currentCode) => setCode(currentCode === undefined ? "" : currentCode)}
                                theme="vs-dark"
                                className='Test'


                                options={{ fontSize: 18, fontFamily: "Fira Code", fontLigatures: "true" }}
                            />
                            {/* <AceEditor
                                
                                style={{
                                    height: compileErrors.length > 0 ? "63vh" : "95vh",
                                    width: "80%",
                                    fontFamily: "revert"
                                    
                                }}
                                
                                theme="monokai"
                                placeholder={initialCode}
                                mode="c_cpp"
                                name="Script for step"
                                onChange={(currentCode) => setCode(currentCode)}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={code}
                                enableBasicAutocompletion={true}
                                tabSize={4}
                                fontSize={18}
                                

                            /> */}


                            <div className='test-div'>
                                <ShowAssembliesNamespaces {...props} />
                            </div>
                        </Flex>
                        {compileErrors.length > 0 &&
                            <Box className='animate__animated animate__slideInUp' color={"white"} position={"absolute"} bottom={0} height={"30%"} width={"78%"} borderWidth={"0px"} borderColor={"#2F3129"} borderLeftWidth={"0px"} borderTopWidth={"2px"} overflowY={"scroll"}>
                                <Text as={"i"} fontSize={"3xl"} textColor={"AbleYellow"}>Errors</Text>
                                {compileErrors.map((error) =>
                                    <>
                                        <Text>{error.message}</Text>
                                    </>
                                )}
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={unsavedCode}
                onClose={handleCancel}
                isCentered
                size={"lg"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Text>Wait!</Text>
                    </ModalHeader>
                    <ModalBody>
                        <p style={{ padding: '1rem' }}> You have made changes to the code which haven't been saved,
                            if you exit now you'll lose any changes made!</p>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent={"space-around"}>
                            <Button bgColor={"AbleBlue"} marginRight={"10px"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"} onClick={() => setUnsavedCode(false)}>Continue Coding</Button>
                            <Button bgColor={"AbleBlue"} marginRight={"10px"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"} onClick={
                                () => {
                                    handleSave();
                                    setOpen(false);
                                    setUnsavedCode(false);
                                }}>Save & Quit</Button>
                            <Button bgColor={"AbleBlue"} marginRight={"10px"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"} onClick={() => {
                                setOpen(false);
                                setUnsavedCode(false);
                            }}>Discard Changes</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}