
import {
    Button,
} from '@chakra-ui/react';
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router";
interface ConfirmProps {
    goBackTo: string,
}
export default function GoBack(props: ConfirmProps) {
    const navigate = useNavigate();


    const handleNavigate = () => {
        navigate(props.goBackTo)
    }
    return (
        <>
            <Button onClick={handleNavigate} marginRight={"20px"} marginTop={"3px"}
            bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} size={"sm"}
            leftIcon={<IoArrowBack />}
            >Go Back</Button>
        </>
    )

}